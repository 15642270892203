import React from 'react'
import { useTranslation } from 'react-i18next'
import DefaultShipping from 'common/components/entities/ShippingFees/DefaultShipping'
import ShippingCondition from 'common/components/entities/ShippingFees/ShippingCondition'
import ShippingFeesUi from 'common/components/entities/ShippingFees/ui/ShippingFeesUi'
import { getShippingUnavailableMessage } from 'common/components/entities/ShippingFees/utils'
import {
  SelectedShipping,
  ShippingFeesInterface,
} from 'common/types/entities/ShippingFeesInterface'
import { ShippingResponse } from 'common/types/shippingType'

export interface ShippingFeesProps {
  entity: ShippingFeesInterface
  selectedShipping: SelectedShipping | null
  isLoading: boolean
  customerCountry?: string
  onChange: (selectedShipping: SelectedShipping) => void
  language?: Intl.Locale['language']
  shipping?: ShippingResponse
}

const CommonShippingFees = ({
  entity,
  selectedShipping,
  isLoading = false,
  customerCountry,
  onChange,
  shipping,
  language = 'en',
}: ShippingFeesProps) => {
  const { t } = useTranslation('common')

  return (
    <ShippingFeesUi
      id={entity.htmlAttrId}
      padding={entity.padding}
      mobilePadding={entity.mobilePadding}
      margin={entity.margin}
      mobileMargin={entity.mobileMargin}
      fontSize={entity.fontSize}
      mobileFontSize={entity.mobileFontSize}
      lineHeight={entity.lineHeight}
      mobileLineHeight={entity.mobileLineHeight}
      fontFamily={entity.fontFamily}
      mobileFontFamily={entity.mobileFontFamily}
      fontWeight={entity.fontWeight}
      mobileFontWeight={entity.mobileFontWeight}
    >
      {!shipping || !shipping.shippingServices.length
        ? t(getShippingUnavailableMessage(customerCountry))
        : shipping.shippingServices.map(service =>
            service.rules.map(rule =>
              rule.shippingConditions.length ? (
                rule.shippingConditions.map(condition => {
                  const isSelected =
                    condition.id === selectedShipping?.conditionId &&
                    rule.id === selectedShipping.ruleId

                  return (
                    <ShippingCondition
                      key={condition.id}
                      isSelected={isSelected}
                      isLoading={isLoading}
                      onChange={onChange}
                      service={service}
                      language={language}
                      rule={rule}
                      condition={condition}
                    />
                  )
                })
              ) : (
                <DefaultShipping
                  key={rule.id}
                  isSelected={rule.id === selectedShipping?.ruleId}
                  isLoading={isLoading}
                  onChange={onChange}
                  service={service}
                  language={language}
                  rule={rule}
                />
              ),
            ),
          )}
    </ShippingFeesUi>
  )
}

export default CommonShippingFees
