import { TFunction } from 'react-i18next'
import { ShippingCondition, ShippingRule } from 'common/types/shippingType'

export const getConditionLabel = (
  condition: ShippingCondition,
  rule: ShippingRule,
  t: TFunction,
) => {
  switch (rule.type) {
    case 'price':
      if (condition.max) {
        return t('components.shipping.condition_label.price', {
          min: condition.min,
          max: condition.max,
          currency: rule.currency.toUpperCase(),
        })
      }

      return t('components.shipping.condition_label.price_min', {
        min: condition.min,
        currency: rule.currency.toUpperCase(),
      })
    case 'quantity':
      if (condition.max) {
        return t('components.shipping.condition_label.quantity', {
          min: condition.min,
          max: condition.max,
        })
      }

      return t('components.shipping.condition_label.quantity_min', {
        min: condition.min,
      })
    case 'weight':
      if (condition.max) {
        return t('components.shipping.condition_label.weight', {
          min: condition.min,
          max: condition.max,
        })
      }

      return t('components.shipping.condition_label.weight_min', {
        min: condition.min,
      })
    default:
      return ''
  }
}

export const getShippingUnavailableMessage = (customerCountry?: string) => {
  if (!customerCountry) {
    return 'components.shipping.country_not_selected'
  }

  return 'components.shipping.not_available'
}
