import React from 'react'
import { useTranslation } from 'react-i18next'
import { SubscriptionPlanIntervalEnum } from 'common/enums/SubscriptionPlanIntervalEnum'

type SubscriptionPlanProps = {
  amountWithCurrency: string
  trialInterval: SubscriptionPlanIntervalEnum
  trialPeriod: number
  interval: SubscriptionPlanIntervalEnum
  intervalCount: number
}

function SubscriptionPlan({
  amountWithCurrency,
  trialInterval,
  trialPeriod,
  interval,
  intervalCount,
}: SubscriptionPlanProps) {
  const { t } = useTranslation(['common', 'publisher'])

  let readableAmount = ''
  if (trialPeriod) {
    const interval = t(`price_plan.trial_period.${trialInterval}`, {
      count: trialPeriod,
    })
    readableAmount = `${t(
      'components.offer_price.price_plans.trial_period_new',
      {
        trialPeriodPrice: t(
          'components.offer_price.price_plans.trial_period_price',
        ),
        trialInterval: interval,
      },
    )} `
  }
  // const readableAmountNew = readableAmount + t('components.offer_price.price_plans.unlimited_new', {
  //   amount: amountWithCurrency,
  //   interval: t(`price_plan.interval.${interval}`, {
  //     count: intervalCount,
  //   }),
  // })
  const readableAmountOld = readableAmount + t('components.offer_price.price_plans.unlimited', {
    amount: amountWithCurrency,
    interval: t(`price_plan.interval.${interval}`, {
      count: intervalCount,
    }),
    intervalHack: t(
      intervalCount > 1
        ? 'components.offer_price.price_plans.interval_hack_many'
        : 'components.offer_price.price_plans.interval_hack_one',
    ),
  })

  return <>{readableAmountOld}</>
}

export default SubscriptionPlan
