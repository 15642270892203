import { IdealBankElement } from '@stripe/react-stripe-js'
import React from 'react'

function StripeIdeal() {
  return (
    <IdealBankElement
      className="Ideal"
      options={{
        style: {
          base: {
            color: '#32325d',
            fontSize: '16px',
            padding: '10px 12px',
          },
        },
      }}
    />
  )
}

export default StripeIdeal
