import styled from 'styled-components'

const CouponUi = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  ${({ styles = {} }) => styles}
`

export default CouponUi
