import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { BadRequest, InternalError, NetworkError } from 'common/errors'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { PaymentButtonInterface } from 'common/types/entities/PaymentButtonInterface'
import { optInFail } from 'publisher/actions/optInActions'
import { buyMainOffer } from 'publisher/api/xenditApi'
import { useXendit } from 'publisher/context/XenditContext'
import { XenditCreditCardStatusEnum } from 'publisher/enums/XenditCreditCardStatusEnum'
import { XenditFieldTypeEnum } from 'publisher/enums/XenditFieldTypeEnum'
import usePaymentSubmit from 'publisher/hooks/payment/usePaymentSubmit'
import usePage, { selectors as pageSelectors } from 'publisher/hooks/usePage'
import { usePayment } from 'publisher/store'
import {
  getActivePricePlan,
  getProduct,
} from 'publisher/store/payment/paymentSelectors'

interface CreditCardTokenInterface {
  id: string
  payer_authentication_url: string
  status: XenditCreditCardStatusEnum
  credit_card_token_id: string
}

export default function useXenditPaymentHandler(
  entity: OldEntityInterface | PaymentButtonInterface,
) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { errors, setErrors, isLoading, submit } = usePaymentSubmit(entity)
  const [isxenditOperationsLoading, setIsXenditOperationsLoading] =
    useState(false)

  const product = usePayment(getProduct)
  const pricePlan = usePayment(getActivePricePlan)

  const pageId = usePage(pageSelectors.getPageId)
  const { fields, handleXenditVerification, setIsSecureModalVisible } =
    useXendit()

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (window.Xendit) {
      const cardNumberValidationResult = handleXenditVerification(
        XenditFieldTypeEnum.card_number,
        fields.cardNumber,
      )
      const cardExpiryDateValidationResult = handleXenditVerification(
        XenditFieldTypeEnum.expiry_date,
        fields.month,
        fields.year,
      )
      const cardCvvValidationResult = handleXenditVerification(
        XenditFieldTypeEnum.cvn_number,
        fields.cvv,
      )
      setIsXenditOperationsLoading(true)
      if (
        !cardNumberValidationResult ||
        !cardExpiryDateValidationResult ||
        !cardCvvValidationResult
      ) {
        setIsXenditOperationsLoading(false)
        return
      }
      window.Xendit.card.createToken(
        {
          amount: fields.amount,
          card_number: fields.cardNumber,
          card_exp_month: fields.month,
          card_exp_year: fields.year,
          card_cvn: fields.cvv,
          is_multiple_use: true,
          should_authenticate: true,
          currency: product?.currency ?? pricePlan?.currency,
        },
        xenditTokenResponseHandler,
      )
    }

    function xenditTokenResponseHandler(
      err: {
        error_code: string
        message: string
      },
      creditCardCharge: CreditCardTokenInterface,
    ) {
      if (err) {
        setErrors([t('core.error.title')])
        ;(window as any).Rollbar.error(err)
        return
      }

      if (creditCardCharge.status === XenditCreditCardStatusEnum.verified) {
        window.Xendit.card.createAuthentication(
          getAuth(creditCardCharge),
          xenditAuthenticationResponseHandler,
        )
      } else if (
        creditCardCharge.status === XenditCreditCardStatusEnum.in_review
      ) {
        setIsSecureModalVisible(true)
        window.open(
          creditCardCharge.payer_authentication_url,
          'xendit-3ds-frame',
        ) //opend 3ds window for confirmation if there is no use_multiple config
      } else if (
        creditCardCharge.status === XenditCreditCardStatusEnum.failed
      ) {
        setIsXenditOperationsLoading(false)
        setErrors([t('core.error.title')])
      }
    }
  }

  function xenditAuthenticationResponseHandler(
    err: {
      error_code: string
      message: string
    },
    creditCardToken: CreditCardTokenInterface,
  ) {
    if (err) {
      setErrors([t('core.error.title')])
      ;(window as any).Rollbar.error(err)
      return
    } else if (
      creditCardToken.status === XenditCreditCardStatusEnum.in_review
    ) {
      setIsSecureModalVisible(true)
      window.open(creditCardToken.payer_authentication_url, 'xendit-3ds-frame')
    } else if (creditCardToken.status === XenditCreditCardStatusEnum.verified) {
      setTimeout(() => {
        setIsSecureModalVisible(false)
      }, 1000)
      setIsXenditOperationsLoading(false)
      submit(async body => {
        try {
          const { data } = await buyMainOffer(pageId, {
            payment_form: {
              ...body,
              options: {
                token: creditCardToken.credit_card_token_id,
              },
            },
          })
          if (data.redirect) {
            window.location.assign(data.redirect)
            return
          }
        } catch (error) {
          setIsXenditOperationsLoading(false)
          if (error instanceof BadRequest) {
            setErrors(error.response.data.errors.common)
            dispatch(optInFail({ fields: error.response.data.errors.fields }))
          } else if (error instanceof NetworkError) {
            setErrors([t('core.errors.no_connection')])
          } else if (error instanceof InternalError) {
            setErrors([t('core.error.title')])
          }
        }
      })
    } else {
      setIsXenditOperationsLoading(false)
      setErrors([t('core.error.title')])
    }
  }

  function getAuth(creditCardToken: any) {
    return {
      amount: fields.amount,
      token_id: creditCardToken.id,
    }
  }

  return {
    errors,
    isLoading: isxenditOperationsLoading ? true : isLoading,
    handleSubmit,
  }
}
