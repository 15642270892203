import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import StyleWrapper from 'common/components/StyleWrapper'
import OrderSummary from 'common/components/entities/OrderSummaryNew'
import SkeletonOrderSummary from 'common/components/entities/OrderSummaryNew/SkeletonOrderSummary'
import { OrderSummaryType } from 'common/types/OrderSummaryType'
import EntityInterface from 'common/types/entities/EntityInterface'
import { OfferThankYouProductInterface } from 'common/types/entities/ProductInterface'
import { getCurrencySignByLocale } from 'common/utils/currencyUtils'
import useManagement, {
  selectors as managementSelectors,
} from 'publisher/hooks/useManagement'
import usePage, { selectors as pageSelector } from 'publisher/hooks/usePage'
import {
  usePage as typedUsePage,
  typedPageSelectors,
} from 'publisher/store/index'
import { getOrderSummary } from '../../../client/api/newPaymentApi'

type OfferThankYouOrderSummaryProps = {
  entity: EntityInterface
}

function OfferThankYouOrderSummaryNew({
  entity,
}: OfferThankYouOrderSummaryProps) {
  const { t } = useTranslation()
  const isTemplate = usePage(pageSelector.isTemplate)
  const locale = typedUsePage(typedPageSelectors.getLocale)
  const isPreview = useManagement(managementSelectors.isPreviewMode)
  const [orderSummary, setOrderSummary] = useState<OrderSummaryType>()
  const pageId = usePage(typedPageSelectors.getPageId)

  useEffect(() => {
    async function fetchOrderSummary() {
      try {
        const response = await getOrderSummary(pageId)
        setOrderSummary(response.data)
      } catch (e) {
        // do nothing
      }
    }
    if (!isTemplate) fetchOrderSummary()
  }, [])

  let calculatePricePlans = orderSummary?.pricePlans
  if (isTemplate || isPreview) {
    calculatePricePlans = [
      {
        id: 1,
        name: t('entities.offer_upsell_price.stub.name'),
        price: '1400 ' + getCurrencySignByLocale(locale),
      },
    ]
  }

  const calculatedProductsInfo = orderSummary?.products?.map(
    (product: OfferThankYouProductInterface) => {
      const calculatedProductInfo = {
        name: product?.name ? product.name : '',
        price: product?.price ? product?.price : '',
      }
      if (product?.variant) {
        const variantValueString = product.variant.map(e => e.value).join(' / ')
        if (variantValueString.length > 0) {
          calculatedProductInfo.name =
            calculatedProductInfo.name + ' ' + `(${variantValueString})`
        }
      }
      return calculatedProductInfo
    },
  )

  return (
    <StyleWrapper
      desktop={entity.appearance.desktop}
      mobile={entity.appearance.mobile}
      styles={entity.margin}
      mobileStyles={entity.mobileMargin}
    >
      {orderSummary ? (
        <OrderSummary
          attrId={entity.htmlAttrId}
          pricePlans={calculatePricePlans}
          products={calculatedProductsInfo}
        />
      ) : (
        <SkeletonOrderSummary />
      )}
    </StyleWrapper>
  )
}

export default OfferThankYouOrderSummaryNew
