import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import CommonCustomerType from 'common/components/entities/CustomerType/CustomerType'
import { customerTypes } from 'common/constants/customTypes'
import StyleWrapper from 'common/components/StyleWrapper'
import * as types from 'common/types'
import { useActions } from 'common/hooks/useActions'
import * as paymentActions from 'publisher/actions/paymentActions'
import { getCustomerType } from 'publisher/reducers/paymentReducer'
import usePayment from 'publisher/hooks/usePayment'

function CustomerType({ entity }) {
  const toggleCustomerType = useActions(paymentActions.toggleCustomerType)
  const customerType = usePayment(getCustomerType)

  useEffect(() => {
    if (!customerType) {
      // if customer type is undefined we must set it as a personal
      toggleCustomerType(customerTypes.PERSONAL)
    }
  }, [])

  return (
    <StyleWrapper
      desktop={entity.options.appearance.desktop}
      mobile={entity.options.appearance.mobile}
    >
      {customerType && (
        <CommonCustomerType
          htmlAttrId={entity.options.attrId}
          styles={entity.styles}
          mobileStyles={entity.mobileStyles}
          toggle={toggleCustomerType}
          activeType={customerType}
        />
      )}
    </StyleWrapper>
  )
}

CustomerType.propTypes = {
  entity: types.entity.isRequired,
  customerType: PropTypes.string,
}

export default CustomerType
