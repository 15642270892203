import PropTypes from 'prop-types'
import React from 'react'
import * as TwoStepPaymentForm from 'common/components/entities/TwoStepPaymentForm'
import useTwoStepOptInSubmit from 'publisher/hooks/useTwoStepOptInSubmit'
import useManagement, {
  selectors as managementSelectors,
} from '../../../hooks/useManagement'
import BaseNodeChildren from '../../ChildrenEntities'
import FieldErrors from '../../FieldErrors'

function OptInStep({ entity, buttonStyles, mobileButtonStyles }) {
  const [isLoading, errors, optInSubmit] = useTwoStepOptInSubmit(entity)
  const isPreview = useManagement(managementSelectors.isPreviewMode)
  return (
    <TwoStepPaymentForm.Step>
      <BaseNodeChildren childIds={entity.childIds} />
      <TwoStepPaymentForm.Button
        styles={buttonStyles}
        mobileStyles={mobileButtonStyles}
        text={entity.options.buttonText}
        subText={entity.options.buttonSubText}
        onClick={optInSubmit}
        disabled={isLoading || isPreview}
      />
      <FieldErrors errors={errors} />
      {entity.options.footerText && (
        <TwoStepPaymentForm.StepFooter>
          {entity.options.footerText}
        </TwoStepPaymentForm.StepFooter>
      )}
    </TwoStepPaymentForm.Step>
  )
}

OptInStep.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    options: PropTypes.shape({
      buttonText: PropTypes.string.isRequired,
      buttonSubText: PropTypes.string.isRequired,
      footerText: PropTypes.string,
    }).isRequired,
    childIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  buttonStyles: PropTypes.shape({
    buttonBackgroundColor: PropTypes.string.isRequired,
    buttonTextColor: PropTypes.string.isRequired,
  }).isRequired,
  mobileButtonStyles: PropTypes.shape({
    buttonBackgroundColor: PropTypes.string,
    buttonTextColor: PropTypes.string,
  }),
}

export default OptInStep
