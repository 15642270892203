import React from 'react'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import useMercadoPagoTaxNumberPaymentHandler from 'publisher/hooks/payment/useMercadoPagoTaxNumberPaymentHandler'
import FieldErrors from '../../FieldErrors'
import BaseButton from '../BaseButton'

function MercadoPagoCreditCardPaymentButton({
  entity,
}: {
  entity: OldEntityInterface
}) {
  const { styles, mobileStyles, options, mobileOptions = {} } = entity
  const { errors, isLoading, handleSubmit } =
    useMercadoPagoTaxNumberPaymentHandler(entity)
  return (
    <>
      <BaseButton
        handleClick={handleSubmit}
        disabled={isLoading}
        styles={styles}
        mobileStyles={mobileStyles}
        attrId={options.attrId}
        text={options.text}
        borderType={options.borderType}
        mobileBorderType={mobileOptions.borderType}
        appearance={options.appearance}
        loading={isLoading}
        loadingText={options.loadingText}
        subText={options.subText}
        subTextColor={options.subTextColor}
        subTextFontSize={options.subTextFontSize}
        subTextFontFamily={options.subTextFontFamily}
        mobileSubTextColor={mobileOptions.subTextColor}
        mobileSubTextFontSize={mobileOptions.subTextFontSize}
        mobileSubTextFontFamily={mobileOptions.subTextFontFamily}
        iconClassNameBefore={options.iconClassNameBefore}
        iconClassNameAfter={options.iconClassNameAfter}
        hoverStyles={options?.hover}
        hoverMobileStyles={mobileOptions?.hover}
      />
      <FieldErrors errors={errors} align="center" />
    </>
  )
}

export default MercadoPagoCreditCardPaymentButton
