import styled from 'styled-components'

const PricePlanItemWithoutLeftOffsetUi = styled.div`
  display: flex;
  align-items: center;
  color: #000000;
  gap: 5px;
`

export default PricePlanItemWithoutLeftOffsetUi
