import React from 'react'
import * as TwoStepPaymentForm from '../../../../common/components/entities/TwoStepPaymentForm'
import useManagement, {
  selectors as managementSelectors,
} from '../../../hooks/useManagement'
import usePaystackPaymentHandler from '../../../hooks/payment/usePaystackPaymentHandler'
import FieldErrors from '../../FieldErrors'
import { TwoStepPaymentButtonProps } from './TwoStepPaymentButtonProps'

export default function TwoStepPaystackPaymentButton({
  entity,
  styles,
  mobileStyles,
}: TwoStepPaymentButtonProps) {
  const { isLoading, handleSubmit, errors } = usePaystackPaymentHandler(entity)

  const isPreview = useManagement(managementSelectors.isPreviewMode)

  return (
    <React.Fragment>
      <TwoStepPaymentForm.Button
        onClick={handleSubmit}
        styles={styles}
        mobileStyles={mobileStyles}
        text={entity.options.buttonText}
        subText={entity.options.buttonSubText}
        disabled={isLoading || isPreview}
      />
      <FieldErrors errors={errors} />
    </React.Fragment>
  )
}
