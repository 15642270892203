import axios from 'axios'
import request from '../utils/request'

export const validateForm = (pageId, body, purchaseProcessId) => {
  return axios.post(
    `/secure/paypal/soap/${pageId}/validate-form-and-store-customer-data/${purchaseProcessId}`,
    {
      payment_form: body,
    },
  )
}

export function purchaseDetails(pageId, body, purchaseProcessId) {
  return axios.post(
    `/secure/paypal/soap/${pageId}/purchase-details/${purchaseProcessId}`,
    {
      offer_payment: body,
    },
  )
}

export function upsellDisagree(pageId, purchaseProcessId, body) {
  return request.post(
    `/secure/stripe/${pageId}/buy-upsell/${purchaseProcessId}`,
    {
      upsell_payment_form: body,
    },
  )
}
