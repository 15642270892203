import React from 'react'
import PropTypes from 'prop-types'
import AgreementUi from 'common/components/entities/Agreement/ui/AgreementUi'
import provideMobileStyles from 'common/hoc/provideMobileStyles'

const Agreement = ({ text, styles, mobileStyles, htmlAttrId }) => (
  <AgreementUi
    attrId={htmlAttrId}
    styles={styles}
    mobileStyles={mobileStyles}
  >
    <span dangerouslySetInnerHTML={{ __html: text }} />
  </AgreementUi>
)

Agreement.propTypes = {
  text: PropTypes.string.isRequired,
  styles: PropTypes.objectOf(PropTypes.any).isRequired,
  htmlAttrId: PropTypes.string,
  mobileStyles: PropTypes.objectOf(PropTypes.any).isRequired,
  forwardedRef: PropTypes.func,
}

export default provideMobileStyles(Agreement)
