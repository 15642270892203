import styled from 'styled-components'

const CouponErrorUi = styled.div`
  width: 100%;
  color: red;
  font-size: 12px;
`

export default CouponErrorUi

