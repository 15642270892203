import React from 'react'
import { PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'
import CashOnDelivery from './methods/CashOnDelivery'
import DLocalCreditCard from './methods/dlocal/CreditCard'
import MercadoPageCreditCard from './methods/mercadoPago/CreditCard'
import StripeCreditCard from './methods/stripe/CreditCard'
import IDeal from './methods/stripe/IDeal'
import XenditCreditCard from './methods/xendit/CreditCard'
import TaxNumber from './shared/components/TaxNumber'
import PaymentMethodDetailsUi from './ui/PaymentMethodDetailsUi'

interface PaymentMethodDetailsProps {
  paymentMethod: PaymentMethodEnum | null
}

function PaymentMethodDetails({ paymentMethod }: PaymentMethodDetailsProps) {
  let details
  switch (paymentMethod) {
    case PaymentMethodEnum.StripeCard:
      details = <StripeCreditCard />
      break
    case PaymentMethodEnum.StripeIDeal:
      details = <IDeal />
      break
    case PaymentMethodEnum.DlocalCard:
      details = <DLocalCreditCard />
      break
    case PaymentMethodEnum.MercadoPagoCard:
    case PaymentMethodEnum.MercadoPagoBrCard:
    case PaymentMethodEnum.MercadoPagoCoCard:
    case PaymentMethodEnum.MercadoPagoMxCard:
    case PaymentMethodEnum.MercadoPagoArCard:
    case PaymentMethodEnum.MercadoPagoClCard:
      details = <MercadoPageCreditCard type={paymentMethod} />
      break
    case PaymentMethodEnum.MercadoPagoPix:
    case PaymentMethodEnum.MercadoPagoBoleto:
    case PaymentMethodEnum.DlocalPix:
    case PaymentMethodEnum.DlocalBoleto:
      details = <TaxNumber type={paymentMethod} />
      break
    case PaymentMethodEnum.XenditIdCard:
    case PaymentMethodEnum.XenditPhCard:
      details = <XenditCreditCard />
      break
    case PaymentMethodEnum.CashOnDelivery:
      details = <CashOnDelivery />
      break
    default:
      return null
  }

  return <PaymentMethodDetailsUi>{details}</PaymentMethodDetailsUi>
}

export default PaymentMethodDetails
