export const formOptions = {
  id: 'form-checkout',
  cardholderName: {
    id: 'mercado-card-holder-name',
  },
  cardNumber: {
    id: 'mercado-card-number',
  },
  cardExpirationMonth: {
    id: 'mercado-card-expiration-month',
  },
  cardExpirationYear: {
    id: 'mercado-card-expiration-year',
  },
  securityCode: {
    id: 'mercado-card-security_code',
  },
  installments: {
    id: 'mercado-card-installments',
  },
  issuer: {
    id: 'form-checkout__issuer',
  },
  identificationType: {
    id: 'mercado-card-identification-type',
  },
  identificationNumber: {
    id: 'mercado-card-identification-number',
  },
}
