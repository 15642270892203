import styled from 'styled-components'

const PaymentMethodSwitcherItemImageUi = styled.img`
  //max-height: 30px;
  margin-left: auto;
  flex-shrink: 1;
  min-width: 1px;
`

export default PaymentMethodSwitcherItemImageUi
