import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { offerPricePlan } from 'common/types/index'
import centsToEuro from 'common/utils/centsToEuro'
import { getNetPrice, getReadablePrice } from 'common/utils/priceCalculator'

export const PaymentDetails = ({ amount, interval, intervalCount }) => {
  const { t } = useTranslation('common')
  return t('components.offer_price.price_plans.unlimited', {
    amount,
    interval: t(`price_plan.interval.${interval}`, {
      count: intervalCount,
    }),
    intervalHack: t(
      intervalCount > 1
        ? 'components.offer_price.price_plans.interval_hack_many'
        : 'components.offer_price.price_plans.interval_hack_one',
    ),
  })
}

const UnlimitedSubscription = props => {
  const {
    translate,
    plan: { subscriptionPlan, currency },
    tax,
    quantity,
    coupon,
  } = props
  const netPrice = getNetPrice(subscriptionPlan.amount, quantity, coupon)
  const interval = translate(
    `price_plan.trial_period.${subscriptionPlan.trialInterval}`,
    {
      count: subscriptionPlan.trialPeriod,
    },
  )
  return (
    <React.Fragment>
      {subscriptionPlan.trialPeriod
        ? translate('components.offer_price.price_plans.trial_period_new', {
            trialPeriodPrice: translate(
              'components.offer_price.price_plans.trial_period_price',
            ),
            trialInterval: interval,
          })
        : ''}{' '}
      <PaymentDetails
        amount={getReadablePrice(centsToEuro(netPrice + tax), currency)}
        interval={subscriptionPlan.interval}
        intervalCount={subscriptionPlan.intervalCount}
      />
    </React.Fragment>
  )
}

UnlimitedSubscription.propTypes = {
  coupon: PropTypes.shape({
    id: PropTypes.number.isRequired,
    discountAmount: PropTypes.string.isRequired,
    discountType: PropTypes.string.isRequired,
  }),
  plan: offerPricePlan.isRequired,
  translate: PropTypes.func.isRequired,
  tax: PropTypes.number,
  quantity: PropTypes.number,
}

UnlimitedSubscription.defaultProps = {
  tax: 0,
  quantity: 1,
}

export default UnlimitedSubscription
