import styled from 'styled-components'
import { marginCss, mobileMarginCss } from '../../../../utils/styleUtilsNew'

const PaymentMethodsUi = styled.div.attrs(p => ({
  id: p.attrId,
}))`
  color: #1e252d;
  --payment-method-border-color: #bfc6d1;
  border: 1px solid var(--payment-method-border-color);
  border-radius: 5px;
  ${marginCss}
  ${p => p.theme.phone} {
    ${mobileMarginCss}
  }
  ${p => p.theme.isEditorMobileMode && mobileMarginCss}
`

export default PaymentMethodsUi
