import styled from 'styled-components'

const CreditCardFieldUi = styled.div<{ width?: string }>`
  position: relative;
  width: ${p => p.width || '100%'};
  color: #8b929c;
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
`

export default CreditCardFieldUi
