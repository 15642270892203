import styled, { css } from 'styled-components'
import { TextProps } from 'common/utils/styleUtilsNew'

type ForgotPasswordWrapperUiProps = {
  align?: TextProps['textAlign']
  mobileAlign?: TextProps['mobileTextAlign']
}

const ForgotPasswordWrapperUi = styled.div<ForgotPasswordWrapperUiProps>`
  text-align: ${p => p.align};
  ${p => p.theme.phone} {
    text-align: ${p => p.mobileAlign || p.align};
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      text-align: ${p.mobileAlign || p.align};
    `}
`

export default ForgotPasswordWrapperUi
