import React, { memo } from 'react'
import usePaypalRestPaymentHandler from 'publisher/hooks/payment/usePaypalRestPaymentHandler'
import { OldEntityInterface } from '../../../../common/types/entities/OldEntityInterface'
import FieldErrors from '../../FieldErrors'
import BaseButton from '../BaseButton'

function PaypalRestPaymentButton({ entity }: { entity: OldEntityInterface }) {
  const { styles, mobileStyles, options, mobileOptions } = entity
  const { handleSubmit, errors, isLoading } =
    usePaypalRestPaymentHandler(entity)

  return (
    <React.Fragment>
      <BaseButton
        handleClick={handleSubmit}
        disabled={isLoading}
        styles={styles}
        mobileStyles={mobileStyles}
        attrId={options.attrId}
        text={options.text}
        borderType={options.borderType}
        mobileBorderType={mobileOptions.borderType}
        appearance={options.appearance}
        loading={isLoading}
        loadingText={options.loadingText}
        subText={options.subText}
        subTextColor={options.subTextColor}
        subTextFontSize={options.subTextFontSize}
        subTextFontWeight={options.subTextFontWeight}
        subTextFontStyle={options.subTextFontStyle}
        subTextFontFamily={options.subTextFontFamily}
        mobileSubTextColor={mobileOptions.subTextColor}
        mobileSubTextFontSize={mobileOptions.subTextFontSize}
        mobileSubTextFontWeight={mobileOptions.subTextFontWeight}
        mobileSubTextFontStyle={mobileOptions.subTextFontStyle}
        mobileSubTextFontFamily={mobileOptions.subTextFontFamily}
        iconClassNameBefore={options.iconClassNameBefore}
        iconClassNameAfter={options.iconClassNameAfter}
        hoverStyles={options?.hover}
        hoverMobileStyles={mobileOptions?.hover}
      />
      <FieldErrors errors={errors} align="center" />
    </React.Fragment>
  )
}

export default memo(PaypalRestPaymentButton)
