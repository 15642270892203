import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { usePayment } from 'publisher/store'
import {
  initializeProductQuantity,
  setProductActiveVariant,
} from 'publisher/store/payment/paymentActions'
import { getProduct } from 'publisher/store/payment/paymentSelectors'

export default function usePhysicalProductsFromQueryString() {
  const product = usePayment(getProduct)
  const dispatch = useDispatch()

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search)
    if (searchParams.get('productQuantity')) {
      dispatch(
        initializeProductQuantity(Number(searchParams.get('productQuantity'))),
      )
    }
    if (searchParams.get('activeVariantId') && product) {
      const activeVariant = product.productVariants.find(
        variant => variant.id === searchParams.get('activeVariantId'),
      )
      if (activeVariant) {
        dispatch(setProductActiveVariant(activeVariant))
      }
    }
  }, [])
}
