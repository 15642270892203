import styled from 'styled-components'

const ProductNameContainerUi = styled.div`
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.1px;
  color: #142d63;
`

export default ProductNameContainerUi
