import React from 'react'
import { entity } from 'common/types'
import CommonAgreement from 'common/components/entities/Agreement'
import StyleWrapper from 'common/components/StyleWrapper'
import useManagement, { selectors } from '../../hooks/useManagement'

function Agreement({ entity: { options, styles } }) {
  const agreement = useManagement(selectors.getAgreement)
  return (
    <StyleWrapper
      desktop={options.appearance.desktop}
      mobile={options.appearance.mobile}
    >
      <CommonAgreement
        htmlAttrId={options.htmlAttrId}
        styles={styles}
        text={agreement}
      />
    </StyleWrapper>
  )
}

Agreement.propTypes = {
  entity: entity.isRequired,
}

export default Agreement
