import styled, { css } from 'styled-components'
import {
  fontCss,
  FontProps,
  lineHeightCss,
  mobileFontCss,
  mobileLineHeightCss,
} from '../../../../utils/styleUtilsNew'

type PriceNameUiProps = {
  lineHeight: number
  mobileLineHeight?: number
} & FontProps

const PricePlanNameUi = styled.div<PriceNameUiProps>`
  //flex-basis: 40%;
  color: ${p => p.color};
  ${fontCss}
  ${lineHeightCss}
  ${p => p.theme.phone} {
    ${mobileFontCss}
    ${mobileLineHeightCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileFontCss}
      ${mobileLineHeightCss}
    `}
`

export default PricePlanNameUi
