import React from 'react'
import ProductLabelUi from 'common/components/entities/Product/ui/ProductLabelUi'
import ProductOptionValueContainerUi from 'common/components/entities/Product/ui/ProductOptionValueContainerUi'
import ProductOptionValuesContainerUi from 'common/components/entities/Product/ui/ProductOptionValuesContainerUi'
import ProductOptionsContainerUi from 'common/components/entities/Product/ui/ProductOptionsContainerUi'
import {
  ProductInterface,
  ProductOptionInterface,
  ProductOptionValueInterface,
  ProductVariantInterface,
} from 'common/types/entities/ProductInterface'

interface ProductOptionsProps {
  product: ProductInterface
  activeVariant: ProductVariantInterface
  changeActiveVariant: (value: ProductVariantInterface) => void
}

const isVariantAvailable = (
  product: ProductInterface,
  activeVariant: ProductVariantInterface,
  option: ProductOptionInterface,
  optionValue: ProductOptionValueInterface,
) => {
  const activeOptionValueIds = activeVariant.optionValueIds

  const changedOptionValueIds = activeOptionValueIds.map(id => {
    const optionValuesIds = option.optionValues.map(
      optionValue => optionValue.id,
    )
    if (optionValuesIds.includes(id)) {
      return optionValue.id
    }
    return id
  })
  const updatedVariant = product.productVariants.find(
    variant =>
      JSON.stringify(variant.optionValueIds) ===
      JSON.stringify(changedOptionValueIds),
  )

  if (updatedVariant) return true

  return false
}

function ProductOptions({
  product,
  activeVariant,
  changeActiveVariant,
}: ProductOptionsProps) {
  function handleChangeSelectedVariant(
    option: ProductOptionInterface,
    optionValue: ProductOptionValueInterface,
  ) {
    const activeOptionValueIds = activeVariant.optionValueIds

    const changedOptionValueIds = activeOptionValueIds.map(id => {
      const optionValuesIds = option.optionValues.map(
        optionValue => optionValue.id,
      )
      if (optionValuesIds.includes(id)) {
        return optionValue.id
      }
      return id
    })
    const updatedVariant = product.productVariants.find(
      variant =>
        JSON.stringify(variant.optionValueIds) ===
        JSON.stringify(changedOptionValueIds),
    )

    if (updatedVariant) changeActiveVariant(updatedVariant)
  }

  return (
    <>
      {product.options.map(option => (
        <ProductOptionsContainerUi key={option.id}>
          <ProductLabelUi>{option.name}</ProductLabelUi>
          <ProductOptionValuesContainerUi>
            {option.optionValues.map(optionValue => (
              <ProductOptionValueContainerUi
                key={optionValue.id}
                disabled={
                  !isVariantAvailable(
                    product,
                    activeVariant,
                    option,
                    optionValue,
                  )
                }
                active={activeVariant.optionValueIds.includes(optionValue.id)}
                onClick={() => handleChangeSelectedVariant(option, optionValue)}
              >
                {optionValue.value}
              </ProductOptionValueContainerUi>
            ))}
          </ProductOptionValuesContainerUi>
        </ProductOptionsContainerUi>
      ))}
    </>
  )
}

export default ProductOptions
