import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import CardFieldUi from 'common/components/entities/PaymentMethods/ui/CardFieldUi'
import CardLabelUi from 'common/components/entities/PaymentMethods/ui/CardLabelUi'
import CardRowUi from 'common/components/entities/PaymentMethods/ui/CardRowUi'
import FieldErrors from 'publisher/components/FieldErrors'
import { useDLocal } from 'publisher/context/DlocalContext'
import { CustomTaxNumberOptionsKeys } from 'publisher/pages/offer-page/constants/custom-tax-number-options'
import { useCustomTaxNumber } from 'publisher/pages/offer-page/hooks/useCustomTaxNumber'
import { usePage } from 'publisher/store'
import { getTaxNumberFieldEntity } from 'publisher/store/page/pageSelectors'
import CreditCardInputUi from '../ui/CreditCardInputUi'
import CreditCardInputWrapperUi from '../ui/CreditCardInputWrapperUi'

interface DLocalCreditCardProps {
  type: CustomTaxNumberOptionsKeys
}

function DLocalCreditCard({ type }: DLocalCreditCardProps) {
  const { t } = useTranslation('common')
  const dlocal = useDLocal()
  const taxNumberOptions = useCustomTaxNumber(type)

  function handleTaxNumberChange(event: React.ChangeEvent<HTMLInputElement>) {
    taxNumberOptions.change(event.target.value)
  }
  const taxNumberField = usePage(getTaxNumberFieldEntity)

  useEffect(() => {
    if (dlocal) {
      const panElement = document.getElementById('dlocal-pan')
      if (panElement) {
        dlocal.pan.mount(panElement)
      }
      const expirationElement = document.getElementById(
        'dlocal-card-expiration',
      )
      if (expirationElement) {
        dlocal.expiration.mount(expirationElement)
      }
      const cardCvvElement = document.getElementById('dlocal-card-cvv')
      if (cardCvvElement) {
        dlocal.cvv.mount(cardCvvElement)
      }
    }
  }, [dlocal])

  return (
    <>
      <CardRowUi>
        <CardFieldUi>
          <CardLabelUi htmlFor="dlocal-pan">
            {t('components.payment_methods.card_number')}
          </CardLabelUi>
          <CreditCardInputWrapperUi id="dlocal-pan" />
        </CardFieldUi>
      </CardRowUi>
      <CardRowUi>
        <CardFieldUi width="50%">
          <CardLabelUi htmlFor="dlocal-card-expiration">
            {t('components.payment_methods.expiration')}
          </CardLabelUi>
          <CreditCardInputWrapperUi id="dlocal-card-expiration" />
        </CardFieldUi>
        <CardFieldUi width="50%">
          <CardLabelUi htmlFor="dlocal-card-cvv">
            {t('components.payment_methods.cvc')}
          </CardLabelUi>
          <CreditCardInputWrapperUi id="dlocal-card-cvv" />
        </CardFieldUi>
      </CardRowUi>
      <CardRowUi>
        {!taxNumberField && (
          <CardFieldUi>
            <CardLabelUi htmlFor="mercado-card-identification-number">
              {t('components.payment_methods.identification_number')}
            </CardLabelUi>
            <CreditCardInputWrapperUi
              hasErrors={taxNumberOptions.errors.length > 0}
            >
              <CreditCardInputUi
                max={14}
                value={taxNumberOptions.value}
                onChange={handleTaxNumberChange}
              />
            </CreditCardInputWrapperUi>
            {taxNumberOptions.errors && (
              <FieldErrors errors={taxNumberOptions.errors} />
            )}
          </CardFieldUi>
        )}
      </CardRowUi>
    </>
  )
}

export default DLocalCreditCard
