import React from 'react'
import { PaymentMethodEnum } from '../../../enums/PaymentMethodEnum'
import PaymentMethodSwitcherItemImageUi from './ui/PaymentMethodSwitcherItemImageUi'
import getImageSrcAndDimensions from './utils/getImageSrcAndDimensions'

interface PaymentMethodSwitcherImageProps {
  method: PaymentMethodEnum
}

function PaymentMethodSwitcherImage({
  method,
}: PaymentMethodSwitcherImageProps) {
  const srcAndDimensions = getImageSrcAndDimensions(method)
  if (!srcAndDimensions) {
    return null
  }
  const { src, width, height } = srcAndDimensions
  return (
    <PaymentMethodSwitcherItemImageUi src={src} width={width} height={height} />
  )
}

export default PaymentMethodSwitcherImage
