import styled, { css } from 'styled-components'

const DialogBody = styled.div`
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  min-height: ${({ small }) => (small ? 200 : 466)}px;
  transition: min-height 0.3s ease-in;
  padding: 24px 36px;
  ${p =>
    p.center &&
    css`
      text-align: center;
    `}
`

export default DialogBody
