import React, { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

const DialogOverlay = styled.div<{ show: boolean }>`
  background-color: rgba(2, 0, 32, 0.9);
  bottom: 0;
  left: 0;
  opacity: ${({ show }) => (show ? 1 : 0)};
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.5s ease-in-out;
  transition: opacity 200ms;
  z-index: 10;
  width: 100vw;
  height: 100vh;
`

const CloseIconUi = styled.i`
  color: #9ba9b3;
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 24px;
  cursor: pointer;
  z-index: 15;
`

function lockScroll(state: boolean) {
  if (typeof document === 'undefined') {
    return
  }

  document.documentElement.style.overflow = state ? 'hidden' : ''
}

export default function Overlay({
  open,
  children,
  onClose,
  withCloseIcon = true,
}: {
  open: boolean
  children: React.ReactChild
  onClose: () => void
  withCloseIcon?: boolean
}) {
  const ref = useRef(null)

  useEffect(() => {
    lockScroll(open)
  }, [open])

  function handleClose(e: React.MouseEvent<HTMLDivElement>) {
    onClose()
  }

  return open ? (
    createPortal(
      <DialogOverlay ref={ref} onClick={handleClose} show={open}>
        {withCloseIcon && (
          <CloseIconUi className="fa fa-times" onClick={onClose} />
        )}
        {children}
      </DialogOverlay>,
      document.body,
    )
  ) : (
    <div />
  )
}
