import styled, { css } from 'styled-components'
import { CommonShippingFeesInterface } from 'common/types/entities/ShippingFeesInterface'
import {
  fontCss,
  lineHeightCss,
  marginCss,
  mobileFontCss,
  mobileLineHeightCss,
  mobileMarginCss,
  mobilePaddingsCss,
  paddingsCss,
} from 'common/utils/styleUtilsNew'

const ShippingFeesUi = styled.div<CommonShippingFeesInterface>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #142d63;
  font-size: 18px;
  ${marginCss}
  ${paddingsCss}
  ${fontCss}
  ${lineHeightCss}

  ${p => p.theme.phone} {
    ${mobileMarginCss}
    ${mobilePaddingsCss}
    ${mobileFontCss}
    ${mobileLineHeightCss}
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileMarginCss}
      ${mobilePaddingsCss}
      ${mobileFontCss}
      ${mobileLineHeightCss}
    `}
`

export default ShippingFeesUi
