import React from 'react'
import styled from 'styled-components'

const OrderBumpInput = styled.input`
  font-size: 20px;
  margin-right: 10px;
  min-height: 20px;
  min-width: 20px;
`

const OrderBumpCheckBoxUi = props => (
  <OrderBumpInput {...props} type="checkbox" />
)

export default OrderBumpCheckBoxUi
