import styled from 'styled-components'

const CardLabelUi = styled.label`
  margin-bottom: 10px;
  &:after {
    content: '*';
    color: red;
  }
`

export default CardLabelUi
