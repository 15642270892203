import axios from 'axios'
import { PageId } from '../store/page/PageStateInterface'
import { PurchaseProcessId } from '../store/payment/PaymentStateInterface'

export type RazorpayBuyOfferResponse = {
  rpOrderId?: string
  rpBumpOrderId: string
  rpSubscriptionId?: string
  rpBumpSubscriptionId: string
  redirectUrl: string
  vendorName: string
  keyId: string
  prefill: {
    name: string
    email: string
    phone: string
    address: string
  }
}

export function buyMainOffer(
  pageId: PageId,
  purchaseProcessId: PurchaseProcessId,
  body: any,
) {
  return axios.post<RazorpayBuyOfferResponse>(
    `/secure/razorpay/${pageId}/buy-main-offer/${purchaseProcessId}`,
    body,
  )
}

export function buyUpsell(
  pageId: PageId,
  purchaseProcessId: PurchaseProcessId,
  body: any,
) {
  return axios.post<RazorpayBuyOfferResponse>(
    `/secure/razorpay/${pageId}/buy-upsell/${purchaseProcessId}`,
    body,
  )
}

export function buyBump(
  pageId: PageId,
  purchaseProcessId: PurchaseProcessId,
  body: any,
) {
  return axios.post<RazorpayBuyOfferResponse>(
    `/secure/razorpay/${pageId}/buy-bump/${purchaseProcessId}`,
    body,
  )
}
