import { CurrencyEnum } from 'common/types/CurrencyType'
import { ShippingResponse } from 'common/types/shippingType'

export const mockedShippingResponse: ShippingResponse = {
  shippingServices: [
    {
      id: 1,
      name: 'DHL',
      rules: [
        {
          id: 1,
          name: 'Express delivery',
          price: 90,
          type: 'price',
          currency: CurrencyEnum.POUND_STERLING,
          shippingConditions: [
            {
              id: 1,
              min: 0,
              max: 10000,
              price: 9900,
            },
            {
              id: 2,
              min: 10000,
              max: 49999,
              price: 19900,
            },
          ],
        },
      ],
    },
    {
      id: 2,
      name: 'Pony Express',
      rules: [
        {
          id: 1,
          name: 'Regular delivery',
          price: 90,
          currency: CurrencyEnum.POUND_STERLING,
          type: 'weight',
          shippingConditions: [
            {
              id: 3,
              min: 100,
              max: 500,
              price: 3999,
            },
            {
              id: 4,
              min: 500,
              max: 999,
              price: 4999,
            },
          ],
        },
      ],
    },
  ],
}
