import { useRollbar } from '@rollbar/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { isOneShot } from 'common/components/entities/OfferPriceNew'
import { BadRequest, InternalError, NetworkError } from 'common/errors'
import { PricePlan } from 'common/types/OfferInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { ProductInterface } from 'common/types/entities/ProductInterface'
import { optInFail } from 'publisher/actions/optInActions'
import {
  RazorpayBuyOfferResponse,
  buyMainOffer,
} from 'publisher/api/razorpayApi'
import useRazorpay, {
  RazorpayOptions,
} from 'publisher/hooks/payment/useRazorpay'
import { usePage, usePayment } from 'publisher/store'
import { getPageId } from 'publisher/store/page/pageSelectors'
import {
  getActivePricePlan,
  getCheckedBump,
  getPurchaseProcessId,
} from 'publisher/store/payment/paymentSelectors'
import paymentSelectors from 'publisher/store/payment/paymentSelectors'
import { PaymentButtonInterface } from 'common/types/entities/PaymentButtonInterface'
import usePaymentSubmit from 'publisher/hooks/payment/usePaymentSubmit'

const isHundredPercentCouponBasedOnMainOfferResponse = (
  pricePlan: PricePlan | null | undefined,
  product: ProductInterface | null,
  mainPurchaseResponse: RazorpayBuyOfferResponse,
) => {
  if (
    ((pricePlan && isOneShot(pricePlan)) || product) &&
    !mainPurchaseResponse.rpOrderId
  ) {
    return true
  }
  if (
    pricePlan &&
    !isOneShot(pricePlan) &&
    !mainPurchaseResponse.rpSubscriptionId
  ) {
    return true
  }
  return false
}

export default function useRazorpayPaymentHandler(
  entity: OldEntityInterface | PaymentButtonInterface,
) {
  const rollbar = useRollbar()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { errors, setErrors, isLoading, submit } = usePaymentSubmit(entity)
  const purchaseProcessId = usePayment(getPurchaseProcessId)
  const pageId = usePage(getPageId)
  const { Razorpay, generateOptions, isRazorpayReady, isRazorpayFetching } =
    useRazorpay({ setErrors })
  const pricePlan = usePayment(getActivePricePlan)
  const bump = usePayment(getCheckedBump)
  const [bumpOptions, setBumpOptions] = useState<RazorpayOptions | null>(null)
  const product = usePayment(paymentSelectors.getProduct)

  const bumpPlan = bump && bump.pricePlans ? bump.pricePlans[0] : null
  const productBump = bump && bump.product ? bump.product : null

  useEffect(() => {
    if (bumpOptions) {
      const razorpay = new Razorpay(bumpOptions)
      razorpay.open()
    }
  }, [bumpOptions, Razorpay])

  const proceedBumpPlan = async (
    mainPurchaseResponse: RazorpayBuyOfferResponse,
  ) => {
    // process bump
    const bumpOptions = generateOptions(mainPurchaseResponse, bumpPlan, true)
    bumpOptions.modal = {
      ondismiss: function () {
        window.location.assign(mainPurchaseResponse.redirectUrl)
      },
    }
    await new Promise(() =>
      // without timeout RP popup is not opened sometimes
      // and it doesn't matter if we use useState or not
      setTimeout(() => setBumpOptions(bumpOptions), 1000),
    )
  }

  const proceedProductBumpPlan = async (
    mainPurchaseResponse: RazorpayBuyOfferResponse,
  ) => {
    // process product bump
    const productBumpOptions = generateOptions(
      mainPurchaseResponse,
      null,
      true,
      productBump,
    )
    productBumpOptions.modal = {
      ondismiss: function () {
        window.location.assign(mainPurchaseResponse.redirectUrl)
      },
    }
    await new Promise(() =>
      // without timeout RP popup is not opened sometimes
      // and it doesn't matter if we use useState or not
      setTimeout(() => setBumpOptions(productBumpOptions), 1000),
    )
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    submit(async body => {
      try {
        const { data: mainPurchaseResponse } = await buyMainOffer(
          pageId,
          purchaseProcessId,
          {
            payment_form: body,
          },
        )
        const isHundredPercentCoupon =
          isHundredPercentCouponBasedOnMainOfferResponse(
            pricePlan,
            product,
            mainPurchaseResponse,
          )

        if (!isHundredPercentCoupon) {
          //case when we need to show all modals -> then redirect
          const options = generateOptions(
            mainPurchaseResponse,
            pricePlan,
            false,
            product,
          )
          if (bumpPlan) {
            options.handler = async () => {
              await proceedBumpPlan(mainPurchaseResponse)
            }
          }
          if (productBump) {
            options.handler = async () => {
              await proceedProductBumpPlan(mainPurchaseResponse)
            }
          }

          if (isRazorpayReady) {
            const razorpay = new Razorpay(options)
            razorpay.open()
          } else {
            throw new Error('form submitted before razorpay was ready')
          }

          return
        } else if (bumpPlan || productBump) {
          //case when we need to show only bump modal -> then redirect
          if (bumpPlan) {
            await proceedBumpPlan(mainPurchaseResponse)
          }
          if (productBump) {
            await proceedProductBumpPlan(mainPurchaseResponse)
          }
        } else {
          //case when we need to only redirect
          if (mainPurchaseResponse.redirectUrl) {
            window.location.assign(mainPurchaseResponse.redirectUrl)
            return
          }
        }
      } catch (error) {
        if (error instanceof BadRequest) {
          setErrors(error.response.data.errors.common)
          dispatch(optInFail({ fields: error.response.data.errors.fields }))
        } else if (error instanceof NetworkError) {
          setErrors([t('core.errors.no_connection')])
        } else if (error instanceof InternalError) {
          setErrors([t('core.error.title')])
        } else {
          rollbar.captureEvent(body, 'debug')
          rollbar.error('Razorpay error', error as Error, body)
        }
      }
    })
  }

  return {
    errors,
    isLoading,
    isRazorpayReady,
    isRazorpayFetching,
    handleSubmit,
  }
}
