import React from 'react'
import PaymentMethodDetailsUi from 'common/components/entities/PaymentMethodNew/ui/PaymentMethodDetailsUi'
import paymentMethodTypes from 'common/constants/paymentMethodTypes'
import { PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'
import StripeCreditCard from 'publisher/pages/offer-page/components/PaymentMethod/PaymentMethodDetails/methods/stripe/CreditCard'
import { CustomTaxNumberOptionsKeys } from 'publisher/pages/offer-page/constants/custom-tax-number-options'
import { usePage } from 'publisher/store'
import { usePayment } from 'publisher/store'
import { getTaxNumberFieldEntity } from 'publisher/store/page/pageSelectors'
import paymentSelectors from 'publisher/store/payment/paymentSelectors'
import PixOrBoletoTaxNumber from './PixOrBoletoTaxNumber'
import StripeIdeal from './StripeIdeal'
import DLocalCreditCard from './dlocal/DLocalCreditCard'
import MercadoPagoCreditCard from './mercado-pago/MercadoPagoCreditCard'
import XenditCreditCard from './xendit/XenditCreditCard'

interface PaymentMethodDetailsProps {
  isActive: boolean
  paymentMethod: string
}

function PaymentMethodDetails({
  paymentMethod,
  isActive,
}: PaymentMethodDetailsProps) {
  const taxNumberField = usePage(getTaxNumberFieldEntity)
  const instruction = usePayment(paymentSelectors.getCashOnDeliveryInstruction)

  if (
    taxNumberField &&
    (paymentMethod === PaymentMethodEnum.MercadoPagoPix ||
      paymentMethod === PaymentMethodEnum.MercadoPagoBoleto ||
      paymentMethod === PaymentMethodEnum.DlocalPix ||
      paymentMethod === PaymentMethodEnum.DlocalBoleto)
  ) {
    return null
  }

  let details
  switch (paymentMethod) {
    case paymentMethodTypes.STRIPE_CARD:
      details = <StripeCreditCard />
      break
    case paymentMethodTypes.STRIPE_IDEAL:
      details = <StripeIdeal />
      break
    case paymentMethodTypes.DLOCAL_CARD:
      details = (
        <DLocalCreditCard type={paymentMethod as CustomTaxNumberOptionsKeys} />
      )
      break
    case paymentMethodTypes.MERCADO_PAGO_CARD:
    case paymentMethodTypes.MERCADO_PAGO_BR_CARD:
    case paymentMethodTypes.MERCADO_PAGO_CO_CARD:
    case paymentMethodTypes.MERCADO_PAGO_MX_CARD:
    case paymentMethodTypes.MERCADO_PAGO_AR_CARD:
    case paymentMethodTypes.MERCADO_PAGO_CL_CARD:
      details = (
        <MercadoPagoCreditCard
          type={paymentMethod as CustomTaxNumberOptionsKeys}
        />
      )
      break
    case PaymentMethodEnum.MercadoPagoPix:
    case PaymentMethodEnum.MercadoPagoBoleto:
      details = <PixOrBoletoTaxNumber id="dlocal-pan" type={paymentMethod} />
      break
    case PaymentMethodEnum.DlocalPix:
    case PaymentMethodEnum.DlocalBoleto:
      details = (
        <PixOrBoletoTaxNumber
          id="mercado-card-identification-number"
          type={paymentMethod}
        />
      )
      break
    case PaymentMethodEnum.XenditIdCard:
    case PaymentMethodEnum.XenditPhCard:
      details = <XenditCreditCard />
      break
    // case PaymentMethodEnum.RazorpayCard:
    //   details = <RazorpayCreditCard />
    //   break
    case PaymentMethodEnum.CashOnDelivery: {
      if (!instruction) {
        details = ''
      } else {
        details = <div dangerouslySetInnerHTML={{ __html: instruction }} />
      }
      break
    }
    case PaymentMethodEnum.PaystackGhCard:
    case PaymentMethodEnum.PaystackKeCard:
    case PaymentMethodEnum.PaystackNgCard:
    case PaymentMethodEnum.PaystackZaCard:
    case PaymentMethodEnum.PaystackEgCard:
    case PaymentMethodEnum.PaystackCiCard: {
      details = ''
      break
    }
    default:
      details = ''
  }

  if (!details) return null

  return (
    <PaymentMethodDetailsUi show={isActive}>{details}</PaymentMethodDetailsUi>
  )
}

export default PaymentMethodDetails
