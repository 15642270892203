import styled, { css } from 'styled-components'

const CardRowUi = styled.div<{ flexDirectionColumn?: boolean }>`
  display: flex;
  gap: 20px;
  margin-bottom: 18px;
  ${p =>
    p.flexDirectionColumn &&
    css`
      flex-direction: column;
      gap: 0;
    `}
`

export default CardRowUi
