import styled from 'styled-components'

interface ProductOptionValueContainerUiProps {
  disabled?: boolean
  active: boolean
}

const ProductOptionValueContainerUi = styled.div<ProductOptionValueContainerUiProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px 10px;
  border-radius: 20px;
  border: 1px solid #e1e3e5;
  cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};
  font-size: 14px;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.1px;
  color: #8196ab;
  background: ${p =>
    p.disabled ? 'lightgray' : p.active ? '#cfedff' : 'initial'};
  &:hover {
    background: ${p => (p.disabled ? 'lightgray' : '#cfedff')};
  }
`

export default ProductOptionValueContainerUi
