import React from 'react'
import { Helmet } from 'react-helmet'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import FieldErrors from 'publisher/components/FieldErrors'
import BaseButton from 'publisher/components/entities/BaseButton'
import useStripeCardPaymentHandler from 'publisher/hooks/payment/useStripeCardPaymentHandler'

export interface StripeCardPaymentButtonProps {
  entity: OldEntityInterface
}

const StripeCardPaymentButton = ({ entity }: StripeCardPaymentButtonProps) => {
  const { styles, mobileStyles, options, mobileOptions } = entity
  const { errors, isReady, isLoading, handleSubmit } =
    useStripeCardPaymentHandler(entity)

  return (
    <React.Fragment>
      <Helmet
        link={[
          {
            rel: 'stylesheet',
            href: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/css/stripe.css?ver=1.2`,
          },
        ]}
      />
      <BaseButton
        handleClick={handleSubmit}
        disabled={isLoading || !isReady}
        styles={styles}
        mobileStyles={mobileStyles}
        attrId={options.attrId}
        text={options.text}
        borderType={options.borderType}
        mobileBorderType={mobileOptions.borderType}
        appearance={options.appearance}
        loading={isLoading}
        loadingText={options.loadingText}
        subText={options.subText}
        subTextColor={options.subTextColor}
        subTextFontSize={options.subTextFontSize}
        subTextFontWeight={options.subTextFontWeight}
        subTextFontStyle={options.subTextFontStyle}
        subTextFontFamily={options.subTextFontFamily}
        mobileSubTextColor={mobileOptions.subTextColor}
        mobileSubTextFontSize={mobileOptions.subTextFontSize}
        mobileSubTextFontWeight={mobileOptions.subTextFontWeight}
        mobileSubTextFontStyle={mobileOptions.subTextFontStyle}
        mobileSubTextFontFamily={mobileOptions.subTextFontFamily}
        iconClassNameBefore={options.iconClassNameBefore}
        iconClassNameAfter={options.iconClassNameAfter}
        hoverStyles={options?.hover}
        hoverMobileStyles={mobileOptions?.hover}
      />
      <FieldErrors errors={errors} align="center" />
    </React.Fragment>
  )
}

export default StripeCardPaymentButton
