import styled from 'styled-components'

const CouponDiscountUi = styled.div`
  width: 100%;
  flex: 1;
  color: forestgreen;
  padding-left: 5px;
  font-size: 13px;
`

export default CouponDiscountUi

