import React, { useEffect } from 'react'
import CommonCustomerType from 'common/components/entities/CustomerType/CustomerTypeNew'
import { CustomerTypeEnum } from 'common/enums/CustomerTypeEnum'
import { useActions } from 'common/hooks/useActions'
import { CustomerTypeInterface } from 'common/types/entities/CustomerTypeInteface'
import * as paymentActions from 'publisher/actions/paymentActions'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import { usePayment } from 'publisher/store'
import { getCustomerType } from 'publisher/store/payment/paymentSelectors'

interface CustomerTypeNewProps {
  entity: CustomerTypeInterface
}

function CustomerTypeNew({ entity }: CustomerTypeNewProps) {
  const customerType = usePayment(getCustomerType)
  const toggleCustomerType = useActions(paymentActions.toggleCustomerType)

  useEffect(() => {
    if (!customerType) {
      // if customer type is undefined we must set it as a personal
      toggleCustomerType(CustomerTypeEnum.Personal)
    }
  }, [])

  return (
    <StyleWrapper
      margin={entity.margin}
      mobileMargin={entity.mobileMargin}
      appearance={entity.appearance}
    >
      {customerType && (
        <CommonCustomerType
          toggleCustomerType={toggleCustomerType}
          activeCustomerType={customerType}
        />
      )}
    </StyleWrapper>
  )
}

export default CustomerTypeNew
