import PropTypes from 'prop-types'
import React from 'react'
import { Translation } from 'react-i18next'
import CouponButtonUi from './ui/CouponButtonUi'
import CouponDiscountUi from './ui/CouponDiscountUi'
import CouponErrorUi from './ui/CouponErrorUi'
import CouponFieldUi from './ui/CouponFieldUi'
import CouponUi from './ui/CouponUi'
import CouponWrapperUi from './ui/CouponWrapperUi'

const Coupon = props => {
  const {
    styles,
    mobileStyles,
    fieldStyles,
    mobileFieldStyles,
    buttonStyles,
    mobileButtonStyles,
    placeholder,
    couponCode,
    update,
    check,
    buttonText,
    discount,
    couponError,
    checking,
    htmlAttrId,
  } = props
  return (
    <CouponWrapperUi
      attrId={htmlAttrId}
      styles={styles}
      mobileStyles={mobileStyles}
    >
      <Translation ns={['common']}>
        {t => (
          <React.Fragment>
            <CouponUi>
              <CouponFieldUi
                value={couponCode}
                onChange={e => update(e.target.value)}
                styles={{
                  ...fieldStyles,
                  width: styles.width,
                }}
                mobileStyles={mobileFieldStyles}
                placeholder={placeholder}
              />
              <CouponButtonUi
                checking={checking}
                onClick={e => {
                  e.preventDefault()
                  return check(e)
                }}
                styles={{
                  ...buttonStyles,
                  width: styles.width,
                }}
                mobileStyles={mobileButtonStyles}
              >
                {buttonText}
              </CouponButtonUi>
            </CouponUi>
            {discount && (
              <CouponDiscountUi>
                {t('components.coupon.discount')} {discount}
              </CouponDiscountUi>
            )}
            {couponError && <CouponErrorUi>{couponError}</CouponErrorUi>}
          </React.Fragment>
        )}
      </Translation>
    </CouponWrapperUi>
  )
}

Coupon.propTypes = {
  couponCode: PropTypes.string,
  buttonText: PropTypes.string,
  check: PropTypes.func,
  update: PropTypes.func,
  styles: PropTypes.objectOf(PropTypes.string),
  mobileStyles: PropTypes.objectOf(PropTypes.string),
  fieldStyles: PropTypes.objectOf(PropTypes.string),
  mobileFieldStyles: PropTypes.objectOf(PropTypes.string),
  buttonStyles: PropTypes.objectOf(PropTypes.string),
  mobileButtonStyles: PropTypes.objectOf(PropTypes.string),
  placeholder: PropTypes.string,
  discount: PropTypes.string,
  couponError: PropTypes.string,
  checking: PropTypes.bool,
  htmlAttrId: PropTypes.string,
}

Coupon.defaultProps = {
  couponCode: '',
  check: () => {},
  update: () => {},
  styles: {},
  mobileStyles: {},
  fieldStyles: {},
  mobileFieldStyles: {},
  buttonStyles: {},
  mobileButtonStyles: {},
  buttonText: '',
  placeholder: '',
  discount: '',
  couponError: '',
  checking: false,
}

export default Coupon
