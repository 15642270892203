import styled, { css } from 'styled-components'

const PaymentMethodSwitcherItemUi = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  padding: 14px 55px 14px 22px;
  border-bottom: 1px solid var(--payment-method-border-color);
  &:last-child {
    border-bottom: none;
  }
  ${p => p.theme.phone} {
    padding-right: 10px;
    padding-left: 10px;
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      padding-right: 10px;
      padding-left: 10px;
    `}
`

export default PaymentMethodSwitcherItemUi
