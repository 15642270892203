import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import * as TwoStepPaymentForm from 'common/components/entities/TwoStepPaymentForm'
import paymentMethodTypes from 'common/constants/paymentMethodTypes'
import TwoStepSecureAndPayPaymentButton from 'publisher/components/entities/TwoStepPaymentForm/TwoStepSecureAndPayPaymentButton'
import usePayment, { selectors } from 'publisher/hooks/usePayment'
import BaseNodeChildren from '../../ChildrenEntities'
import TwoStepCashOnDeliveryPaymentButton from './TwoStepCashOnDeliveryPaymentButton'
import TwoStepDLocalPaymentButton from './TwoStepDLocalPaymentButton'
import TwoStepFlutterwavePaymentButton from './TwoStepFlutterwavePaymentButton'
import TwoStepGocardlessPaymentButton from './TwoStepGocardlessPaymentButton'
import TwoStepMercadoPagoCreditCardPaymentButton from './TwoStepMercadoPagoCreditCardPaymentButton'
import TwoStepMercadoPagoPixAndBoletoPaymentButton from './TwoStepMercadoPagoPixAndBoletoPaymentButton'
import TwoStepMolliePaymentButton from './TwoStepMolliePaymentButton'
import PaypalPaymentButton from './TwoStepPaypalPaymentButton'
import TwoStepPaystackPaymentButton from './TwoStepPaystackPaymentButton'
import TwoStepPixAndBoletoPaymentButton from './TwoStepPixAndBoletoPaymentButton'
import TwoStepRazorpayPaymentButton from './TwoStepRazorpayPaymentButton'
import TwoStepStripeBancontactPaymentButton from './TwoStepStripeBancontactPaymentButton'
import StripeCardPaymentButton from './TwoStepStripeCardPaymentButton'
import TwoStepStripeIdealPaymentButton from './TwoStepStripeIdealPaymentButton'

const {
  STRIPE_CARD,
  STRIPE_IDEAL,
  PAYPAL_SOAP,
  STRIPE_BANCONTACT,
  DLOCAL_BOLETO,
  DLOCAL_CARD,
  DLOCAL_PIX,
  MERCADO_PAGO_CARD,
  MERCADO_PAGO_PIX,
  MERCADO_PAGO_BOLETO,
  MERCADO_PAGO_BR_BOLETO,
  MERCADO_PAGO_BR_PIX,
  MERCADO_PAGO_BR_CARD,
  MERCADO_PAGO_CO_CARD,
  MERCADO_PAGO_MX_CARD,
  MERCADO_PAGO_AR_CARD,
  RAZORPAY,
  FLUTTERWAVE,
  MOLLIE,
  GOCARDLESS,
  CASH_ON_DELIVERY,
  PAYSTACK_GH_CARD,
  PAYSTACK_KE_CARD,
  PAYSTACK_NG_CARD,
  PAYSTACK_ZA_CARD,
  PAYSTACK_EG_CARD,
  PAYSTACK_CI_CARD,
  MERCADO_PAGO_CL_CARD,
  SECURE_AND_PAY,
} = paymentMethodTypes

function PaymentStep({ entity, buttonStyles, mobileButtonStyles }) {
  const paymentMethod = usePayment(selectors.getActivePaymentMethod)
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
      })
    }
  }, [])

  return (
    <TwoStepPaymentForm.Step ref={ref}>
      <BaseNodeChildren childIds={entity.childIds} />
      {paymentMethod === STRIPE_CARD && (
        <StripeCardPaymentButton
          entity={entity}
          styles={buttonStyles}
          mobileStyles={mobileButtonStyles}
        />
      )}
      {paymentMethod === STRIPE_IDEAL && (
        <TwoStepStripeIdealPaymentButton
          entity={entity}
          styles={buttonStyles}
          mobileStyles={mobileButtonStyles}
        />
      )}
      {paymentMethod === STRIPE_BANCONTACT && (
        <TwoStepStripeBancontactPaymentButton
          entity={entity}
          styles={buttonStyles}
          mobileStyles={mobileButtonStyles}
        />
      )}
      {paymentMethod === PAYPAL_SOAP && (
        <PaypalPaymentButton
          entity={entity}
          styles={buttonStyles}
          mobileStyles={mobileButtonStyles}
        />
      )}
      {paymentMethod === DLOCAL_CARD && (
        <TwoStepDLocalPaymentButton
          entity={entity}
          styles={buttonStyles}
          mobileStyles={mobileButtonStyles}
        />
      )}
      {(paymentMethod === DLOCAL_PIX || paymentMethod === DLOCAL_BOLETO) && (
        <TwoStepPixAndBoletoPaymentButton
          entity={entity}
          styles={buttonStyles}
          mobileStyles={mobileButtonStyles}
        />
      )}
      {(paymentMethod === MERCADO_PAGO_CARD ||
        paymentMethod === MERCADO_PAGO_BR_CARD ||
        paymentMethod === MERCADO_PAGO_CO_CARD ||
        paymentMethod === MERCADO_PAGO_MX_CARD ||
        paymentMethod === MERCADO_PAGO_AR_CARD ||
        paymentMethod === MERCADO_PAGO_CL_CARD) && (
        <TwoStepMercadoPagoCreditCardPaymentButton
          entity={entity}
          styles={buttonStyles}
          mobileStyles={mobileButtonStyles}
        />
      )}
      {(paymentMethod === MERCADO_PAGO_PIX ||
        paymentMethod === MERCADO_PAGO_BOLETO ||
        paymentMethod === MERCADO_PAGO_BR_BOLETO ||
        paymentMethod === MERCADO_PAGO_BR_PIX) && (
        <TwoStepMercadoPagoPixAndBoletoPaymentButton
          entity={entity}
          styles={buttonStyles}
          mobileStyles={mobileButtonStyles}
        />
      )}
      {paymentMethod === RAZORPAY && (
        <TwoStepRazorpayPaymentButton
          entity={entity}
          styles={buttonStyles}
          mobileStyles={mobileButtonStyles}
        />
      )}
      {paymentMethod === FLUTTERWAVE && (
        <TwoStepFlutterwavePaymentButton
          entity={entity}
          styles={buttonStyles}
          mobileStyles={mobileButtonStyles}
        />
      )}
      {paymentMethod === MOLLIE && (
        <TwoStepMolliePaymentButton
          entity={entity}
          styles={buttonStyles}
          mobileStyles={mobileButtonStyles}
        />
      )}
      {paymentMethod === GOCARDLESS && (
        <TwoStepGocardlessPaymentButton
          entity={entity}
          styles={buttonStyles}
          mobileStyles={mobileButtonStyles}
        />
      )}
      {paymentMethod === CASH_ON_DELIVERY && (
        <TwoStepCashOnDeliveryPaymentButton
          entity={entity}
          styles={buttonStyles}
          mobileStyles={mobileButtonStyles}
        />
      )}
      {paymentMethod === SECURE_AND_PAY && (
        <TwoStepSecureAndPayPaymentButton
          entity={entity}
          styles={buttonStyles}
          mobileStyles={mobileButtonStyles}
        />
      )}
      {(paymentMethod === PAYSTACK_GH_CARD ||
        paymentMethod === PAYSTACK_KE_CARD ||
        paymentMethod === PAYSTACK_NG_CARD ||
        paymentMethod === PAYSTACK_ZA_CARD ||
        paymentMethod === PAYSTACK_EG_CARD ||
        paymentMethod === PAYSTACK_CI_CARD) && (
        <TwoStepPaystackPaymentButton
          entity={entity}
          styles={buttonStyles}
          mobileStyles={mobileButtonStyles}
        />
      )}
      {entity.options.footerText && (
        <TwoStepPaymentForm.StepFooter>
          {entity.options.footerText}
        </TwoStepPaymentForm.StepFooter>
      )}
    </TwoStepPaymentForm.Step>
  )
}

PaymentStep.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    options: PropTypes.shape({
      buttonText: PropTypes.string.isRequired,
      buttonSubText: PropTypes.string.isRequired,
      footerText: PropTypes.string.isRequired,
    }).isRequired,
    childIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  buttonStyles: PropTypes.shape({
    buttonBackgroundColor: PropTypes.string.isRequired,
    buttonTextColor: PropTypes.string.isRequired,
  }).isRequired,
  mobileButtonStyles: PropTypes.shape({
    buttonBackgroundColor: PropTypes.string.isRequired,
    buttonTextColor: PropTypes.string.isRequired,
  }).isRequired,
}

export default PaymentStep
