import styled from 'styled-components'

const OrderBumpUi = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-height: 59px;
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${({ styles = {} }) => styles}
`

export default OrderBumpUi
