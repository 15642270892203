import styled, { css } from 'styled-components'
import { CommonTextPropsInterface } from 'common/types/CommonTextPropsInterface'
import {
  fontCss,
  mobileFontCss,
  lineHeightCss,
  mobileLineHeightCss,
} from 'common/utils/styleUtilsNew'

type PaymentMethodLabelUiProps = Omit<
  CommonTextPropsInterface,
  'fontFileId' | 'mobileFontFileId'
>

const PaymentMethodLabelUi = styled.span<PaymentMethodLabelUiProps>`
  ${fontCss};
  ${lineHeightCss}
  ${p => p.theme.phone} {
    ${mobileFontCss};
    ${mobileLineHeightCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileFontCss};
      ${mobileLineHeightCss}
    `}
`

export default PaymentMethodLabelUi
