import React from 'react'
import { useTranslation } from 'react-i18next'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import FieldErrors from 'publisher/components/FieldErrors'
import { useXendit } from 'publisher/context/XenditContext'
import Overlay from 'publisher/pages/offer-page/components/Overlay'
import CreditCardFieldUi from '../../shared/ui/CreditCardFieldUi'
import CreditCardInputUi from '../../shared/ui/CreditCardInputUi'
import CreditCardInputWrapperUi from '../../shared/ui/CreditCardInputWrapperUi'
import CreditCardRowUi from '../../shared/ui/CreditCardRowUi'
import getFormattedCardExpiry from '../../shared/utils/get-formatted-card-expiry'
import {
  xenditCardExpirationMonthId,
  xenditCardHolderNameId,
  xenditCardNumberMaskId,
  xenditCardSecurityCodeId,
} from './constants/creditCardFieldIds'
import CloseIconUi from './ui/CloseIconUi'
import CloseIconWrapperUi from './ui/CloseIconWrapperUi'
import { XenditIframeContainer, XenditIframeUi } from './ui/XenditIframeUi'

function CreditCard() {
  const {
    fields,
    changeFields,
    errors,
    resetError,
    isSecureModalVisible,
    setIsSecureModalVisible,
  } = useXendit()
  const { t } = useTranslation(['common', 'publisher'])

  function changeCardNumber({ value }: NumberFormatValues) {
    changeFields.onChangeCardNumber(value)
    resetError('cardNumber', '')
  }

  function changeCardExpiry({ value }: NumberFormatValues) {
    changeFields.onChangeMonth(value.substring(0, 2))
    changeFields.onChangeYear('20' + value.substring(2, 4))
    resetError('expiryDate', '')
  }

  function handleCardholderNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.target.value = e.target.value.toUpperCase()
    changeFields.onChangeCardHolderName(e.target.value)
  }

  function handleCvv(e: React.ChangeEvent<HTMLInputElement>) {
    changeFields.onChangeCvv(e.target.value)
    resetError('cardCvn', '')
  }

  return (
    <>
      <CreditCardRowUi>
        <CreditCardFieldUi width="70%">
          <CreditCardInputWrapperUi htmlFor={xenditCardNumberMaskId}>
            {t('components.payment_methods.card_number')}
          </CreditCardInputWrapperUi>
          <CreditCardInputWrapperUi hasErrors={Boolean(errors.cardNumber)}>
            <NumberFormat
              id={xenditCardNumberMaskId}
              format="#### #### #### ####"
              placeholder="1234 1234 1234 1234"
              customInput={CreditCardInputUi}
              onValueChange={changeCardNumber}
              value={fields.cardNumber}
            />
          </CreditCardInputWrapperUi>
          {errors.cardNumber && <FieldErrors errors={[errors.cardNumber]} />}
        </CreditCardFieldUi>
        <CreditCardFieldUi width="30%">
          <CreditCardInputWrapperUi htmlFor={xenditCardExpirationMonthId}>
            {t('components.payment_methods.expiration_date')}
          </CreditCardInputWrapperUi>
          <CreditCardInputWrapperUi hasErrors={Boolean(errors.expiryDate)} flex>
            <NumberFormat
              format={getFormattedCardExpiry}
              placeholder="MM / YY"
              customInput={CreditCardInputUi}
              onValueChange={changeCardExpiry}
            />
          </CreditCardInputWrapperUi>
          {errors.expiryDate && <FieldErrors errors={[errors.expiryDate]} />}
        </CreditCardFieldUi>
      </CreditCardRowUi>
      <CreditCardRowUi>
        <CreditCardFieldUi width="70%">
          <CreditCardInputWrapperUi htmlFor={xenditCardHolderNameId}>
            {t('components.payment_methods.card_holder_name')}
          </CreditCardInputWrapperUi>
          <CreditCardInputWrapperUi>
            <CreditCardInputUi
              type="text"
              id={xenditCardHolderNameId}
              onChange={handleCardholderNameChange}
              value={fields.cardHolderName}
            />
          </CreditCardInputWrapperUi>
        </CreditCardFieldUi>
        <CreditCardFieldUi width="30%">
          <CreditCardInputWrapperUi htmlFor={xenditCardSecurityCodeId}>
            {t('components.payment_methods.security_code')}
          </CreditCardInputWrapperUi>
          <CreditCardInputWrapperUi hasErrors={Boolean(errors.cardCvn)}>
            <CreditCardInputUi
              id={xenditCardSecurityCodeId}
              onChange={handleCvv}
              value={fields.cvv}
              maxLength={4}
            />
          </CreditCardInputWrapperUi>
          {errors.cardCvn && <FieldErrors errors={[errors.cardCvn]} />}
        </CreditCardFieldUi>
      </CreditCardRowUi>
      <Overlay
        open={isSecureModalVisible}
        onClose={() => setIsSecureModalVisible(false)}
        withCloseIcon={false}
      >
        <XenditIframeContainer>
          <CloseIconWrapperUi>
            <CloseIconUi
              className="fa fa-times"
              onClick={() => setIsSecureModalVisible(false)}
            />
          </CloseIconWrapperUi>
          <XenditIframeUi />
        </XenditIframeContainer>
      </Overlay>
    </>
  )
}

export default CreditCard
