import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  OverlayProductImagesCarousel,
  OverlayCarouselSlide,
} from 'common/components/entities/Product/ProductImagesCarousel'
import { ImageFileInterface } from 'common/types/entities/ProductInterface'
import Overlay from '../Overlay'

const CarouselImageContainerUi = styled.img`
  border-radius: 8px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 45%;
`
interface OverlayCarouselProps {
  open: boolean
  setOpen: (value: boolean) => void
  slides: ImageFileInterface[]
  initialActiveSlideIndex: number
}
function OverlayCarousel({
  open,
  setOpen,
  slides,
  initialActiveSlideIndex,
}: OverlayCarouselProps) {
  const carouselRef = useRef<HTMLDivElement>(null)
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)
  const [needScroll, setNeedScroll] = useState(false)

  function updateClosedActiveSlideIndex(index: number) {
    return function () {
      setActiveSlideIndex(prevIndex => {
        setNeedScroll(prevIndex !== index)
        return index
      })
    }
  }

  function updateActiveSlideIndex(index: number) {
    setActiveSlideIndex(prevIndex => {
      setNeedScroll(prevIndex !== index)
      return index
    })
  }

  useEffect(() => {
    if (activeSlideIndex !== initialActiveSlideIndex) {
      setActiveSlideIndex(prev => {
        setNeedScroll(initialActiveSlideIndex !== prev)
        return initialActiveSlideIndex
      })
    }
  }, [initialActiveSlideIndex])

  return (
    <Overlay open={open} onClose={() => setOpen(false)}>
      <OverlayProductImagesCarousel
        totalItems={slides.length}
        activeSlideIndex={activeSlideIndex}
        switchActiveSlideNumber={updateActiveSlideIndex}
        carouselRef={carouselRef}
      >
        {slides.map((image, index) => (
          <OverlayCarouselSlide
            key={`overlay-${image.id}`}
            active={
              slides.findIndex(el => el.id === image.id) === activeSlideIndex
            }
            setActive={updateClosedActiveSlideIndex(index)}
            needScroll={needScroll}
            carouselRef={carouselRef}
            needToSetInitialSlide={initialActiveSlideIndex !== 0}
          >
            <CarouselImageContainerUi
              key={`overlay-${image.id}`}
              src={image.path}
            />
          </OverlayCarouselSlide>
        ))}
      </OverlayProductImagesCarousel>
    </Overlay>
  )
}
export default OverlayCarousel
