import React from 'react'
import Coupon from 'common/components/entities/OfferPriceNew/Coupon'
import {
  PricePlanProps,
  ReadablePricePlanAmount,
} from 'common/components/entities/OfferPriceNew/PricePlan'
import Total from 'common/components/entities/OfferPriceNew/Total'
import Vat from 'common/components/entities/OfferPriceNew/Vat'
import CurrencyBadgeUi from 'common/components/entities/OfferPriceNew/ui/CurrencyBadgeUi'
import PricePlanAmountUi from 'common/components/entities/OfferPriceNew/ui/PricePlanAmountUi'
import PricePlanItemUi from 'common/components/entities/OfferPriceNew/ui/PricePlanItemUi'
import PricePlanNameUi from 'common/components/entities/OfferPriceNew/ui/PricePlanNameUi'
import PricePlanUi from 'common/components/entities/OfferPriceNew/ui/PricePlanUi'

const BumpPricePlan = ({
  plan,
  pricePlanPricing,
  coupon,
  locale,
  nameFontSize,
  mobileNameFontSize,
  nameFontStyle,
  mobileNameFontStyle,
  nameFontWeight,
  mobileNameFontWeight,
  nameFontFamily,
  mobileNameFontFamily,
  nameLineHeight,
  mobileNameLineHeight,
  nameColor,
  mobileNameColor,
  amountFontSize,
  mobileAmountFontSize,
  amountFontStyle,
  mobileAmountFontStyle,
  amountFontWeight,
  mobileAmountFontWeight,
  amountFontFamily,
  mobileAmountFontFamily,
  amountLineHeight,
  mobileAmountLineHeight,
  amountColor,
  mobileAmountColor,
  descriptionFontSize,
  mobileDescriptionFontSize,
  descriptionFontStyle,
  mobileDescriptionFontStyle,
  descriptionFontWeight,
  mobileDescriptionFontWeight,
  descriptionFontFamily,
  mobileDescriptionFontFamily,
  descriptionLineHeight,
  mobileDescriptionLineHeight,
  descriptionColor,
  mobileDescriptionColor,
  dataTestId,
}: PricePlanProps) => {
  const needTotal = pricePlanPricing.taxAmount > 0 || !!coupon

  return (
    <PricePlanUi>
      <PricePlanItemUi>
        <PricePlanNameUi
          fontSize={nameFontSize}
          mobileFontSize={mobileNameFontSize}
          fontStyle={nameFontStyle}
          mobileFontStyle={mobileNameFontStyle}
          fontWeight={nameFontWeight}
          mobileFontWeight={mobileNameFontWeight}
          fontFamily={nameFontFamily}
          mobileFontFamily={mobileNameFontFamily}
          lineHeight={nameLineHeight}
          mobileLineHeight={mobileNameLineHeight}
          color={nameColor}
          mobileColor={mobileNameColor}
          data-test-id={dataTestId}
        >
          {plan.name}
        </PricePlanNameUi>
        <PricePlanAmountUi
          fontSize={amountFontSize}
          mobileFontSize={mobileAmountFontSize}
          fontStyle={amountFontStyle}
          mobileFontStyle={mobileAmountFontStyle}
          fontWeight={amountFontWeight}
          mobileFontWeight={mobileAmountFontWeight}
          fontFamily={amountFontFamily}
          mobileFontFamily={mobileAmountFontFamily}
          lineHeight={amountLineHeight}
          mobileLineHeight={mobileAmountLineHeight}
          color={amountColor}
          mobileColor={mobileAmountColor}
        >
          <CurrencyBadgeUi color={amountColor} mobileColor={mobileAmountColor}>
            {plan.currency}
          </CurrencyBadgeUi>
          <ReadablePricePlanAmount
            plan={plan}
            amount={pricePlanPricing.netAmount}
            locale={locale}
          />
        </PricePlanAmountUi>
      </PricePlanItemUi>
      {coupon && (
        <Coupon
          amountFontSize={amountFontSize}
          mobileAmountFontSize={mobileAmountFontSize}
          amountFontStyle={amountFontStyle}
          mobileAmountFontStyle={mobileAmountFontStyle}
          amountFontWeight={amountFontWeight}
          mobileAmountFontWeight={mobileAmountFontWeight}
          amountFontFamily={amountFontFamily}
          mobileAmountFontFamily={mobileAmountFontFamily}
          amountLineHeight={amountLineHeight}
          mobileAmountLineHeight={mobileAmountLineHeight}
          amountColor={amountColor}
          mobileAmountColor={mobileAmountColor}
          descriptionFontSize={descriptionFontSize}
          mobileDescriptionFontSize={mobileDescriptionFontSize}
          descriptionFontStyle={descriptionFontStyle}
          mobileDescriptionFontStyle={mobileDescriptionFontStyle}
          descriptionFontWeight={descriptionFontWeight}
          mobileDescriptionFontWeight={mobileDescriptionFontWeight}
          descriptionFontFamily={descriptionFontFamily}
          mobileDescriptionFontFamily={mobileDescriptionFontFamily}
          descriptionLineHeight={descriptionLineHeight}
          mobileDescriptionLineHeight={mobileDescriptionLineHeight}
          descriptionColor={descriptionColor}
          mobileDescriptionColor={mobileDescriptionColor}
          coupon={coupon}
          discountAmount={pricePlanPricing.discountAmount}
          locale={locale}
        />
      )}
      {pricePlanPricing.taxAmount > 0 && (
        <Vat
          plan={plan}
          quantity={1}
          taxAmount={pricePlanPricing.taxAmount}
          taxPercent={pricePlanPricing.taxPercent}
          locale={locale}
          amountFontSize={amountFontSize}
          mobileAmountFontSize={mobileAmountFontSize}
          amountFontStyle={amountFontStyle}
          mobileAmountFontStyle={mobileAmountFontStyle}
          amountFontWeight={amountFontWeight}
          mobileAmountFontWeight={mobileAmountFontWeight}
          amountFontFamily={amountFontFamily}
          mobileAmountFontFamily={mobileAmountFontFamily}
          amountLineHeight={amountLineHeight}
          mobileAmountLineHeight={mobileAmountLineHeight}
          amountColor={amountColor}
          mobileAmountColor={mobileAmountColor}
          descriptionFontSize={descriptionFontSize}
          mobileDescriptionFontSize={mobileDescriptionFontSize}
          descriptionFontStyle={descriptionFontStyle}
          mobileDescriptionFontStyle={mobileDescriptionFontStyle}
          descriptionFontWeight={descriptionFontWeight}
          mobileDescriptionFontWeight={mobileDescriptionFontWeight}
          descriptionFontFamily={descriptionFontFamily}
          mobileDescriptionFontFamily={mobileDescriptionFontFamily}
          descriptionLineHeight={descriptionLineHeight}
          mobileDescriptionLineHeight={mobileDescriptionLineHeight}
          descriptionColor={descriptionColor}
          mobileDescriptionColor={mobileDescriptionColor}
        />
      )}
      {needTotal && (
        <Total
          coupon={coupon}
          plan={plan}
          quantity={1}
          grossAmount={pricePlanPricing.grossAmount}
          locale={locale}
          amountFontSize={amountFontSize}
          mobileAmountFontSize={mobileAmountFontSize}
          amountFontStyle={amountFontStyle}
          mobileAmountFontStyle={mobileAmountFontStyle}
          amountFontWeight={amountFontWeight}
          mobileAmountFontWeight={mobileAmountFontWeight}
          amountFontFamily={amountFontFamily}
          mobileAmountFontFamily={mobileAmountFontFamily}
          amountLineHeight={amountLineHeight}
          mobileAmountLineHeight={mobileAmountLineHeight}
          amountColor={amountColor}
          mobileAmountColor={mobileAmountColor}
          descriptionFontSize={descriptionFontSize}
          mobileDescriptionFontSize={mobileDescriptionFontSize}
          descriptionFontStyle={descriptionFontStyle}
          mobileDescriptionFontStyle={mobileDescriptionFontStyle}
          descriptionFontWeight={descriptionFontWeight}
          mobileDescriptionFontWeight={mobileDescriptionFontWeight}
          descriptionFontFamily={descriptionFontFamily}
          mobileDescriptionFontFamily={mobileDescriptionFontFamily}
          descriptionLineHeight={descriptionLineHeight}
          mobileDescriptionLineHeight={mobileDescriptionLineHeight}
          descriptionColor={descriptionColor}
          mobileDescriptionColor={mobileDescriptionColor}
        />
      )}
    </PricePlanUi>
  )
}

export default BumpPricePlan
