import React from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import { PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'
import { OldFieldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { splitMarginsAndRestStyles } from 'common/utils/styleUtils'
import { usePayment } from 'publisher/store'
import { getActivePaymentMethod } from 'publisher/store/payment/paymentSelectors'
import CompanyVatField from './CompanyVatField'
import MercadoPagoTaxNumber from './MercadoPagoTaxNumber'

type TaxNumberProps = {
  entity: OldFieldEntityInterface
}

function TaxNumber({ entity }: TaxNumberProps) {
  const activePaymentMethod = usePayment(getActivePaymentMethod)

  if (typeof window === 'undefined') {
    return (
      <StyleWrapper
        desktop={entity.options.appearance?.desktop}
        mobile={entity.options.appearance?.mobile}
        styles={splitMarginsAndRestStyles(entity.styles)[0]}
        mobileStyles={splitMarginsAndRestStyles(entity.mobileStyles)[0]}
      />
    )
  }

  switch (activePaymentMethod) {
    case PaymentMethodEnum.MercadoPagoCard:
    case PaymentMethodEnum.MercadoPagoPix:
    case PaymentMethodEnum.MercadoPagoBoleto:
    case PaymentMethodEnum.MercadoPagoBrCard:
    case PaymentMethodEnum.MercadoPagoArCard:
    case PaymentMethodEnum.MercadoPagoBrPix:
    case PaymentMethodEnum.MercadoPagoBrBoleto:
    case PaymentMethodEnum.MercadoPagoCoCard:
    case PaymentMethodEnum.MercadoPagoMxCard:
    case PaymentMethodEnum.MercadoPagoClCard:
    case PaymentMethodEnum.DlocalCard:
    case PaymentMethodEnum.DlocalPix:
    case PaymentMethodEnum.DlocalBoleto:
      return <MercadoPagoTaxNumber entity={entity} type={activePaymentMethod} />
    default:
      // @ts-ignore
      return <CompanyVatField entity={entity} />
  }
}

export default TaxNumber
