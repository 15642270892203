import styled from 'styled-components'

const InputRadioUi = styled.input.attrs(() => ({
  type: 'radio',
}))`
  width: 16px;
  height: 16px;
  margin-right: 16px;
`

export default InputRadioUi
