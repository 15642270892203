import PropTypes from 'prop-types'
import React from 'react'
import provideMobileStyles from 'common/hoc/provideMobileStyles'
import OrderBumpArrowUi from './ui/OrderBumpArrowUi'
import OrderBumpCheckBoxUi from './ui/OrderBumpCheckBoxUi'
import OrderBumpTitleUi from './ui/OrderBumpTitleUi'
import OrderBumpUi from './ui/OrderBumpUi'
import OrderBumpWrapperUi from './ui/OrderBumpWrapperUi'

function OrderBump(props) {
  const {
    bumps,
    checkedBumpsIds,
    onChangeHandler,
    styles,
    mobileStyles,
    mobileOptions,
    options,
    children,
  } = props
  const { title, showArrow, headerStyles } = options
  const bump = bumps[0] || {}

  const clickHandler = e => {
    e.preventDefault()
    if (bump.id) {
      onChangeHandler(bump.id)
    }
  }

  const handleChange = () => {
    if (bump.id) {
      onChangeHandler(bump.id)
    }
  }

  return (
    <OrderBumpWrapperUi
      styles={styles}
      mobileStyles={mobileStyles}
      attrId={options.attrId}
    >
      <OrderBumpUi
        styles={headerStyles}
        mobileStyles={mobileOptions.headerStyles}
      >
        {showArrow === '1' && <OrderBumpArrowUi onClick={clickHandler} />}
        <OrderBumpCheckBoxUi
          checked={checkedBumpsIds.includes(bump.id)}
          onChange={handleChange}
        />
        <OrderBumpTitleUi onClick={clickHandler}>{title}</OrderBumpTitleUi>
      </OrderBumpUi>
      {children}
    </OrderBumpWrapperUi>
  )
}

OrderBump.propTypes = {
  bumps: PropTypes.arrayOf(PropTypes.any).isRequired,
  styles: PropTypes.objectOf(PropTypes.any).isRequired,
  mobileStyles: PropTypes.objectOf(PropTypes.any).isRequired,
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  mobileOptions: PropTypes.objectOf(PropTypes.any).isRequired,
  onChangeHandler: PropTypes.func,
  checkedBumpsIds: PropTypes.arrayOf(PropTypes.number),
  children: PropTypes.node.isRequired,
}

OrderBump.defaultProps = {
  onChangeHandler: () => {},
  checkedBumpsIds: [],
}

export default provideMobileStyles(OrderBump)
