import { pageTypes } from 'common/constants/pageTypes'
import CommonNoIndexPage from 'publisher/pages/CommonNoIndexPage'
import CommonPage from 'publisher/pages/CommonPage'
import CustomLoginPage from 'publisher/pages/CustomLoginPage'
import InlineWrapper from 'publisher/pages/InlineForm'
import LecturePage from 'publisher/pages/LecturePage'
import PaymentPage from 'publisher/pages/PaymentPage'
import PopupForm from 'publisher/pages/PopupForm'
import UpsellPage from 'publisher/pages/UpsellPage'
import WebinarSessionPage from 'publisher/pages/WebinarSessionPage'
import WebinarThankYouPage from 'publisher/pages/WebinarThankYouPage'
import BlogPage from 'publisher/pages/blog'
import { PageTypeEnum } from '../../../common/enums/PageTypeEnum'

export default function createPage(type) {
  switch (type) {
    case pageTypes.webinarSession:
      return WebinarSessionPage
    case pageTypes.offerPage:
    case pageTypes.offerInner:
      return PaymentPage
    case pageTypes.offerDownSellPage:
    case pageTypes.offerUpSellPage:
      return UpsellPage
    case pageTypes.webinarThankYou:
      return WebinarThankYouPage
    case pageTypes.inline:
      return InlineWrapper
    case pageTypes.popup:
      return PopupForm
    case pageTypes.customLogin:
      return CustomLoginPage
    case pageTypes.blogPostBody: // need for ServerApp renderEntity, will be removed
    case pageTypes.blogStatic: // need for ServerApp renderEntity, will be removed
    case pageTypes.blogHome: // need for ServerApp renderEntity, will be removed
      return BlogPage
    case pageTypes.squeeze:
    case pageTypes.salesPage:
    case pageTypes.webinarRegistration:
    case pageTypes.infoPage:
    case pageTypes.blogPostLayout: // post layout save
    case pageTypes.blogLayout: // blog layout save
    case PageTypeEnum.FunnelContactUs:
    case pageTypes.optInThankYou:
    case pageTypes.offerThankYouPage:
    case PageTypeEnum.LinkInBio:
      return CommonPage
    case pageTypes.inactiveFunnelPage:
    case pageTypes.courseIntroduction:
      return CommonNoIndexPage
    default:
      throw new Error(`unknown page type ${type}`)
  }
}
