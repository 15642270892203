import React, { createContext, useContext, useEffect, useState } from 'react'
import paymentMethodTypes from 'common/constants/paymentMethodTypes'
import loadScript from 'publisher/utils/loadScript'
import usePayment, { selectors } from '../hooks/usePayment'
import { usePage } from '../store'
import pageSelectors from '../store/page/pageSelectors'

const baseFieldStyles = {
  base: {
    fontSize: '16px',
    fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
    lineHeight: '18px',
    fontSmoothing: 'antialiased',
    fontWeight: '500',
    color: '#666',
    '::placeholder': {
      color: '#c1c1c1',
    },
    iconColor: '#c1c1c1',
  },
  autofilled: {
    color: '#000000',
  },
}

const today = new Date()
const month = today.getMonth() + 1
const monthStr = month <= 9 ? '0' + month : month.toString()
const year = (today.getFullYear() + 2).toString().substring(2)

type FieldOptions = {
  style: {
    autofilled?: { color: string }
    base?: {
      fontFamily?: string
      color?: string
      '::placeholder'?: { color: string }
      iconColor?: string
      fontSize?: string
      lineHeight?: string
      fontSmoothing?: string
      fontWeight?: string
    }
  }
  placeholder: string
}

type FieldsOptions = {
  locale: string
  country: string
  fonts: { cssSrc: string }[]
}

type FieldType = 'card' | 'pan' | 'expiration' | 'cvv' | 'cvv-only'

interface Fields {
  create: (type: FieldType, options?: FieldOptions) => Field
}

interface Field {
  mount: (element: Element) => void
}

type DlocalTokenResponse = Promise<{ token?: string; error?: string }>

interface DLocal {
  createToken: (field: Field, tokenData?: any) => DlocalTokenResponse
  fields: (options: FieldsOptions) => Fields
}

interface DLocalWrapper {
  createToken: () => DlocalTokenResponse
  pan: Field
  expiration: Field
  cvv: Field
}

export const DlocalContext = createContext<DLocalWrapper | null>(null)

export const useDLocal = () => useContext(DlocalContext)

export function DLocalProvider(
  props: React.PropsWithChildren<Record<string, never>>,
) {
  const paymentMethods = usePayment(selectors.getPaymentMethods)
  const locale = usePage(pageSelectors.getLocale)
  const [dlocalWrapper, setDlocalWrapper] = useState<DLocalWrapper | null>(null)

  useEffect(() => {
    if (paymentMethods.includes(paymentMethodTypes.DLOCAL_CARD)) {
      loadScript(process.env.DLOCAL_API_URL as string, 'd-local', () => {
        const dLocalInstance = (window as any).dlocal(
          process.env.DLOCAL_API_KEY,
        ) as DLocal
        const fields = dLocalInstance.fields({
          fonts: [
            {
              cssSrc: 'https://rsms.me/inter/inter-ui.css',
            },
          ],
          locale: locale,
          country: 'BR',
        })
        const panField = fields.create('pan', {
          style: baseFieldStyles,
          placeholder: '4111 1111 1111 1111',
        })
        const expirationField = fields.create('expiration', {
          style: baseFieldStyles,
          placeholder: `${monthStr}/${year}`,
        })
        const cvvField = fields.create('cvv', {
          style: baseFieldStyles,
          placeholder: '123',
        })

        const dlocalWrapper: DLocalWrapper = {
          pan: panField,
          expiration: expirationField,
          cvv: cvvField,
          createToken: () =>
            dLocalInstance.createToken(panField, {
              name: 'test',
            }),
        }

        setDlocalWrapper(dlocalWrapper)
      })
    }
  }, [])

  return <DlocalContext.Provider value={dlocalWrapper} {...props} />
}
