import React from 'react'
import { CommonButtonProps } from 'common/types/entities/ButtonInterface'
import ButtonContentUi from './ui/ButtonContentUi'
import ButtonIconUi from './ui/ButtonIconUi'
import LinkUi from './ui/LinkUi'
import SubTextUiNew from './ui/SubTextUiNew'

type LinkNewProps = {
  attrId: string
  disabled?: boolean
  onClick?: () => void
  target?: '_self' | '_blank'
  href: string
  rel?: string
} & CommonButtonProps

function LinkNew({
  attrId,
  text,
  subText,
  padding,
  mobilePadding,
  alignSelf,
  mobileAlignSelf,
  width,
  mobileWidth,
  textFontSize,
  mobileTextFontSize,
  subTextFontSize,
  mobileSubTextFontSize,
  textFontFamily,
  textFontWeight,
  textFontStyle,
  subTextFontFamily,
  subTextFontWeight,
  subTextFontStyle,
  mobileTextFontFamily,
  mobileTextFontWeight,
  mobileTextFontStyle,
  mobileSubTextFontFamily,
  mobileSubTextFontWeight,
  mobileSubTextFontStyle,
  textColor,
  subTextColor,
  mobileTextColor,
  mobileSubTextColor,
  backgroundColor,
  mobileBackgroundColor,
  iconClassNameBefore,
  iconClassNameAfter,
  border,
  mobileBorder,
  boxShadow,
  mobileBoxShadow,
  lineHeight,
  mobileLineHeight,
  target,
  rel = '',
  href = '',
  hover,
  mobileHover,
}: LinkNewProps) {
  return (
    <LinkUi
      id={attrId}
      target={target}
      rel={rel}
      href={href}
      border={border}
      mobileBorder={mobileBorder}
      color={textColor}
      fontFamily={textFontFamily}
      fontWeight={textFontWeight}
      fontStyle={textFontStyle}
      fontSize={textFontSize}
      mobileFontSize={mobileTextFontSize}
      mobileColor={mobileTextColor}
      mobileFontFamily={mobileTextFontFamily}
      mobileFontWeight={mobileTextFontWeight}
      mobileFontStyle={mobileTextFontStyle}
      backgroundColor={backgroundColor}
      mobileBackgroundColor={mobileBackgroundColor}
      padding={padding}
      mobilePadding={mobilePadding}
      width={width}
      mobileWidth={mobileWidth}
      alignSelf={alignSelf}
      mobileAlignSelf={mobileAlignSelf}
      boxShadow={boxShadow}
      mobileBoxShadow={mobileBoxShadow}
      lineHeight={lineHeight}
      mobileLineHeight={mobileLineHeight}
      hover={hover}
      mobileHover={mobileHover}
    >
      <ButtonContentUi>
        {iconClassNameBefore && (
          <ButtonIconUi className={iconClassNameBefore} placed={'left'} />
        )}
        {text}
        {iconClassNameAfter && (
          <ButtonIconUi className={iconClassNameAfter} placed={'right'} />
        )}
      </ButtonContentUi>
      {subText && (
        <SubTextUiNew
          color={subTextColor}
          fontFamily={subTextFontFamily}
          fontWeight={subTextFontWeight}
          fontStyle={subTextFontStyle}
          fontSize={subTextFontSize}
          mobileFontSize={mobileSubTextFontSize}
          mobileColor={mobileSubTextColor}
          mobileFontFamily={mobileSubTextFontFamily}
          mobileFontWeight={mobileSubTextFontWeight}
          mobileFontStyle={mobileSubTextFontStyle}
        >
          {subText}
        </SubTextUiNew>
      )}
    </LinkUi>
  )
}

export default LinkNew
