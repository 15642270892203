import styled, { css } from 'styled-components'
import { colorCss, mobileColorCss } from 'common/utils/styleUtilsNew'
import { changeStringColorOpacity } from 'common/utils/colorUtils'

type CurrencyBadgeUiProps = {
  color?: string
  mobileColor?: string
}

const CurrencyBadgeUi = styled.div<CurrencyBadgeUiProps>`
  padding: 3px 6px;
  border-radius: 5px;
  text-transform: uppercase;
  color: #142d63;
  background-color: #00a0ff33;
  margin-right: 10px;
  line-height: 10px;
  font-size: 10px;
  font-weight: bold;

  ${({ color }) =>
    color &&
    css`
      ${colorCss}
      background-color: ${changeStringColorOpacity(color, 0.2)};
    `}

  ${({ theme, mobileColor }) =>
    theme.isEditorMobileMode &&
    mobileColor &&
    css`
      ${mobileColorCss}
      background-color: ${changeStringColorOpacity(mobileColor, 0.2)};
    `}
`

export default CurrencyBadgeUi
