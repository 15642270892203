import React from 'react'
import { useTranslation } from 'react-i18next'
import ProductStockLimitContainerUi from './ui/ProductStockLimitContainerUi'
import ProductStockLimitInnerContainerUi from './ui/ProductStockLimitInnerContainerUi'

interface ProductStockLimitProps {
  stockLimit: number
  showStockLimit?: boolean
}

function ProductStockLimit({
  stockLimit,
  showStockLimit,
}: ProductStockLimitProps) {
  const { t } = useTranslation('common')
  return (
    <>
      {showStockLimit && (
        <ProductStockLimitContainerUi>
          {t('entity.product.stock_limit_label')}:
          <ProductStockLimitInnerContainerUi>
            {t('entity.product.stock_limit_title', { limit: stockLimit })}
          </ProductStockLimitInnerContainerUi>
        </ProductStockLimitContainerUi>
      )}
    </>
  )
}

export default ProductStockLimit
