import axios from 'axios'

export function buyMainOffer(pageId: number, body: any) {
  return axios.post(
    `/api/payment/cash-on-delivery/buy-main-offer/${pageId}`,
    body,
  )
}

export function buyUpsellOffer(
  pageId: number,
  purchaseProcessId: number,
  body: any,
) {
  return axios.post(
    `/api/payment/cash-on-delivery/buy-upsell/${pageId}/${purchaseProcessId}`,
    body,
  )
}
