import styled from 'styled-components'

type SkeletonOrderItemUiProps = {
  width: number
}

const SkeletonOrderItemUi = styled.span<SkeletonOrderItemUiProps>`
  animation: 1.2s ease-in-out 0s infinite normal none running;
  width: ${({ width }) => `${width}px` || '100%'};
  height: 20px;
  background-color: rgb(238, 238, 238);
  background-image: linear-gradient(
    90deg,
    rgb(238, 238, 238),
    rgb(245, 245, 245),
    rgb(238, 238, 238)
  );
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
`

export default SkeletonOrderItemUi
