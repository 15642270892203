import styled from 'styled-components'

const Divider = styled.hr`
  margin: 10px 0;
  height: 1px;
  border: none;
  background-color: rgb(224, 224, 224);
`

export default Divider
