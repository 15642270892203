import axios from 'axios'
import { PageId } from '../store/page/PageStateInterface'
import { PurchaseProcessId } from '../store/payment/PaymentStateInterface'

export function buyMainOffer(
  pageId: PageId,
  purchaseProcessId: PurchaseProcessId,
  body: any,
) {
  return axios.post(
    `/secure/dlocal/${pageId}/buy-main-offer/${purchaseProcessId}`,
    body,
  )
}

export function buyUpsellOffer(
  pageId: number,
  purchaseProcessId: number,
  body: any,
) {
  return axios.post(
    `/secure/dlocal/${pageId}/buy-upsell/${purchaseProcessId}`,
    body,
  )
}
