import { MercadoPagoFieldErrorInterface } from '../types/MercadoPagoInterface'

// reference:
// https://www.mercadopago.com.ar/developers/en/docs/checkout-api/response-handling/data-insertion-errors
export function getFieldError(
  errorCode: string,
): MercadoPagoFieldErrorInterface | undefined {
  switch (errorCode) {
    case '205':
    case 'E301':
      return { field: 'cardNumber', message: 'validation.card_number_invalid' }

    case '208':
    case '209':
    case '325':
    case '326':
    case 'E205':
      return {
        field: 'cardExpiration',
        message: 'validation.expiration_invalid',
      }

    case '221':
    case '316':
      return {
        field: 'cardholderName',
        message: 'validation.cardholder_name_invalid',
      }

    case '212':
    case '213':
    case '214':
    case '322':
    case '323':
    case '324':
      return {
        field: 'identificationNumber',
        message: 'validation.identification_number_invalid',
      }

    case '224':
      return {
        field: 'securityCode',
        message: 'validation.cvc_invalid',
      }
  }
}
