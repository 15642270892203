import React, { RefObject, useEffect, useRef } from 'react'
import styled from 'styled-components'

type CarouselProps = {
  children: React.ReactElement[] | React.ReactElement // todo should be ReactElement
  totalItems: number
  switchActiveSlideNumber?: (index: number) => void
  disableOverflow?: boolean
  activeSlideIndex: number
  carouselRef?: React.RefObject<HTMLDivElement>
}

const CarouselUi = styled.div`
  max-width: 315px;
  min-width: 159px;
  position: relative;
`
const OverlayCarouselUi = styled.div`
  position: relative;
`

const CarouselArrowRightUi = styled.i`
  display: block;
  color: #9ba9b3;
  position: absolute;
  right: 10px;
  top: 50%;
  padding: 10px;
  font-size: 20px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
`
const CarouselArrowLeftUi = styled.i`
  display: block;
  color: #9ba9b3;
  position: absolute;
  left: 10px;
  top: 50%;
  padding: 10px;
  font-size: 20px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
`

const CarouselSliderUi = styled.div<{ disableOverflow: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 2px;
  overflow-x: ${p =>
    p.disableOverflow
      ? 'visible'
      : 'scroll'}; // in the editor we need visible to show entity actions
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    width: 0;
  }
`
const CarouselSlideUi = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  top: 0;
  min-width: 100%;
  margin: auto;
  scroll-snap-align: start;
  flex-wrap: wrap;
  justify-content: center;
  //to prevent trigger isIntersecting in IntersectionObserver with blank slides
  min-height: 1px;
`

const OverLayCarouselSlideUi = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-width: 100%;
  width: 100vw;
  height: 100vh;
  flex-wrap: wrap;
  justify-content: center;
  //to prevent trigger isIntersecting in IntersectionObserver with blank slides
  min-height: 1px;
  scroll-snap-align: start;
`

function ProductImagesCarousel({
  children,
  totalItems,
  switchActiveSlideNumber,
  disableOverflow = false,
  activeSlideIndex,
  carouselRef,
}: CarouselProps) {
  function handleNavigationClick(index: number) {
    return function (event: React.SyntheticEvent) {
      event.preventDefault()
      event.stopPropagation()
      if (switchActiveSlideNumber) {
        switchActiveSlideNumber(index)
      }
    }
  }

  const nextSlide = activeSlideIndex + 1
  const prevSlide = activeSlideIndex - 1

  return (
    <CarouselUi className="carousel-ui" ref={carouselRef}>
      {prevSlide >= 0 && (
        <CarouselArrowLeftUi
          className="fas fa-chevron-left"
          onClick={handleNavigationClick(prevSlide)}
        />
      )}
      {nextSlide < totalItems && (
        <CarouselArrowRightUi
          className="fas fa-chevron-right"
          onClick={handleNavigationClick(nextSlide)}
        />
      )}
      <CarouselSliderUi
        className="carousel-slider-ui"
        disableOverflow={disableOverflow}
      >
        {children}
      </CarouselSliderUi>
    </CarouselUi>
  )
}

export function OverlayProductImagesCarousel({
  children,
  totalItems,
  switchActiveSlideNumber,
  disableOverflow = false,
  activeSlideIndex,
  carouselRef,
}: CarouselProps) {
  function handleNavigationClick(index: number) {
    return function (event: React.SyntheticEvent) {
      event.preventDefault()
      event.stopPropagation()
      if (switchActiveSlideNumber) {
        switchActiveSlideNumber(index)
      }
    }
  }

  const nextSlide = activeSlideIndex + 1
  const prevSlide = activeSlideIndex - 1

  return (
    <OverlayCarouselUi ref={carouselRef}>
      {prevSlide >= 0 && (
        <CarouselArrowLeftUi
          className="fas fa-chevron-left"
          onClick={handleNavigationClick(prevSlide)}
        />
      )}
      {nextSlide < totalItems && (
        <CarouselArrowRightUi
          className="fas fa-chevron-right"
          onClick={handleNavigationClick(nextSlide)}
        />
      )}
      <CarouselSliderUi
        className="carousel-slider-ui"
        disableOverflow={disableOverflow}
      >
        {children}
      </CarouselSliderUi>
    </OverlayCarouselUi>
  )
}

type CarouselSlideProps = {
  active: boolean
  children: React.ReactElement
  setActive: () => void
  needScroll: boolean
  carouselRef: RefObject<HTMLDivElement>
  needToSetInitialSlide?: boolean
}

export function CarouselSlide({
  active,
  needScroll,
  children,
  setActive,
  carouselRef,
}: CarouselSlideProps) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current && active && needScroll) {
      ref.current.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
      })
    }
  }, [active])

  useEffect(() => {
    if (ref.current) {
      const observerParams = { threshold: 0.5, root: carouselRef.current }
      const observer = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => {
          const [entry] = entries
          if (entry.isIntersecting) {
            setActive()
          }
        },
        observerParams,
      )

      observer.observe(ref.current)
      return () => observer.disconnect()
    }
  }, [])

  return <CarouselSlideUi ref={ref}>{children}</CarouselSlideUi>
}

export function OverlayCarouselSlide({
  active,
  needScroll,
  children,
  setActive,
  carouselRef,
  needToSetInitialSlide,
}: CarouselSlideProps) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current && active && needScroll) {
      ref.current.scrollIntoView({
        block: 'end',
        behavior: 'smooth',
        inline: 'center',
      })
    }
  }, [active])

  useEffect(() => {
    if (ref.current && !needToSetInitialSlide) {
      const observerParams = { threshold: 0.5, root: carouselRef.current }
      const observer = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => {
          const [entry] = entries
          if (entry.isIntersecting) {
            setActive()
          }
        },
        observerParams,
      )

      observer.observe(ref.current)
      return () => observer.disconnect()
    }
  }, [needToSetInitialSlide])

  return <OverLayCarouselSlideUi ref={ref}>{children}</OverLayCarouselSlideUi>
}

export default ProductImagesCarousel
