import { CalculatePriceData } from 'common/types/shippingType'
import { transformPricingDto } from 'common/utils/productUtils'
import { calculatePrice } from 'publisher/api/shippingApi'

export const fetchOfferPricing = async (
  offers: CalculatePriceData[],
  abortSignal?: AbortSignal,
) => {
  const { data } = await calculatePrice(offers, abortSignal)

  const offerPricings = data.map(offerPricing =>
    transformPricingDto(offerPricing),
  )

  return offerPricings
}
