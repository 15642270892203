import styled, { css } from 'styled-components'
import { colorCss, mobileColorCss } from 'common/utils/styleUtilsNew'

interface FieldPasswordButtonUiProps {
  color?: string
  mobileColor?: string
}

const FieldPasswordButtonUi = styled.button<FieldPasswordButtonUiProps>`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  ${colorCss};
  ${p => p.theme.phone} {
    ${mobileColorCss};
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileColorCss};
    `}
`

export default FieldPasswordButtonUi
