import styled from 'styled-components'

const ProductPriceContainerUi = styled.div`
  align-items: center;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  gap: 5px;
  letter-spacing: -0.1px;
  color: #142d63;
`

export default ProductPriceContainerUi
