import React from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import { wrapperStyles } from 'common/components/entities/Button'
import CommonButton from 'common/components/entities/Button/Button'
import { SalesRedirectionButtonInterface } from 'common/types/entities/ButtonInterface'
import * as fetchUtils from 'common/utils/fetchHelper'
import { useOptIn } from 'publisher/store'
import { usePayment as useTypedPayment } from 'publisher/store'
import { getNextStepUrl } from 'publisher/store/optIn/optInSelectors'
import typedPaymentSelectors from 'publisher/store/payment/paymentSelectors'

export type SalesRedirectionButtonPropsType = {
  entity: SalesRedirectionButtonInterface
}

function SalesRedirectionButton({ entity }: SalesRedirectionButtonPropsType) {
  const nextStepUrl = useOptIn(optIn => getNextStepUrl(optIn))
  const productQuantity = useTypedPayment(
    typedPaymentSelectors.getProductQuantity,
  )
  const productActiveVariant = useTypedPayment(
    typedPaymentSelectors.getProductActiveVariant,
  )

  const getQueryString = () => {
    const params: Record<string, string | number | undefined> = {}
    params.productQuantity = productQuantity
    params.activeVariantId = productActiveVariant?.id

    const queryString = fetchUtils.getQueryString(params)

    return queryString ? `${nextStepUrl}?${queryString}` : nextStepUrl
  }

  const redirectNextStep = () => {
    window.location.assign(getQueryString())
  }

  return (
    <StyleWrapper
      styles={{ ...entity.margin, ...wrapperStyles }}
      mobileStyles={entity.mobileMargin}
      desktop={entity.appearance.desktop}
      mobile={entity.appearance.mobile}
    >
      <CommonButton
        onClick={redirectNextStep}
        text={entity.text}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        subText={entity.subText}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        alignSelf={entity.alignSelf}
        backgroundColor={entity.backgroundColor}
        mobileBackgroundColor={entity.mobileBackgroundColor}
        attrId={entity.htmlAttrId}
        width={entity.width}
        mobileWidth={entity.mobileWidth}
        textColor={entity.textColor}
        subTextColor={entity.subTextColor}
        mobileTextColor={entity.mobileTextColor}
        mobileSubTextColor={entity.mobileSubTextColor}
        textFontSize={entity.textFontSize}
        mobileTextFontSize={entity.mobileTextFontSize}
        subTextFontSize={entity.subTextFontSize}
        mobileSubTextFontSize={entity.mobileSubTextFontSize}
        textFontFamily={entity.textFontFamily}
        textFontWeight={entity.textFontWeight}
        textFontStyle={entity.textFontStyle}
        subTextFontFamily={entity.subTextFontFamily}
        subTextFontWeight={entity.subTextFontWeight}
        subTextFontStyle={entity.subTextFontStyle}
        mobileTextFontFamily={entity.mobileTextFontFamily}
        mobileTextFontWeight={entity.mobileTextFontWeight}
        mobileTextFontStyle={entity.mobileTextFontStyle}
        mobileSubTextFontFamily={entity.mobileSubTextFontFamily}
        mobileSubTextFontWeight={entity.mobileSubTextFontWeight}
        mobileSubTextFontStyle={entity.mobileSubTextFontStyle}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
        lineHeight={entity.lineHeight}
        mobileLineHeight={entity.mobileLineHeight}
        iconClassNameBefore={entity.iconClassNameBefore}
        iconClassNameAfter={entity.iconClassNameAfter}
        hover={entity.hover}
        mobileHover={entity.mobileHover}
      />
    </StyleWrapper>
  )
}

export default SalesRedirectionButton
