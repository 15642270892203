export function getCleanIdentificationNumberValue(value?: string) {
  if (!value) return ''

  return value.replace(/\W+/g, '')
}

export const getCleanRUTValue = (value?: string) => {
  if (!value) return ''

  // replace any non-numeric character except for letter 'K'
  return value.toUpperCase().replace(/[^\dK]/, '')
}
