import styled, { css } from 'styled-components'

const defaultMobileCss = css`
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px;
  margin-right: 0;
`

const CouponFieldUi = styled.input`
  max-width: 300px;
  margin-right: 5px;
  padding: 5px;
  outline: none;
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    text-transform: capitalize;
    font-style: italic;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    text-transform: capitalize;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    text-transform: capitalize;
  }
  ${p => p.theme.phone} {
    ${defaultMobileCss};
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${({ styles = {} }) => styles}
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${p.mobileStyles};
      ${defaultMobileCss};
    `}
`

export default CouponFieldUi
