import styled from 'styled-components'

const PricePlanItemUi = styled.div<{
  skipLeftOffset?: boolean // we need it when there is no radio button
  needBottomOffset?: boolean // we need it when there we need to add some space between items, like description and vat
}>`
  display: flex;
  align-items: center;
  margin-left: ${p => (p.skipLeftOffset ? 0 : '23px')};
  margin-bottom: ${p => (p.needBottomOffset ? '5px' : 0)};
  color: #000000;
  gap: 5px;
`

export default PricePlanItemUi
