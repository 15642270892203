import styled, { css } from 'styled-components'
import { fontCss, mobileFontCss } from '../../../../utils/styleUtilsNew'

const PaymentMethodLabelUi = styled.span`
  margin-right: 0.25rem;
  ${fontCss};
  line-height: ${p => p.lineHeight};
  ${p => p.theme.phone} {
    ${mobileFontCss};
    line-height: ${p => p.mobileLineHeight || p.lineHeight};
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileFontCss};
      line-height: ${p => p.mobileLineHeight || p.lineHeight};
    `}
`

export default PaymentMethodLabelUi
