import styled from 'styled-components'

const ProductQuantityIconContainerUi = styled.div`
  cursor: pointer;
  background-color: #e1e3e5;
  border-radius: 15px;
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #667f97;
`

export default ProductQuantityIconContainerUi
