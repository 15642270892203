import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonCoupon from 'common/components/entities/Coupon'
import couponTypes from 'common/components/entities/Coupon/couponTypes'
import { PaymentDetails as LimitedSubscriptionPaymentDetails } from 'common/components/entities/OfferPrice/LimitedSubscription'
import { PaymentDetails as UnlimitedSubscriptionPaymentDetails } from 'common/components/entities/OfferPrice/UnlimitedSubscription'
import { borderTypeValues } from 'common/constants/settings'
import * as types from 'common/types'
import centsToEuro from 'common/utils/centsToEuro'
import { getReadablePrice } from 'common/utils/priceCalculator'
import { checkCoupon, updateCoupon } from 'publisher/actions/paymentActions'
import {
  getCheckedCoupon,
  getCouponCode,
  getCouponError,
  getCheckedPricePlan,
  getPricePlans,
  getProduct,
} from 'publisher/reducers/paymentReducer'
import { getLocale } from '../../reducers/pageReducer'

class Coupon extends PureComponent {
  state = {
    checking: false,
  }

  check = () => {
    this.setState({ checking: true })
    this.props.checkCoupon().then(() => this.setState({ checking: false }))
  }

  update = code => {
    this.props.updateCoupon(code)
  }

  render() {
    const {
      couponCode,
      entity,
      checkedCoupon,
      couponError,
      checkedPricePlan,
      locale,
      pricePlans,
      product,
    } = this.props
    const {
      options: { appearance, buttonText, placeholder },
    } = entity
    if (pricePlans.length === 0 && !product) {
      return (
        <StyleWrapper desktop={appearance.desktop} mobile={appearance.mobile} />
      )
    }
    let discount
    if (pricePlans.length > 0) {
      discount = checkedCoupon
        ? checkedCoupon.discountType === couponTypes.fixed
          ? getReadablePrice(
              centsToEuro(checkedCoupon.discountAmount),
              pricePlans[0].currency,
              locale,
            )
          : `${checkedCoupon.discountAmount}%`
        : ''
    }

    if (product) {
      discount = checkedCoupon
        ? checkedCoupon.discountType === couponTypes.fixed
          ? getReadablePrice(
              centsToEuro(checkedCoupon.discountAmount),
              product.currency,
              locale,
            )
          : `${checkedCoupon.discountAmount}%`
        : ''
    }

    let discountDetails = null

    if (discount) {
      if (checkedPricePlan && checkedPricePlan.subscriptionPlan) {
        if (checkedPricePlan.isLimited) {
          discountDetails = (
            <LimitedSubscriptionPaymentDetails
              amount={discount}
              intervalCount={checkedPricePlan.subscriptionPlan.intervalCount}
              interval={checkedPricePlan.subscriptionPlan.interval}
              limitOfPayments={
                checkedPricePlan.subscriptionPlan.limitOfPayments
              }
            />
          )
        } else {
          discountDetails = (
            <UnlimitedSubscriptionPaymentDetails
              amount={discount}
              intervalCount={checkedPricePlan.subscriptionPlan.intervalCount}
              interval={checkedPricePlan.subscriptionPlan.interval}
            />
          )
        }
      } else {
        discountDetails = discount
      }
    }

    let fieldStyles = entity.options.fieldStyles
    if (entity.options.fieldOptions.borderType) {
      fieldStyles = {
        ...fieldStyles,
        ...borderTypeValues[entity.options.fieldOptions.borderType],
      }
    }
    let mobileFieldStyles = entity.mobileOptions.fieldStyles
    if (entity.mobileOptions.fieldOptions.borderType) {
      mobileFieldStyles = {
        ...mobileFieldStyles,
        ...borderTypeValues[entity.mobileOptions.fieldOptions.borderType],
      }
    }
    let buttonStyles = entity.options.buttonStyles
    if (entity.options.buttonOptions.borderType) {
      buttonStyles = {
        ...buttonStyles,
        ...borderTypeValues[entity.options.buttonOptions.borderType],
      }
    }
    let mobileButtonStyles = entity.mobileOptions.buttonStyles
    if (entity.mobileOptions.buttonOptions.borderType) {
      mobileButtonStyles = {
        ...mobileButtonStyles,
        ...borderTypeValues[entity.mobileOptions.buttonOptions.borderType],
      }
    }

    return (
      <StyleWrapper desktop={appearance.desktop} mobile={appearance.mobile}>
        <CommonCoupon
          htmlAttrId={entity.options.attrId}
          check={this.check}
          update={this.update}
          styles={entity.styles}
          fieldStyles={fieldStyles}
          mobileFieldStyles={mobileFieldStyles}
          placeholder={placeholder}
          buttonStyles={buttonStyles}
          mobileButtonStyles={mobileButtonStyles}
          mobileStyles={entity.mobileStyles}
          couponCode={couponCode}
          buttonText={buttonText}
          couponError={couponError}
          discount={discountDetails}
          checking={this.state.checking}
        />
      </StyleWrapper>
    )
  }
}

Coupon.propTypes = {
  entity: types.entity.isRequired,
  couponCode: PropTypes.string,
  checkedCoupon: PropTypes.shape({
    id: PropTypes.number.isRequired,
    discountAmount: PropTypes.string.isRequired,
    discountType: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
  }),
  checkedPricePlan: PropTypes.objectOf(PropTypes.any),
  checkCoupon: PropTypes.func.isRequired,
  updateCoupon: PropTypes.func.isRequired,
  couponError: PropTypes.string,
  locale: PropTypes.string.isRequired,
  pricePlans: PropTypes.arrayOf(types.offerPricePlan).isRequired,
  product: PropTypes.objectOf(PropTypes.any),
}

Coupon.defaultProps = {
  checkedCoupon: null,
  couponCode: '',
  couponError: '',
  checkedPricePlan: null,
}

const mapStateToProps = ({ payment, page }) => ({
  couponCode: getCouponCode(payment),
  checkedCoupon: getCheckedCoupon(payment),
  couponError: getCouponError(payment),
  checkedPricePlan: getCheckedPricePlan(payment),
  locale: getLocale(page),
  pricePlans: getPricePlans(payment),
  product: getProduct(payment),
})

const mapDispatchToProps = { checkCoupon, updateCoupon }

export default connect(mapStateToProps, mapDispatchToProps)(Coupon)
