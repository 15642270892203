import React from 'react'
import { Helmet } from 'react-helmet'
import * as TwoStepPaymentForm from 'common/components/entities/TwoStepPaymentForm'
import useStripeCardPaymentHandler from 'publisher/hooks/payment/useStripeCardPaymentHandler'
import useManagement, {
  selectors as managementSelectors,
} from '../../../hooks/useManagement'
import FieldErrors from '../../FieldErrors'
import { TwoStepPaymentButtonProps } from './TwoStepPaymentButtonProps'

function TwoStepStripeCardPaymentButton({
  entity,
  styles,
  mobileStyles,
}: TwoStepPaymentButtonProps) {
  const { errors, isLoading, isReady, handleSubmit } =
    useStripeCardPaymentHandler(entity)
  const isPreview = useManagement(managementSelectors.isPreviewMode)

  return (
    <React.Fragment>
      <Helmet
        link={[
          {
            rel: 'stylesheet',
            href: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/css/stripe.css?ver=1.2`,
          },
        ]}
      />
      <TwoStepPaymentForm.Button
        onClick={handleSubmit}
        styles={styles}
        mobileStyles={mobileStyles}
        text={entity.options.buttonText}
        subText={entity.options.buttonSubText}
        disabled={isLoading || isPreview || !isReady}
      />
      <FieldErrors errors={errors} />
    </React.Fragment>
  )
}

export default TwoStepStripeCardPaymentButton
