const orderSummaryPlaceholder = '%ORDER_SUMMARY%'

const defaultState = {
  orderSummary: orderSummaryPlaceholder,
}

export default function (state = defaultState) {
  return state
}

export const getPricePlans = ({ orderSummary }) => {
  try {
    return JSON.parse(orderSummary)
  } catch (e) {
    return []
  }
}
