import styled from 'styled-components'

const ProductDescriptionContainerUi = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #8196ab;
`

export default ProductDescriptionContainerUi
