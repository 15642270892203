import styled from 'styled-components'

const OrderBumpWrapperUI = styled.div.attrs(p => ({
  id: p.attrId,
}))`
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${({ styles = {} }) => styles}
`

export default OrderBumpWrapperUI
