import React from 'react'
import { useTranslation } from 'react-i18next'
import CurrencyBadgeUi from 'common/components/entities/OfferPriceNew/ui/CurrencyBadgeUi'
import { ProductInterface } from 'common/types/entities/ProductInterface'
import { OfferPricing } from 'common/types/pricingTypes'
import { centsToEuro, getReadablePrice } from 'common/utils/priceCalculator'
import { getLocale } from 'publisher/reducers/pageReducer'
import { usePage } from 'publisher/store'
import DividerUi from '../ui/DividerUi'
import PricePlanAmountUi from '../ui/PricePlanAmountUi'
import PricePlanItemUi from '../ui/PricePlanItemUi'
import PricePlanNameUi from '../ui/PricePlanNameUi'
import PricePlanUi from '../ui/PricePlanUi'

interface ProductBumpProps {
  productBump: ProductInterface
  bumpOfferPricing?: OfferPricing
  nameFontFamily?: string
  mobileNameFontFamily?: string
  nameFontSize: number
  mobileNameFontSize?: number
  nameFontStyle?: string
  mobileNameFontStyle?: string
  nameFontWeight?: string
  mobileNameFontWeight?: string
  nameLineHeight: number
  mobileNameLineHeight?: number
  nameColor: string
  mobileNameColor?: string
  amountFontFamily?: string
  mobileAmountFontFamily?: string
  amountFontSize: number
  mobileAmountFontSize?: number
  amountFontStyle?: string
  mobileAmountFontStyle?: string
  amountFontWeight?: string
  mobileAmountFontWeight?: string
  amountLineHeight: number
  mobileAmountLineHeight?: number
  amountColor: string
  mobileAmountColor?: string
  skipLeftOffset: boolean
}

const ProductBump = ({
  bumpOfferPricing,
  productBump,
  nameFontSize,
  mobileNameFontSize,
  nameFontStyle,
  mobileNameFontStyle,
  nameFontWeight,
  mobileNameFontWeight,
  nameFontFamily,
  mobileNameFontFamily,
  nameLineHeight,
  mobileNameLineHeight,
  nameColor,
  mobileNameColor,
  amountFontSize,
  mobileAmountFontSize,
  amountFontStyle,
  mobileAmountFontStyle,
  amountFontWeight,
  mobileAmountFontWeight,
  amountFontFamily,
  mobileAmountFontFamily,
  amountLineHeight,
  mobileAmountLineHeight,
  amountColor,
  mobileAmountColor,
  skipLeftOffset,
}: ProductBumpProps) => {
  const { t } = useTranslation(['common', 'publisher'])
  const locale = usePage(getLocale)
  const needTotal =
    !!bumpOfferPricing?.netAmount && !!bumpOfferPricing.taxAmount

  return (
    <>
      <DividerUi />
      <PricePlanUi>
        <PricePlanItemUi skipLeftOffset={skipLeftOffset}>
          <PricePlanNameUi
            fontSize={nameFontSize}
            mobileFontSize={mobileNameFontSize}
            fontStyle={nameFontStyle}
            mobileFontStyle={mobileNameFontStyle}
            fontWeight={nameFontWeight}
            mobileFontWeight={mobileNameFontWeight}
            fontFamily={nameFontFamily}
            mobileFontFamily={mobileNameFontFamily}
            lineHeight={nameLineHeight}
            mobileLineHeight={mobileNameLineHeight}
            color={nameColor}
            mobileColor={mobileNameColor}
            data-test-id={`offer-price-product-bump-${productBump.name}`}
          >
            {productBump.name}
          </PricePlanNameUi>
          <PricePlanAmountUi
            fontSize={amountFontSize}
            mobileFontSize={mobileAmountFontSize}
            fontStyle={amountFontStyle}
            mobileFontStyle={mobileAmountFontStyle}
            fontWeight={amountFontWeight}
            mobileFontWeight={mobileAmountFontWeight}
            fontFamily={amountFontFamily}
            mobileFontFamily={mobileAmountFontFamily}
            lineHeight={amountLineHeight}
            mobileLineHeight={mobileAmountLineHeight}
            color={amountColor}
            mobileColor={mobileAmountColor}
          >
            <CurrencyBadgeUi
              color={amountColor}
              mobileColor={mobileAmountColor}
            >
              {productBump.currency}
            </CurrencyBadgeUi>
            {getReadablePrice(
              centsToEuro(bumpOfferPricing?.netAmount ?? 0),
              productBump.currency,
              locale,
            )}
          </PricePlanAmountUi>
        </PricePlanItemUi>
        {needTotal && (
          <>
            <PricePlanItemUi skipLeftOffset={skipLeftOffset}>
              <PricePlanNameUi
                fontSize={nameFontSize}
                mobileFontSize={mobileNameFontSize}
                fontStyle={nameFontStyle}
                mobileFontStyle={mobileNameFontStyle}
                fontWeight={nameFontWeight}
                mobileFontWeight={mobileNameFontWeight}
                fontFamily={nameFontFamily}
                mobileFontFamily={mobileNameFontFamily}
                lineHeight={nameLineHeight}
                mobileLineHeight={mobileNameLineHeight}
                color={nameColor}
                mobileColor={mobileNameColor}
              >
                {`${t('components.offer_price.vat')} (${
                  bumpOfferPricing.taxPercent ?? 0
                }%)`}
              </PricePlanNameUi>
              <PricePlanAmountUi
                fontSize={amountFontSize}
                mobileFontSize={mobileAmountFontSize}
                fontStyle={amountFontStyle}
                mobileFontStyle={mobileAmountFontStyle}
                fontWeight={amountFontWeight}
                mobileFontWeight={mobileAmountFontWeight}
                fontFamily={amountFontFamily}
                mobileFontFamily={mobileAmountFontFamily}
                lineHeight={amountLineHeight}
                mobileLineHeight={mobileAmountLineHeight}
                color={amountColor}
                mobileColor={mobileAmountColor}
              >
                <CurrencyBadgeUi
                  color={amountColor}
                  mobileColor={mobileAmountColor}
                >
                  {productBump.currency}
                </CurrencyBadgeUi>
                {getReadablePrice(
                  centsToEuro(bumpOfferPricing.taxAmount),
                  productBump.currency,
                  locale,
                )}
              </PricePlanAmountUi>
            </PricePlanItemUi>
            <PricePlanItemUi skipLeftOffset={skipLeftOffset}>
              <PricePlanNameUi
                fontSize={nameFontSize}
                mobileFontSize={mobileNameFontSize}
                fontStyle={nameFontStyle}
                mobileFontStyle={mobileNameFontStyle}
                fontWeight={nameFontWeight}
                mobileFontWeight={mobileNameFontWeight}
                fontFamily={nameFontFamily}
                mobileFontFamily={mobileNameFontFamily}
                lineHeight={nameLineHeight}
                mobileLineHeight={mobileNameLineHeight}
                color={nameColor}
                mobileColor={mobileNameColor}
              >
                {t('components.offer_price.subtotal')}
              </PricePlanNameUi>
              <PricePlanAmountUi
                fontSize={amountFontSize}
                mobileFontSize={mobileAmountFontSize}
                fontStyle={amountFontStyle}
                mobileFontStyle={mobileAmountFontStyle}
                fontWeight={amountFontWeight}
                mobileFontWeight={mobileAmountFontWeight}
                fontFamily={amountFontFamily}
                mobileFontFamily={mobileAmountFontFamily}
                lineHeight={amountLineHeight}
                mobileLineHeight={mobileAmountLineHeight}
                color={amountColor}
                mobileColor={mobileAmountColor}
              >
                <CurrencyBadgeUi
                  color={amountColor}
                  mobileColor={mobileAmountColor}
                >
                  {productBump.currency}
                </CurrencyBadgeUi>
                {getReadablePrice(
                  centsToEuro(bumpOfferPricing.grossAmount),
                  productBump.currency,
                  locale,
                )}
              </PricePlanAmountUi>
            </PricePlanItemUi>
          </>
        )}
      </PricePlanUi>
    </>
  )
}
export default ProductBump
