import React from 'react'
import { useTranslation } from 'react-i18next'
import CurrencyBadgeUi from 'common/components/entities/OfferPriceNew/ui/CurrencyBadgeUi'
import DividerUi from 'common/components/entities/OfferPriceNew/ui/DividerUi'
import PricePlanAmountUi from 'common/components/entities/OfferPriceNew/ui/PricePlanAmountUi'
import PricePlanDescriptionUi from 'common/components/entities/OfferPriceNew/ui/PricePlanDescriptionUi'
import PricePlanItemWithoutLeftOffsetUi from 'common/components/entities/OfferPriceNew/ui/PricePlanItemWithoutLeftOffsetUi'
import { OneShotPlan } from 'common/types/OfferInterface'
import { ProductInterface } from 'common/types/entities/ProductInterface'
import { OfferPricing } from 'common/types/pricingTypes'
import { centsToEuro, getReadablePrice } from 'common/utils/priceCalculator'

interface ProductAndPricePlanBumpTotalProps {
  product?: ProductInterface
  bumpPlan: OneShotPlan
  productPricing?: OfferPricing
  bumpPlanPricing: OfferPricing
  amountFontFamily?: string
  locale: string
  mobileAmountFontFamily?: string
  amountFontSize: number
  mobileAmountFontSize?: number
  amountFontStyle?: string
  mobileAmountFontStyle?: string
  amountFontWeight?: string
  mobileAmountFontWeight?: string
  amountLineHeight: number
  mobileAmountLineHeight?: number
  amountColor: string
  mobileAmountColor?: string
  descriptionFontFamily?: string
  mobileDescriptionFontFamily?: string
  descriptionFontSize: number
  mobileDescriptionFontSize?: number
  descriptionFontStyle?: string
  mobileDescriptionFontStyle?: string
  descriptionFontWeight?: string
  mobileDescriptionFontWeight?: string
  descriptionLineHeight: number
  mobileDescriptionLineHeight?: number
  descriptionColor: string
  mobileDescriptionColor?: string
}

function ProductAndPricePlanBumpTotal({
  product,
  productPricing,
  bumpPlanPricing,
  locale,
  amountFontSize,
  mobileAmountFontSize,
  amountFontStyle,
  mobileAmountFontStyle,
  amountFontWeight,
  mobileAmountFontWeight,
  amountFontFamily,
  mobileAmountFontFamily,
  amountLineHeight,
  mobileAmountLineHeight,
  amountColor,
  mobileAmountColor,
  descriptionFontSize,
  mobileDescriptionFontSize,
  descriptionFontStyle,
  mobileDescriptionFontStyle,
  descriptionFontWeight,
  mobileDescriptionFontWeight,
  descriptionFontFamily,
  mobileDescriptionFontFamily,
  descriptionLineHeight,
  mobileDescriptionLineHeight,
  descriptionColor,
  mobileDescriptionColor,
}: ProductAndPricePlanBumpTotalProps) {
  const { t } = useTranslation(['common', 'publisher'])
  const totalAmountWithTax =
    (productPricing?.grossAmount ?? 0) + bumpPlanPricing.grossAmount

  return (
    <>
      <DividerUi />
      <PricePlanItemWithoutLeftOffsetUi>
        <PricePlanDescriptionUi
          fontSize={descriptionFontSize}
          mobileFontSize={mobileDescriptionFontSize}
          fontStyle={descriptionFontStyle}
          mobileFontStyle={mobileDescriptionFontStyle}
          fontWeight={descriptionFontWeight}
          mobileFontWeight={mobileDescriptionFontWeight}
          fontFamily={descriptionFontFamily}
          mobileFontFamily={mobileDescriptionFontFamily}
          lineHeight={descriptionLineHeight}
          mobileLineHeight={mobileDescriptionLineHeight}
          color={descriptionColor}
          mobileColor={mobileDescriptionColor}
          data-test-id={`offer-price-product-bump-total-tax-${product?.name}`}
        >
          {t('components.offer_price.one_shots_summary.total_amount_with_tax')}
        </PricePlanDescriptionUi>
        <PricePlanAmountUi
          fontSize={amountFontSize}
          mobileFontSize={mobileAmountFontSize}
          fontStyle={amountFontStyle}
          mobileFontStyle={mobileAmountFontStyle}
          fontWeight={amountFontWeight}
          mobileFontWeight={mobileAmountFontWeight}
          fontFamily={amountFontFamily}
          mobileFontFamily={mobileAmountFontFamily}
          lineHeight={amountLineHeight}
          mobileLineHeight={mobileAmountLineHeight}
          color={amountColor}
          mobileColor={mobileAmountColor}
        >
          {product && (
            <>
              <CurrencyBadgeUi
                color={amountColor}
                mobileColor={mobileAmountColor}
              >
                {product.currency}
              </CurrencyBadgeUi>

              {getReadablePrice(
                centsToEuro(totalAmountWithTax),
                product?.currency,
                locale,
              )}
            </>
          )}
        </PricePlanAmountUi>
      </PricePlanItemWithoutLeftOffsetUi>
    </>
  )
}

export default ProductAndPricePlanBumpTotal
