import { IdentificationTypeEnum, PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'

const brazilianTaxNumberOptions = {
  personalIdentificationType: IdentificationTypeEnum.CPF,
  companyIdentificationType: IdentificationTypeEnum.CNPJ,
  detectIdentificationType: function (value: string) {
    if (value.length > 11) {
      return this.companyIdentificationType
    } else {
      return this.personalIdentificationType
    }
  },
}

const columbianCompanyRegex = new RegExp(/\d*-\d$/g)

const columbianTaxNumberOptions = {
  personalIdentificationType: IdentificationTypeEnum.CC,
  companyIdentificationType: IdentificationTypeEnum.NIT,
  detectIdentificationType: function (value: string) {
    if (columbianCompanyRegex.test(value)) {
      return this.companyIdentificationType
    } else {
      return this.personalIdentificationType
    }
  },
}

const mexicanTaxNumberOptions = {
  personalIdentificationType: '' as IdentificationTypeEnum,
  companyIdentificationType: '' as IdentificationTypeEnum,
  detectIdentificationType: function (value: string) {
    return this.personalIdentificationType
  },
}

const argentinianTaxNumberOptions = {
  personalIdentificationType: IdentificationTypeEnum.DNI,
  companyIdentificationType: IdentificationTypeEnum.DNI,
  detectIdentificationType: function (value: string) {
    return this.personalIdentificationType
  },
}

const chileanTaxNumberOptions = {
  personalIdentificationType: IdentificationTypeEnum.RUT,
  companyIdentificationType: IdentificationTypeEnum.RUT,
  detectIdentificationType: function (value: string) {
    return this.personalIdentificationType
  },
}

export type CustomTaxNumberOptionsKeys =
  | PaymentMethodEnum.DlocalCard
  | PaymentMethodEnum.DlocalPix
  | PaymentMethodEnum.DlocalBoleto
  | PaymentMethodEnum.MercadoPagoBoleto
  | PaymentMethodEnum.MercadoPagoPix
  | PaymentMethodEnum.MercadoPagoCard
  | PaymentMethodEnum.MercadoPagoBrCard
  | PaymentMethodEnum.MercadoPagoBrBoleto
  | PaymentMethodEnum.MercadoPagoBrPix
  | PaymentMethodEnum.MercadoPagoCoCard
  | PaymentMethodEnum.MercadoPagoMxCard
  | PaymentMethodEnum.MercadoPagoArCard
  | PaymentMethodEnum.MercadoPagoClCard

export const customTaxNumberOptions: Record<
  CustomTaxNumberOptionsKeys,
  {
    personalIdentificationType: IdentificationTypeEnum
    companyIdentificationType: IdentificationTypeEnum
    detectIdentificationType: (value: string) => IdentificationTypeEnum
  }
> = {
  [PaymentMethodEnum.DlocalCard]: brazilianTaxNumberOptions,
  [PaymentMethodEnum.DlocalPix]: brazilianTaxNumberOptions,
  [PaymentMethodEnum.DlocalBoleto]: brazilianTaxNumberOptions,
  [PaymentMethodEnum.MercadoPagoBoleto]: brazilianTaxNumberOptions,
  [PaymentMethodEnum.MercadoPagoPix]: brazilianTaxNumberOptions,
  [PaymentMethodEnum.MercadoPagoCard]: brazilianTaxNumberOptions,
  [PaymentMethodEnum.MercadoPagoBrCard]: brazilianTaxNumberOptions,
  [PaymentMethodEnum.MercadoPagoBrBoleto]: brazilianTaxNumberOptions,
  [PaymentMethodEnum.MercadoPagoBrPix]: brazilianTaxNumberOptions,
  [PaymentMethodEnum.MercadoPagoCoCard]: columbianTaxNumberOptions,
  [PaymentMethodEnum.MercadoPagoMxCard]: mexicanTaxNumberOptions,
  [PaymentMethodEnum.MercadoPagoArCard]: argentinianTaxNumberOptions,
  [PaymentMethodEnum.MercadoPagoClCard]: chileanTaxNumberOptions,
}
