import axios from 'axios'
import { PageId } from '../store/page/PageStateInterface'
import { PurchaseProcessId } from '../store/payment/PaymentStateInterface'

export function buyMainOffer(
  pageId: PageId,
  purchaseProcessId: PurchaseProcessId,
  body: any,
) {
  return axios.post(`/api/purchase-service/buy-main-offer/${pageId}`, body)
}

export function buyUpsellOffer(
  pageId: PageId,
  purchaseProcessId: PurchaseProcessId,
  body: any,
) {
  return axios.post(
    `/api/purchase-service/buy-upsell/${pageId}/${purchaseProcessId}`,
    body,
  )
}
