import PropTypes from 'prop-types'
import React from 'react'
import usePayment, { selectors } from 'publisher/hooks/usePayment'
import BaseField from './BaseField'

function CompanyNameField({ entity }) {
  const isPersonal = usePayment(selectors.isCustomerPersonal)
  return isPersonal ? (
    <div />
  ) : (
    <BaseField dataTestId="company-name-field" entity={entity} />
  )
}

CompanyNameField.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    options: PropTypes.shape({
      slug: PropTypes.string,
      placeholder: PropTypes.string,
      placeholderColor: PropTypes.string,
    }).isRequired,
    styles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    mobileStyles: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    type: PropTypes.string.isRequired,
  }),
}

export default CompanyNameField
