import styled, { css } from 'styled-components'

type ShippingItemProps = {
  isActive: boolean
  isLoading: boolean
}

const ShippingItem = styled.label<ShippingItemProps>`
  display: inline-flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 20px;
  border: 1px solid #bfc6d1;
  border-radius: 6px;
  transition: border 0.25s ease;

  ${({ isActive }) =>
    isActive &&
    css`
      border: 1px solid #00a0ff;
    `}

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0.5;
      cursor: progress;
    `}
`

export default ShippingItem
