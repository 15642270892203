export enum CurrencyEnum {
  AUSTRALIAN_DOLLAR = 'aud',
  CANADIAN_DOLLAR = 'cad',
  DANISH_KRONE = 'dkk',
  EURO = 'eur',
  HONG_KONG_DOLLAR = 'hkd',
  ISRAELI_NEW_SHEKEL = 'ils',
  JAPANESE_YEN = 'jpy',
  MEXICAN_PESO = 'mxn',
  NEW_ZEALAND_DOLLAR = 'nzd',
  NORWEGIAN_KRONE = 'nok',
  PHILIPPINE_PESO = 'php',
  POLISH_ZLOTY = 'pln',
  POUND_STERLING = 'gbp',
  RUSSIAN_RUBLE = 'rub',
  SINGAPORE_DOLLAR = 'sgd',
  SWEDISH_KRONA = 'sek',
  SWISS_FRANC = 'chf',
  THAI_BAHT = 'thb',
  US_DOLLAR = 'usd',
  BRAZILIAN_REAL = 'brl',
  INDIAN_RUPEE = 'inr',
  MALAYSIAN_RINGGIT = 'myr',
  CZECH_CROWN = 'czk',
  ROMANIA_LEI = 'ron',
  UNITED_ARAB_EMIRATES_DIRHAM = 'aed',
  HUNGARIAN_FORINT = 'huf',
  COLOMBIAN_PESO = 'cop',
  CHILEAN_PESO = 'clp',
  ARGENTINE_PESO = 'ars',
  SAUDI_RIYAL = 'sar',
  CENTRAL_AFRICAN_FRANC = 'xaf',
  EGYPTIAN_POUND = 'egp',
  GHANAIAN_CEDI = 'ghs',
  GUINEAN_FRANC = 'gnf',
  KENYAN_SHILLING = 'kes',
  MALAWIAN_KWACHA = 'mwk',
  MOROCCAN_DIRHAM = 'mad',
  NIGERIAN_NAIRA = 'ngn',
  RWANDAN_FRANC = 'rwf',
  SIERRA_LEONEAN_LEONE = 'sll',
  SAO_TOME_AND_PRINCIPE_DOBRA = 'std',
  SOUTH_AFRICAN_RAND = 'zar',
  TANZANIAN_SHILLING = 'tzs',
  UGANDAN_SHILLING = 'ugx',
  WEST_AFRICAN_CFA_FRANC_BCEAO = 'xof',
  ZAMBIAN_KWACHA = 'zmw',
  TURKISH_LIRA = 'try',
  INDONESIAN_RUPIAH = 'idr',
  SRI_LANKAN_RUPEE = 'lkr',
  SERBIAN_DINAR = 'rsd',
}
