import styled from 'styled-components'

const PricePlanUi = styled.div`
  display: grid;
  gap: 6px;
  ${p => p.theme.phone} {
    gap: 4px;
  }
`

export default PricePlanUi
