import styled, { css } from 'styled-components'

const PaymentMethodSwitcherCreditCardUi = styled.div`
  height: 36px;
  max-width: 223px;
  width: 100%;
  background-image: url(${process.env
    .CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/credit_cards.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-left: auto;

  ${p => p.theme.phone} {
    background-image: url(${process.env
      .CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/credit_cards_mobile.svg);
    height: 38px;
    width: 60px;
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      background-image: url(${process.env
        .CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/credit_cards_mobile.svg);
      height: 38px;
      width: 60px;
    `}
`

export default PaymentMethodSwitcherCreditCardUi
