import React from 'react'
import { useTranslation } from 'react-i18next'
import { CustomerTypeEnum } from 'common/enums/CustomerTypeEnum'
import CustomerTypeRadioUi from './ui/CustomerTypeRadioUi'
import {
  isCustomerCompany,
  isCustomerPersonal,
} from './utils/CustomerTypeUtils'

interface CustomerTypeNewProps {
  toggleCustomerType?: (value: CustomerTypeEnum) => void
  activeCustomerType?: CustomerTypeEnum
}

function CustomerTypeNew({
  toggleCustomerType,
  activeCustomerType,
}: CustomerTypeNewProps) {
  const { t } = useTranslation('common')

  return (
    <div>
      <label htmlFor="customer-type-personal">
        <CustomerTypeRadioUi
          type="radio"
          id="customer-type-personal"
          data-testid="customer-type-personal"
          name="customer-type"
          onChange={() =>
            toggleCustomerType && toggleCustomerType(CustomerTypeEnum.Personal)
          }
          defaultChecked={isCustomerPersonal(activeCustomerType)}
        />
        {t('customer_type.personal')}
      </label>
      <label htmlFor="customer-type-company">
        <CustomerTypeRadioUi
          type="radio"
          id="customer-type-company"
          data-testid="customer-type-company"
          name="customer-type"
          onChange={() =>
            toggleCustomerType && toggleCustomerType(CustomerTypeEnum.Company)
          }
          defaultChecked={isCustomerCompany(activeCustomerType)}
        />
        {t('customer_type.company')}
      </label>
    </div>
  )
}

export default CustomerTypeNew
