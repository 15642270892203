import React from 'react'
import { useTranslation } from 'react-i18next'
import { pricePlanType } from '../../../types/OrderSummaryType'
import OrderSummaryHeaderUi from './ui/OrderSummaryHeaderUi'
import OrderSummaryUi from './ui/OrderSummaryUi'
import PricePlanColumnUi from './ui/PricePlanColumnUi'
import PricePlanRowUi from './ui/PricePlanRowUi'

type OrderSummaryProps = {
  pricePlans?: pricePlanType[]
  attrId: string
  products?: productType[]
}

type productType = {
  name: string
  price: string
}

function OrderSummary({
  pricePlans = [],
  attrId,
  products = [],
}: OrderSummaryProps) {
  const { t } = useTranslation('common')

  return (
    <OrderSummaryUi id={attrId} data-test-element="order-summary">
      <OrderSummaryHeaderUi>
        <PricePlanColumnUi>
          {t('components.order_summary.header.product')}
        </PricePlanColumnUi>
        <PricePlanColumnUi align="right">
          {t('components.order_summary.header.price')}
        </PricePlanColumnUi>
      </OrderSummaryHeaderUi>
      {pricePlans.map(plan => (
        <PricePlanRowUi key={`plan-${plan.id}`}>
          <PricePlanColumnUi>{plan.name}</PricePlanColumnUi>
          <PricePlanColumnUi align="right">{plan.price}</PricePlanColumnUi>
        </PricePlanRowUi>
      ))}
      {products.map(product => (
        <PricePlanRowUi key={`product-${product.name}`}>
          <PricePlanColumnUi>{product.name}</PricePlanColumnUi>
          <PricePlanColumnUi align="right">{product.price}</PricePlanColumnUi>
        </PricePlanRowUi>
      ))}
    </OrderSummaryUi>
  )
}

export default OrderSummary
