import { CouponTypeEnum } from 'common/enums/CouponTypeEnum'
import { PricePlanTypeEnum } from 'common/enums/PricePlanTypeEnum'
import { usePayment } from 'publisher/store'
import {
  getActivePricePlan,
  getSelectedProductPrice,
  getProduct,
  getCheckedCoupon,
} from 'publisher/store/payment/paymentSelectors'

export function useAreInstallmentsVisible() {
  const pricePlan = usePayment(getActivePricePlan)
  const price = usePayment(getSelectedProductPrice)
  const product = usePayment(getProduct)

  const coupon = usePayment(getCheckedCoupon)
  const isFullyPaidByCoupon =
    (coupon?.discountType === CouponTypeEnum.Percent &&
      coupon.discountAmount === 100) ||
    (coupon?.discountType === CouponTypeEnum.Fixed &&
      coupon.discountAmount >= price)

  return !isFullyPaidByCoupon && pricePlan
    ? pricePlan.type === PricePlanTypeEnum.OneShot
    : !!product?.id
}
