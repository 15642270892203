import styled from 'styled-components'

const CreditCardInputUi = styled.input`
  border: none;
  outline: none;
  width: 100%;
  font-size: 16px;
  background-color: transparent;
  text-transform: uppercase;
`

export default CreditCardInputUi
