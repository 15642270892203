import {
  CHANGE_COUNTRY_VAT,
  CHECK_COUPON,
  SUBMIT_FAIL,
  TOGGLE_CHECKED_BUMP,
  TOGGLE_CUSTOMER_TYPE,
  TOGGLE_OFFER_PRICE_PLAN,
  TOGGLE_PAYMENT_METHOD,
  UPDATE_COUPON,
} from 'publisher/actionTypes'
import { checkAndFetchCoupon } from 'publisher/api/payment'
import { getFunnelStepId } from 'publisher/reducers/managementReducer'
import { getCouponCode } from 'publisher/reducers/paymentReducer'

export const toggleCustomerType = type => dispatch => {
  dispatch({
    type: TOGGLE_CUSTOMER_TYPE,
    payload: type,
  })
}

export const togglePricePlan = plan => dispatch => {
  dispatch({
    type: TOGGLE_OFFER_PRICE_PLAN,
    payload: plan,
  })
}

export const changeCountryVat = vat => dispatch => {
  dispatch({
    type: CHANGE_COUNTRY_VAT,
    payload: vat,
  })
}

export const togglePaymentMethod = method => ({
  type: TOGGLE_PAYMENT_METHOD,
  payload: method,
})

export const toggleCheckedBump = value => dispatch => {
  dispatch({
    type: TOGGLE_CHECKED_BUMP,
    payload: value,
  })
}

export const submitFail = errors => ({
  type: SUBMIT_FAIL,
  payload: errors,
})

export const checkCoupon = () => async (dispatch, getState) => {
  const funnelStepId = getFunnelStepId(getState().management)
  const couponCode = getCouponCode(getState().payment)
  const { data } = await checkAndFetchCoupon(funnelStepId, couponCode)
  if (data.success) {
    dispatch({
      type: CHECK_COUPON + 'SUCCESS',
      payload: data.coupon,
    })
  } else {
    dispatch({
      type: CHECK_COUPON + 'FAIL',
      payload: data.error,
    })
  }
}

export const updateCoupon = code => dispatch => {
  dispatch({
    type: UPDATE_COUPON,
    payload: code,
  })
}
