import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import StyleWrapper from 'common/components/StyleWrapper'
import {
  getCountryCodeByVatNumber,
  isFromEU,
} from 'common/constants/countryVatList'
import { splitMarginsAndRestStyles } from 'common/utils/styleUtils'
import BaseField from 'publisher/components/entities/Field/BaseField'
import usePayment from 'publisher/hooks/usePayment'
import * as paymentSelectors from 'publisher/reducers/paymentReducer'
import { useOptIn } from 'publisher/store'
import {
  getFieldValueBySlug,
  getCustomerCountry,
} from 'publisher/store/optIn/optInSelectors'

function CompanyVatField({ entity }) {
  const { t } = useTranslation()
  const value = useOptIn(state =>
    getFieldValueBySlug(state, entity.options.slug),
  )
  const customerCountryCode = useOptIn(state => getCustomerCountry(state))
  const [notSuitableForSelectedCountry, setNotSuitableForSelectedCountry] =
    useState(false)
  const isPersonal = usePayment(paymentSelectors.isCustomerPersonal)

  useEffect(() => {
    if (isFromEU(customerCountryCode)) {
      const countryCodeByVatNumber = value && getCountryCodeByVatNumber(value)
      const isNotSuitableForSelectedCountry =
        countryCodeByVatNumber && customerCountryCode !== countryCodeByVatNumber

      setNotSuitableForSelectedCountry(isNotSuitableForSelectedCountry)
    }
  }, [value, customerCountryCode, t])

  if (typeof window === 'undefined') {
    return (
      <StyleWrapper
        desktop={entity.options.appearance.desktop}
        mobile={entity.options.appearance.mobile}
        styles={splitMarginsAndRestStyles(entity.styles)[0]}
        mobileStyles={splitMarginsAndRestStyles(entity.mobileStyles)[0]}
      />
    )
  }

  return customerCountryCode !== 'BR' && isPersonal ? (
    <div />
  ) : (
    <BaseField
      entity={entity}
      extraErrors={
        notSuitableForSelectedCountry
          ? [t('validation.vat_does_not_suitable_for_selected_country')]
          : []
      }
    />
  )
}

CompanyVatField.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    options: PropTypes.shape({
      slug: PropTypes.string,
      placeholder: PropTypes.string,
      placeholderColor: PropTypes.string,
      appearance: PropTypes.shape({
        desktop: PropTypes.bool.isRequired,
        mobile: PropTypes.bool.isRequired,
      }).isRequired,
    }).isRequired,
    styles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    mobileStyles: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    type: PropTypes.string.isRequired,
  }),
}

export default CompanyVatField
