import React from 'react'
import { useTranslation } from 'react-i18next'
import { SubscriptionPlanIntervalEnum } from 'common/enums/SubscriptionPlanIntervalEnum'

type LimitedSubscriptionPlanProps = {
  amountWithCurrency: string
  trialInterval: SubscriptionPlanIntervalEnum
  trialPeriod: number
  interval: SubscriptionPlanIntervalEnum
  intervalCount: number
  limitOfPayments: number
}

function LimitedSubscriptionPlan({
  amountWithCurrency,
  trialInterval,
  trialPeriod,
  interval,
  intervalCount,
  limitOfPayments,
}: LimitedSubscriptionPlanProps) {
  const { t } = useTranslation(['common', 'publisher'])

  let readableAmount = ''
  if (trialPeriod) {
    const interval = t(`price_plan.trial_period.${trialInterval}`, {
      count: trialPeriod,
    })
    readableAmount = `${t(
      'components.offer_price.price_plans.trial_period_new',
      {
        trialPeriodPrice: t(
          'components.offer_price.price_plans.trial_period_price',
        ),
        trialInterval: interval,
      },
    )} `
  }
  // readableAmount += t('components.offer_price.price_plans.limited', {
  //   amount: amountWithCurrency,
  //   interval: t(`price_plan.interval.${interval}`, {
  //     count: intervalCount,
  //   }),
  //   intervalWithLimitOfPayments: t(`price_plan.interval.${interval}`, {
  //     count: limitOfPayments * intervalCount,
  //   }),
  // })

  readableAmount += t(
    'components.offer_price.price_plans.limited_with_limit_of_payments',
    {
      count: limitOfPayments,
      amount: amountWithCurrency,
      interval: t(`price_plan.interval.${interval}`, {
        count: intervalCount,
      }),
      intervalHack: t(
        intervalCount > 1
          ? 'components.offer_price.price_plans.interval_hack_many'
          : 'components.offer_price.price_plans.interval_hack_one',
      ),
    },
  )

  return <>{readableAmount}</>
}

export default LimitedSubscriptionPlan
