import styled from 'styled-components'

const CreditCardSelectUi = styled.select`
  width: 100%;
  font-size: 14px;
  border: 1px solid #e4e8ee;
  padding: 18px 23px;
  background-color: #fff;
  border-radius: 5px;
`

export default CreditCardSelectUi
