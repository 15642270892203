import React from 'react'
import { usePayment } from 'publisher/store'
import paymentSelectors from 'publisher/store/payment/paymentSelectors'

function CashOnDelivery() {
  const instruction = usePayment(paymentSelectors.getCashOnDeliveryInstruction)
  if (!instruction) {
    return <></>
  }
  return <div dangerouslySetInnerHTML={{ __html: instruction }} />
}

export default CashOnDelivery
