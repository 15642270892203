import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useRef, useState } from 'react'
import ProductImagesCarousel, {
  CarouselSlide,
} from 'common/components/entities/Product/ProductImagesCarousel'
import ProductQuantity from 'common/components/entities/Product/ProductQuantity'
import ProductStockLimit from 'common/components/entities/Product/ProductStockLimit'
import ProductCarouselImageContainerUi from 'common/components/entities/Product/ui/ProductCarouselImageContainerUi'
import ProductContainerUi from 'common/components/entities/Product/ui/ProductContainerUi'
import ProductContentContainerUi from 'common/components/entities/Product/ui/ProductContentContainerUi'
import ProductDescriptionContainerUi from 'common/components/entities/Product/ui/ProductDescriptionContainerUi'
import ProductInfoContainerUi from 'common/components/entities/Product/ui/ProductInfoContainerUi'
import ProductNameContainerUi from 'common/components/entities/Product/ui/ProductNameContainerUi'
import ProductPriceContainerUi from 'common/components/entities/Product/ui/ProductPriceContainerUi'
import ProductEntityInterface, {
  ImageFileInterface,
  ProductVariantInterface,
} from 'common/types/entities/ProductInterface'
import { getReadablePrice } from 'common/utils/priceCalculator'
import { getLocale } from 'client/reducers/pageReducer'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import {
  managementSelectors,
  useManagement,
  usePage,
  usePayment,
} from 'publisher/store'
import pageSelectors from 'publisher/store/page/pageSelectors'
import {
  decreaseProductQuantity,
  increaseProductQuantity,
  setProductActiveVariant,
} from 'publisher/store/payment/paymentActions'
import paymentSelectors from 'publisher/store/payment/paymentSelectors'
import OverlayCarousel from './OverlayCarousel'
import ProductOptions from './ProductOptions'
import CurrencyBadgeUi from 'common/components/entities/OfferPriceNew/ui/CurrencyBadgeUi'
import { mockedPhysicalProduct } from 'common/mocks/product'

const isCurrentSlidesImagesDifferentFromProductImages = (
  imageSlides: ImageFileInterface[],
  productImages: ImageFileInterface[],
) => {
  return JSON.stringify(imageSlides) !== JSON.stringify(productImages)
}

const replaceFirstImageInSlides = (
  image: ImageFileInterface,
  state: ImageFileInterface[],
) => {
  const updatedSlides = [...state]
  updatedSlides[0] = image
  return updatedSlides
}

function Product({ entity }: { entity: ProductEntityInterface }) {
  let product = usePayment(paymentSelectors.getProduct)
  const locale = usePage(getLocale)
  const activeProductVariant = usePayment(
    paymentSelectors.getProductActiveVariant,
  )
  const isScreenshotMode = useManagement(managementSelectors.isScreenshotMode)
  const quantity = usePayment(paymentSelectors.getProductQuantity)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isTemplate = usePage(pageSelectors.getIsPageTemplate)
  const [imageSlides, setImageSlides] = useState<ImageFileInterface[]>([])
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)
  const carouselRef = useRef<HTMLDivElement>(null)
  const [needScroll, setNeedScroll] = useState(false)
  const [activeVariant, setActiveVariant] =
    useState<ProductVariantInterface | null>(null)
  const [isOverlayCarouselOpen, setIsOverlayCarouselOpen] = useState(false)
  const isPreview = useManagement(managementSelectors.isPreview)
  const isSsr = typeof window === 'undefined'

  function updateClosedActiveSlideIndex(index: number) {
    return function () {
      setActiveSlideIndex(prevIndex => {
        setNeedScroll(prevIndex !== index)
        return index
      })
    }
  }

  function updateActiveSlideIndex(index: number) {
    setActiveSlideIndex(prevIndex => {
      setNeedScroll(prevIndex !== index)
      return index
    })
  }
  function handleIncreaseQuantity() {
    dispatch(increaseProductQuantity())
  }

  function handleDecreaseQuantity() {
    dispatch(decreaseProductQuantity())
  }

  function scrollToFirstSlide() {
    setActiveSlideIndex(0)
    setNeedScroll(true)
  }

  function handleChangeActiveVariant(variant: ProductVariantInterface) {
    setActiveVariant(variant)
    dispatch(setProductActiveVariant(variant))
    if (product) {
      if (variant.image) {
        const variantImage = variant.image
        if (
          isCurrentSlidesImagesDifferentFromProductImages(
            imageSlides,
            product.images,
          )
        ) {
          if (
            JSON.stringify(imageSlides[0]) !== JSON.stringify(variant.image)
          ) {
            setImageSlides(prev =>
              replaceFirstImageInSlides(variantImage, prev),
            )
            scrollToFirstSlide()
          }
        } else {
          setImageSlides(prev => [variantImage, ...prev])
          scrollToFirstSlide()
        }
      } else {
        if (
          isCurrentSlidesImagesDifferentFromProductImages(
            imageSlides,
            product.images,
          )
        ) {
          setImageSlides(prev => {
            return [...prev].slice(1, prev.length)
          })
          scrollToFirstSlide()
        }
      }
    }
  }

  useEffect(() => {
    if (product) {
      const carouselImages = [...product.images]
      if (product.productVariants[0]?.image) {
        carouselImages.unshift(product.productVariants[0]?.image)
      }
      setImageSlides(carouselImages)
      if (!activeProductVariant) {
        dispatch(setProductActiveVariant(product.productVariants[0] || null))
        setActiveVariant(product.productVariants[0])
      }
    }
  }, [])

  useEffect(() => {
    if (activeProductVariant?.id !== activeVariant?.id) {
      setActiveVariant(activeProductVariant)
    }
  }, [activeProductVariant])

  if (isTemplate || (!product && isPreview) || isScreenshotMode) {
    product = mockedPhysicalProduct
  }

  if ((isSsr && !isScreenshotMode) || !product) {
    return (
      <StyleWrapper
        margin={entity.margin}
        appearance={entity.appearance}
        mobileMargin={entity.mobileMargin}
      />
    )
  }

  const stockLimit = activeProductVariant?.stock ?? product.stock ?? 0
  const quantityLimit = entity.showStockLimit ? stockLimit : undefined

  return (
    <StyleWrapper
      margin={entity.margin}
      appearance={entity.appearance}
      mobileMargin={entity.mobileMargin}
    >
      <ProductContainerUi
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        fontFamily={entity.fontFamily}
        fontWeight={entity.fontWeight}
        fontStyle={entity.fontStyle}
        mobileFontFamily={entity.mobileFontFamily}
        mobileFontWeight={entity.mobileFontWeight}
        mobileFontStyle={entity.mobileFontStyle}
      >
        <ProductContentContainerUi>
          {imageSlides.length > 0 && (
            <ProductInfoContainerUi>
              <ProductImagesCarousel
                totalItems={imageSlides.length}
                activeSlideIndex={activeSlideIndex}
                switchActiveSlideNumber={updateActiveSlideIndex}
                carouselRef={carouselRef}
                key={imageSlides.length}
              >
                {imageSlides.map((image, index) => (
                  <CarouselSlide
                    key={image.id}
                    active={
                      imageSlides.findIndex(el => el.id === image.id) ===
                      activeSlideIndex
                    }
                    setActive={updateClosedActiveSlideIndex(index)}
                    needScroll={needScroll}
                    carouselRef={carouselRef}
                  >
                    <ProductCarouselImageContainerUi
                      key={image.id}
                      src={image.path}
                      onClick={() => setIsOverlayCarouselOpen(true)}
                    />
                  </CarouselSlide>
                ))}
              </ProductImagesCarousel>
            </ProductInfoContainerUi>
          )}
          <ProductInfoContainerUi>
            <ProductNameContainerUi>{product.name}</ProductNameContainerUi>
            <ProductPriceContainerUi>
              <div>{t('entity.product.price_label')}:</div>
              <CurrencyBadgeUi style={{ marginRight: 0 }}>{product.currency}</CurrencyBadgeUi>
              {getReadablePrice(
                (activeVariant?.price && activeVariant?.price / 100) ||
                  product.price / 100,
                product.currency,
                locale,
              )}
            </ProductPriceContainerUi>
            <ProductStockLimit
              stockLimit={stockLimit}
              showStockLimit={entity.showStockLimit}
            />
            <hr style={{ border: '1px solid #E1E3E5', width: '100%' }} />
            {product.description ? (
              <ProductDescriptionContainerUi>
                {product.description}
              </ProductDescriptionContainerUi>
            ) : (
              //empty div with the negative gap margin
              <div style={{ marginTop: '-20px' }} />
            )}
            <ProductQuantity
              quantity={quantity}
              showQuantity={entity.showQuantity}
              increaseQuantity={handleIncreaseQuantity}
              decreaseQuantity={handleDecreaseQuantity}
              limit={quantityLimit}
            />
            {activeVariant && (
              <ProductOptions
                product={product}
                activeVariant={activeVariant}
                changeActiveVariant={handleChangeActiveVariant}
              />
            )}
          </ProductInfoContainerUi>
        </ProductContentContainerUi>
      </ProductContainerUi>
      <OverlayCarousel
        open={isOverlayCarouselOpen}
        setOpen={setIsOverlayCarouselOpen}
        slides={imageSlides}
        initialActiveSlideIndex={activeSlideIndex}
      />
    </StyleWrapper>
  )
}

export default Product
