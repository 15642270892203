const idealElementOptions = {
  style: {
    base: {
      color: '#32325d',
      fontSize: '16px',
      padding: '10px 12px',
    },
  },
}

export default idealElementOptions
