import React from 'react'
import { useTranslation } from 'react-i18next'
import OrderSummaryHeaderUi from './ui/OrderSummaryHeaderUi'
import OrderSummaryUi from './ui/OrderSummaryUi'
import PricePlanColumnUi from './ui/PricePlanColumnUi'
import PricePlanRowUi from './ui/PricePlanRowUi'
import SkeletonOrderItemUi from './ui/SkeletonOrderItemUi'

function SkeletonOrderSummary() {
  const { t } = useTranslation('common')

  return (
    <OrderSummaryUi>
      <OrderSummaryHeaderUi>
        <PricePlanColumnUi>
          {t('components.order_summary.header.product')}
        </PricePlanColumnUi>
        <PricePlanColumnUi align="right">
          {t('components.order_summary.header.price')}
        </PricePlanColumnUi>
      </OrderSummaryHeaderUi>
      {Array.from(Array(3).keys()).map((el, idx) => (
        <PricePlanRowUi key={idx}>
          <PricePlanColumnUi>
            <SkeletonOrderItemUi width={350} />
          </PricePlanColumnUi>
          <PricePlanColumnUi align="right">
            <SkeletonOrderItemUi width={150} />
          </PricePlanColumnUi>
        </PricePlanRowUi>
      ))}
    </OrderSummaryUi>
  )
}

export default SkeletonOrderSummary
