import styled, { css } from 'styled-components'

const CouponButtonUi = styled.button`
  padding: 5px 8px;
  outline: none;
  transition: opacity 0.2s ease;
  cursor: pointer;
  opacity: ${p => (p.checking ? 0.5 : 1)};
  ${p => p.theme.phone} {
    width: 100%;
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${({ styles = {} }) => styles}
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${p.mobileStyles};
      width: 100%;
    `}
`

export default CouponButtonUi
