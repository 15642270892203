import styled, { css } from 'styled-components'

const ProductContentContainerUi = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10%;
  padding: 25px;
  ${p => p.theme.phone} {
    flex-direction: column-reverse;
    gap: 25px;
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      flex-direction: column-reverse;
      gap: 25px;
    `}
`

export default ProductContentContainerUi
