import React from 'react'
import { useTranslation } from 'react-i18next'
import FieldErrors from 'publisher/components/FieldErrors'
import { CustomTaxNumberOptionsKeys } from 'publisher/pages/offer-page/constants/custom-tax-number-options'
import { useCustomTaxNumber } from 'publisher/pages/offer-page/hooks/useCustomTaxNumber'
import CreditCardFieldUi from '../ui/CreditCardFieldUi'
import CreditCardInputUi from '../ui/CreditCardInputUi'
import CreditCardInputWrapperUi from '../ui/CreditCardInputWrapperUi'
import CreditCardLabelUi from '../ui/CreditCardLabelUi'
import CreditCardRowUi from '../ui/CreditCardRowUi'

interface TaxNumberProps {
  type: CustomTaxNumberOptionsKeys
}

function TaxNumber({ type }: TaxNumberProps) {
  const taxNumberOptions = useCustomTaxNumber(type)

  const { t } = useTranslation(['common', 'publisher'])

  function handleTaxNumberChange(event: React.ChangeEvent<HTMLInputElement>) {
    taxNumberOptions.change(event.target.value)
  }
  return (
    <CreditCardRowUi>
      <CreditCardFieldUi>
        <CreditCardLabelUi htmlFor={`tax-number-${type}`}>
          {t('components.payment_methods.identification_number')}
        </CreditCardLabelUi>
        <CreditCardInputWrapperUi
          hasErrors={taxNumberOptions.errors.length > 0}
        >
          <CreditCardInputUi
            id={`tax-number-${type}`}
            value={taxNumberOptions.value}
            onChange={handleTaxNumberChange}
          />
        </CreditCardInputWrapperUi>
        {taxNumberOptions.errors && (
          <FieldErrors errors={taxNumberOptions.errors} />
        )}
      </CreditCardFieldUi>
    </CreditCardRowUi>
  )
}

export default TaxNumber
