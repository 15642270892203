import styled from 'styled-components'

const OrderSummaryHeaderUi = styled.div`
  display: flex;
  border-bottom: 1px solid #ccc;
  padding: 5px 0;
  font-weight: bold;
`

export default OrderSummaryHeaderUi
