import { TFunction } from 'i18next'
import { PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'

export const mercadoCreditCardTypes = [
  PaymentMethodEnum.MercadoPagoCard,
  PaymentMethodEnum.MercadoPagoBrCard,
  PaymentMethodEnum.MercadoPagoCoCard,
  PaymentMethodEnum.MercadoPagoMxCard,
  PaymentMethodEnum.MercadoPagoArCard,
  PaymentMethodEnum.MercadoPagoClCard,
]

export const creditCardMethodTypes = [
  PaymentMethodEnum.StripeCard,
  PaymentMethodEnum.DlocalCard,
  ...mercadoCreditCardTypes,
]

export const isPaymentMethodTranslatable = (
  paymentMethod: PaymentMethodEnum,
) => {
  return (
    creditCardMethodTypes.includes(paymentMethod) ||
    paymentMethod === PaymentMethodEnum.CashOnDelivery
  )
}

export const getPaymentMethodTitle = (
  paymentMethod: PaymentMethodEnum,
  t: TFunction,
) => {
  if (paymentMethod === PaymentMethodEnum.CashOnDelivery) {
    return t('components.payment_methods.cash_on_delivery.title')
  }

  const baseTitle = t('components.payment_methods.credit_cards.label')
  if (paymentMethod === PaymentMethodEnum.DlocalCard)
    return baseTitle + ' (Dlocal)'

  if (mercadoCreditCardTypes.includes(paymentMethod))
    return baseTitle + ' (MercadoPago)'

  return baseTitle
}
