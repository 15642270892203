import { ResourceTypeEnum } from 'common/enums/PricingEnum'
import { PricePlan } from 'common/types/OfferInterface'
import {
  ProductInterface,
  ProductVariantInterface,
} from 'common/types/entities/ProductInterface'
import { usePayment } from 'publisher/store'
import { getOfferPricing } from 'publisher/store/payment/paymentSelectors'

export interface UseSelectedOfferPricing {
  selectedProduct?: ProductInterface | null
  selectedPricePlan?: PricePlan | null
  activeProductVariant?: ProductVariantInterface | null
}

export const useSelectedOfferPricing = ({
  selectedProduct,
  selectedPricePlan,
  activeProductVariant,
}: UseSelectedOfferPricing) => {
  const offerPricings = usePayment(getOfferPricing)

  if (
    !selectedProduct?.id &&
    !selectedPricePlan?.id &&
    !activeProductVariant?.id
  ) {
    return
  }

  return offerPricings?.find(({ entityId, entityType }) => {
    switch (entityType) {
      case ResourceTypeEnum.Product:
        return entityId === selectedProduct?.id
      case ResourceTypeEnum.PricePlan:
        return entityId === selectedPricePlan?.id
      case ResourceTypeEnum.ProductVariant:
        return entityId === activeProductVariant?.id
      default:
        return false
    }
  })
}
