export const EUCountryVatList = {
  BE: 21,
  BG: 20,
  CZ: 21,
  DK: 25,
  DE: 19,
  EE: 22,
  IE: 21,
  EL: 24,
  GR: 24,
  ES: 21,
  FR: 20,
  HR: 25,
  IT: 22,
  CY: 19,
  LV: 21,
  LT: 21,
  LU: 17,
  HU: 27,
  MT: 18,
  NL: 21,
  AT: 20,
  PL: 23,
  PT: 23,
  RO: 19,
  SI: 22,
  SK: 20,
  FI: 24,
  SE: 25,
}

export function getCountryVat(countryCode) {
  return (
    EUCountryVatList[countryCode] || nonEUCountryVatList[countryCode] || null
  )
}

const nonEUCountryVatList = {
  GB: 20,
  NZ: 15,
}

export function getCountryCodeByVatNumber(vat) {
  const countryCode = vat.slice(0, 2).toUpperCase()
  if (countryCode === 'EL') {
    return 'GR'
  }

  return countryCode
}

export const isFromEU = code => Boolean(EUCountryVatList[code])
