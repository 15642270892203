import React from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import {
  ForgotPasswordUi,
  ForgotPasswordWrapperUi,
} from 'common/components/entities/CustomLogin'
import { CustomLoginForgotPasswordInterface } from 'common/types/entities/CustomLoginForgotPasswordInterface'
import usePage, { selectors as pageSelectors } from 'publisher/hooks/usePage'

type CustomLoginForgotPasswordProps = {
  entity: CustomLoginForgotPasswordInterface
}

function CustomLoginForgotPassword({ entity }: CustomLoginForgotPasswordProps) {
  const locale = usePage(pageSelectors.getLocale)
  return (
    <StyleWrapper
      mobile={entity.appearance.mobile}
      desktop={entity.appearance.desktop}
      styles={entity.margin}
      mobileStyles={entity.mobileMargin}
    >
      <ForgotPasswordWrapperUi
        align={entity.textAlign || 'center'}
        mobileAlign={entity.mobileTextAlign || 'center'}
      >
        <ForgotPasswordUi
          {...entity}
          href={`/dashboard/${locale}/resetting/request`}
        >
          {entity.text}
        </ForgotPasswordUi>
      </ForgotPasswordWrapperUi>
    </StyleWrapper>
  )
}

export default CustomLoginForgotPassword
