import React from 'react'
import CurrencyBadgeUi from 'common/components/entities/OfferPriceNew/ui/CurrencyBadgeUi'
import RadioInputUi from 'common/components/entities/ShippingFees/ui/RadioInputUi'
import ShippingItem from 'common/components/entities/ShippingFees/ui/ShippingItem'
import ShippingItemDescription from 'common/components/entities/ShippingFees/ui/ShippingItemDescription'
import ShippingItemInfo from 'common/components/entities/ShippingFees/ui/ShippingItemInfo'
import ShippingItemName from 'common/components/entities/ShippingFees/ui/ShippingItemName'
import ShippingItemPrice from 'common/components/entities/ShippingFees/ui/ShippingItemPrice'
import { SelectedShipping } from 'common/types/entities/ShippingFeesInterface'
import { ShippingRule, ShippingService } from 'common/types/shippingType'
import { centsToEuro, getReadablePrice } from 'common/utils/priceCalculator'

export interface DefaultShippingProps {
  onChange: (selectedShipping: SelectedShipping) => void
  service: ShippingService
  rule: ShippingRule
  language: string
  isSelected: boolean
  isLoading?: boolean
}

const DefaultShipping = ({
  service,
  rule,
  onChange,
  language,
  isSelected,
  isLoading = false,
}: DefaultShippingProps) => {
  return (
    <ShippingItem
      htmlFor={`conditionition-${rule.id}`}
      isActive={isSelected}
      isLoading={isLoading}
    >
      <ShippingItemInfo>
        <RadioInputUi
          disabled={isLoading}
          id={`conditionition-${rule.id}`}
          onChange={() =>
            onChange({
              ruleId: rule.id,
              currency: rule.currency,
              price: rule.price,
            })
          }
          checked={isSelected}
          type="radio"
        />
        <ShippingItemDescription>
          <ShippingItemName>
            <span>{service.name}</span>
            <span>{rule.name}</span>
          </ShippingItemName>
        </ShippingItemDescription>
      </ShippingItemInfo>
      <ShippingItemPrice>
        <CurrencyBadgeUi>{rule.currency}</CurrencyBadgeUi>
        <strong>
          {getReadablePrice(centsToEuro(rule.price), rule.currency, language)}
        </strong>
      </ShippingItemPrice>
    </ShippingItem>
  )
}

export default DefaultShipping
