import axios from 'axios'

export function buyMainOffer(pageId, purchaseProcessId, body) {
  return axios.post(
    `/secure/stripe/${pageId}/buy-main-offer/${purchaseProcessId}`,
    body,
  )
}

export function buyUpsell(pageId, purchaseProcessId, body) {
  return axios.post(
    `/secure/stripe/${pageId}/buy-upsell/${purchaseProcessId}`,
    body,
  )
}

export function confirmPaymentIntent(pageId, purchaseProcessId) {
  return axios.get(
    `/secure/stripe/${pageId}/payment/confirm/${purchaseProcessId}`,
  )
}
