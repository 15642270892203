import React from 'react'
import OneShotsSummary from 'common/components/entities/OfferPriceNew/OneShotsSummary'
import PricePlan from 'common/components/entities/OfferPriceNew/PricePlan'
import BumpPricePlan from 'common/components/entities/OfferPriceNew/PricePlan/BumpPricePlan'
import Product from 'common/components/entities/OfferPriceNew/Product'
import ProductAndPricePlanBumpTotal from 'common/components/entities/OfferPriceNew/Product/ProductAndPricePlanBumpTotal'
import ProductBump from 'common/components/entities/OfferPriceNew/Product/ProductBump'
import ProductBumpTotal from 'common/components/entities/OfferPriceNew/Product/ProductBumpTotal'
import ShippingSummary from 'common/components/entities/OfferPriceNew/Product/ShippingSummary'
import DividerUi from 'common/components/entities/OfferPriceNew/ui/DividerUi'
import PricePlanListUi from 'common/components/entities/OfferPriceNew/ui/PricePlanListUi'
import { PricePlanTypeEnum } from 'common/enums/PricePlanTypeEnum'
import { ResourceTypeEnum } from 'common/enums/PricingEnum'
import { Coupon as CouponType } from 'common/types/CouponType'
import {
  OneShotPlan,
  PricePlan as PricePlanType,
} from 'common/types/OfferInterface'
import { ProductInterface } from 'common/types/entities/ProductInterface'
import { SelectedShipping } from 'common/types/entities/ShippingFeesInterface'
import { OfferPricing } from 'common/types/pricingTypes'

export type OfferPriceProps = {
  attrId: string
  activePlanId?: number
  plans: PricePlanType[]
  pricePlansPricing: OfferPricing[]
  bumpPlan?: OneShotPlan
  bumpPlanPricing?: OfferPricing
  coupon?: CouponType
  quantity?: number
  isVatNotChargeable: boolean
  togglePricePlan?: (id: number) => void
  customDescription?: string
  locale: string
  mainOfferPricing?: OfferPricing
  bumpOfferPricing?: OfferPricing
  product?: ProductInterface | null
  productBump?: ProductInterface | null
  selectedShipping?: SelectedShipping | null
  nameFontFamily?: string
  mobileNameFontFamily?: string
  nameFontSize: number
  mobileNameFontSize?: number
  nameFontStyle?: string
  mobileNameFontStyle?: string
  nameFontWeight?: string
  mobileNameFontWeight?: string
  nameLineHeight: number
  mobileNameLineHeight?: number
  nameColor: string
  mobileNameColor?: string
  amountFontFamily?: string
  mobileAmountFontFamily?: string
  amountFontSize: number
  mobileAmountFontSize?: number
  amountFontStyle?: string
  mobileAmountFontStyle?: string
  amountFontWeight?: string
  mobileAmountFontWeight?: string
  amountLineHeight: number
  mobileAmountLineHeight?: number
  amountColor: string
  mobileAmountColor?: string
  descriptionFontFamily?: string
  mobileDescriptionFontFamily?: string
  descriptionFontSize: number
  mobileDescriptionFontSize?: number
  descriptionFontStyle?: string
  mobileDescriptionFontStyle?: string
  descriptionFontWeight?: string
  mobileDescriptionFontWeight?: string
  descriptionLineHeight: number
  mobileDescriptionLineHeight?: number
  descriptionColor: string
  mobileDescriptionColor?: string
}

export const isOneShot = (plan: PricePlanType) =>
  plan.type === PricePlanTypeEnum.OneShot

const OfferPrice = ({
  attrId,
  plans,
  coupon,
  bumpPlan,
  mainOfferPricing,
  bumpOfferPricing,
  pricePlansPricing,
  bumpPlanPricing,
  quantity = 1,
  activePlanId,
  selectedShipping,
  togglePricePlan,
  isVatNotChargeable,
  customDescription,
  locale,
  product,
  productBump,
  nameFontSize,
  mobileNameFontSize,
  nameFontStyle,
  mobileNameFontStyle,
  nameFontWeight,
  mobileNameFontWeight,
  nameFontFamily,
  mobileNameFontFamily,
  nameLineHeight,
  mobileNameLineHeight,
  nameColor,
  mobileNameColor,
  amountFontSize,
  mobileAmountFontSize,
  amountFontStyle,
  mobileAmountFontStyle,
  amountFontWeight,
  mobileAmountFontWeight,
  amountFontFamily,
  mobileAmountFontFamily,
  amountLineHeight,
  mobileAmountLineHeight,
  amountColor,
  mobileAmountColor,
  descriptionFontSize,
  mobileDescriptionFontSize,
  descriptionFontStyle,
  mobileDescriptionFontStyle,
  descriptionFontWeight,
  mobileDescriptionFontWeight,
  descriptionFontFamily,
  mobileDescriptionFontFamily,
  descriptionLineHeight,
  mobileDescriptionLineHeight,
  descriptionColor,
  mobileDescriptionColor,
}: OfferPriceProps) => {
  const activePricePlan = plans?.find(
    plan => plan.id === activePlanId,
  ) as PricePlanType

  const activePricePlanPricing = pricePlansPricing?.find(
    pricing =>
      pricing.entityId === activePricePlan.id &&
      pricing.entityType === ResourceTypeEnum.PricePlan,
  )

  const needOneShotsSummary =
    bumpPlan &&
    isOneShot(bumpPlan) &&
    activePricePlan &&
    isOneShot(activePricePlan)

  const isCombinationOfProductAndPricePlanBump =
    product && product.name && bumpPlan && isOneShot(bumpPlan)

  const isProductBumpSelected = !!(productBump && productBump.name)

  return (
    <div id={attrId} data-test-element="offer-price">
      <PricePlanListUi>
        {pricePlansPricing?.map((pricePlanPricing, index) => (
          <PricePlan
            key={pricePlanPricing.entityId}
            plan={plans[index]}
            quantity={quantity}
            coupon={coupon}
            pricePlanPricing={pricePlanPricing}
            isActive={pricePlanPricing.entityId === activePlanId}
            togglePricePlan={togglePricePlan}
            customDescription={customDescription}
            locale={locale}
            nameFontSize={nameFontSize}
            mobileNameFontSize={mobileNameFontSize}
            nameFontStyle={nameFontStyle}
            mobileNameFontStyle={mobileNameFontStyle}
            nameFontWeight={nameFontWeight}
            mobileNameFontWeight={mobileNameFontWeight}
            nameFontFamily={nameFontFamily}
            mobileNameFontFamily={mobileNameFontFamily}
            nameLineHeight={nameLineHeight}
            mobileNameLineHeight={mobileNameLineHeight}
            nameColor={nameColor}
            mobileNameColor={mobileNameColor}
            amountFontSize={amountFontSize}
            mobileAmountFontSize={mobileAmountFontSize}
            amountFontStyle={amountFontStyle}
            mobileAmountFontStyle={mobileAmountFontStyle}
            amountFontWeight={amountFontWeight}
            mobileAmountFontWeight={mobileAmountFontWeight}
            amountFontFamily={amountFontFamily}
            mobileAmountFontFamily={mobileAmountFontFamily}
            amountLineHeight={amountLineHeight}
            mobileAmountLineHeight={mobileAmountLineHeight}
            amountColor={amountColor}
            mobileAmountColor={mobileAmountColor}
            descriptionFontSize={descriptionFontSize}
            mobileDescriptionFontSize={mobileDescriptionFontSize}
            descriptionFontStyle={descriptionFontStyle}
            mobileDescriptionFontStyle={mobileDescriptionFontStyle}
            descriptionFontWeight={descriptionFontWeight}
            mobileDescriptionFontWeight={mobileDescriptionFontWeight}
            descriptionFontFamily={descriptionFontFamily}
            mobileDescriptionFontFamily={mobileDescriptionFontFamily}
            descriptionLineHeight={descriptionLineHeight}
            mobileDescriptionLineHeight={mobileDescriptionLineHeight}
            descriptionColor={descriptionColor}
            mobileDescriptionColor={mobileDescriptionColor}
            dataTestId={`offer-price-${pricePlanPricing.entityId}`}
          />
        ))}
      </PricePlanListUi>
      <Product
        mainOfferPricing={mainOfferPricing}
        product={product}
        coupon={coupon}
        nameFontSize={nameFontSize}
        mobileNameFontSize={mobileNameFontSize}
        nameFontStyle={nameFontStyle}
        mobileNameFontStyle={mobileNameFontStyle}
        nameFontWeight={nameFontWeight}
        mobileNameFontWeight={mobileNameFontWeight}
        nameFontFamily={nameFontFamily}
        mobileNameFontFamily={mobileNameFontFamily}
        nameLineHeight={nameLineHeight}
        mobileNameLineHeight={mobileNameLineHeight}
        nameColor={nameColor}
        mobileNameColor={mobileNameColor}
        amountFontSize={amountFontSize}
        mobileAmountFontSize={mobileAmountFontSize}
        amountFontStyle={amountFontStyle}
        mobileAmountFontStyle={mobileAmountFontStyle}
        amountFontWeight={amountFontWeight}
        mobileAmountFontWeight={mobileAmountFontWeight}
        amountFontFamily={amountFontFamily}
        mobileAmountFontFamily={mobileAmountFontFamily}
        amountLineHeight={amountLineHeight}
        mobileAmountLineHeight={mobileAmountLineHeight}
        amountColor={amountColor}
        mobileAmountColor={mobileAmountColor}
        descriptionFontSize={descriptionFontSize}
        mobileDescriptionFontSize={mobileDescriptionFontSize}
        descriptionFontStyle={descriptionFontStyle}
        mobileDescriptionFontStyle={mobileDescriptionFontStyle}
        descriptionFontWeight={descriptionFontWeight}
        mobileDescriptionFontWeight={mobileDescriptionFontWeight}
        descriptionFontFamily={descriptionFontFamily}
        mobileDescriptionFontFamily={mobileDescriptionFontFamily}
        descriptionLineHeight={descriptionLineHeight}
        mobileDescriptionLineHeight={mobileDescriptionLineHeight}
        descriptionColor={descriptionColor}
        mobileDescriptionColor={mobileDescriptionColor}
        isFinalSummary={!productBump && !selectedShipping}
      />
      {productBump && productBump.name && (
        <ProductBump
          bumpOfferPricing={bumpOfferPricing}
          skipLeftOffset={!activePricePlan}
          productBump={productBump}
          nameFontSize={nameFontSize}
          mobileNameFontSize={mobileNameFontSize}
          nameFontStyle={nameFontStyle}
          mobileNameFontStyle={mobileNameFontStyle}
          nameFontWeight={nameFontWeight}
          mobileNameFontWeight={mobileNameFontWeight}
          nameFontFamily={nameFontFamily}
          mobileNameFontFamily={mobileNameFontFamily}
          nameLineHeight={nameLineHeight}
          mobileNameLineHeight={mobileNameLineHeight}
          nameColor={nameColor}
          mobileNameColor={mobileNameColor}
          amountFontSize={amountFontSize}
          mobileAmountFontSize={mobileAmountFontSize}
          amountFontStyle={amountFontStyle}
          mobileAmountFontStyle={mobileAmountFontStyle}
          amountFontWeight={amountFontWeight}
          mobileAmountFontWeight={mobileAmountFontWeight}
          amountFontFamily={amountFontFamily}
          mobileAmountFontFamily={mobileAmountFontFamily}
          amountLineHeight={amountLineHeight}
          mobileAmountLineHeight={mobileAmountLineHeight}
          amountColor={amountColor}
          mobileAmountColor={mobileAmountColor}
        />
      )}
      {isProductBumpSelected && (
        <ProductBumpTotal
          productBump={productBump}
          activePricePlan={activePricePlan}
          activePlanPricing={activePricePlanPricing}
          mainOfferPricing={mainOfferPricing}
          bumpOfferPricing={bumpOfferPricing}
          quantity={quantity}
          coupon={coupon}
          locale={locale}
          amountFontSize={amountFontSize}
          mobileAmountFontSize={mobileAmountFontSize}
          amountFontStyle={amountFontStyle}
          mobileAmountFontStyle={mobileAmountFontStyle}
          amountFontWeight={amountFontWeight}
          mobileAmountFontWeight={mobileAmountFontWeight}
          amountFontFamily={amountFontFamily}
          mobileAmountFontFamily={mobileAmountFontFamily}
          amountLineHeight={amountLineHeight}
          mobileAmountLineHeight={mobileAmountLineHeight}
          amountColor={amountColor}
          mobileAmountColor={mobileAmountColor}
          descriptionFontSize={descriptionFontSize}
          mobileDescriptionFontSize={mobileDescriptionFontSize}
          descriptionFontStyle={descriptionFontStyle}
          mobileDescriptionFontStyle={mobileDescriptionFontStyle}
          descriptionFontWeight={descriptionFontWeight}
          mobileDescriptionFontWeight={mobileDescriptionFontWeight}
          descriptionFontFamily={descriptionFontFamily}
          mobileDescriptionFontFamily={mobileDescriptionFontFamily}
          descriptionLineHeight={descriptionLineHeight}
          mobileDescriptionLineHeight={mobileDescriptionLineHeight}
          descriptionColor={descriptionColor}
          mobileDescriptionColor={mobileDescriptionColor}
          isFinalSummary={!selectedShipping}
        />
      )}
      {bumpPlan && bumpPlanPricing && (
        <>
          <DividerUi />
          <BumpPricePlan
            nameFontSize={nameFontSize}
            mobileNameFontSize={mobileNameFontSize}
            nameFontStyle={nameFontStyle}
            mobileNameFontStyle={mobileNameFontStyle}
            nameFontWeight={nameFontWeight}
            mobileNameFontWeight={mobileNameFontWeight}
            nameFontFamily={nameFontFamily}
            mobileNameFontFamily={mobileNameFontFamily}
            nameLineHeight={nameLineHeight}
            mobileNameLineHeight={mobileNameLineHeight}
            nameColor={nameColor}
            mobileNameColor={mobileNameColor}
            amountFontSize={amountFontSize}
            mobileAmountFontSize={mobileAmountFontSize}
            amountFontStyle={amountFontStyle}
            mobileAmountFontStyle={mobileAmountFontStyle}
            amountFontWeight={amountFontWeight}
            mobileAmountFontWeight={mobileAmountFontWeight}
            amountFontFamily={amountFontFamily}
            mobileAmountFontFamily={mobileAmountFontFamily}
            amountLineHeight={amountLineHeight}
            mobileAmountLineHeight={mobileAmountLineHeight}
            amountColor={amountColor}
            mobileAmountColor={mobileAmountColor}
            descriptionFontSize={descriptionFontSize}
            mobileDescriptionFontSize={mobileDescriptionFontSize}
            descriptionFontStyle={descriptionFontStyle}
            mobileDescriptionFontStyle={mobileDescriptionFontStyle}
            descriptionFontWeight={descriptionFontWeight}
            mobileDescriptionFontWeight={mobileDescriptionFontWeight}
            descriptionFontFamily={descriptionFontFamily}
            mobileDescriptionFontFamily={mobileDescriptionFontFamily}
            descriptionLineHeight={descriptionLineHeight}
            mobileDescriptionLineHeight={mobileDescriptionLineHeight}
            descriptionColor={descriptionColor}
            mobileDescriptionColor={mobileDescriptionColor}
            plan={bumpPlan}
            dataTestId={`offer-price-bump-${bumpPlan.id}`}
            pricePlanPricing={bumpPlanPricing}
            locale={locale}
          />
        </>
      )}
      {bumpPlanPricing && isCombinationOfProductAndPricePlanBump && (
        <ProductAndPricePlanBumpTotal
          product={product}
          bumpPlan={bumpPlan}
          productPricing={mainOfferPricing}
          bumpPlanPricing={bumpPlanPricing}
          locale={locale}
          amountFontSize={amountFontSize}
          mobileAmountFontSize={mobileAmountFontSize}
          amountFontStyle={amountFontStyle}
          mobileAmountFontStyle={mobileAmountFontStyle}
          amountFontWeight={amountFontWeight}
          mobileAmountFontWeight={mobileAmountFontWeight}
          amountFontFamily={amountFontFamily}
          mobileAmountFontFamily={mobileAmountFontFamily}
          amountLineHeight={amountLineHeight}
          mobileAmountLineHeight={mobileAmountLineHeight}
          amountColor={amountColor}
          mobileAmountColor={mobileAmountColor}
          descriptionFontSize={descriptionFontSize}
          mobileDescriptionFontSize={mobileDescriptionFontSize}
          descriptionFontStyle={descriptionFontStyle}
          mobileDescriptionFontStyle={mobileDescriptionFontStyle}
          descriptionFontWeight={descriptionFontWeight}
          mobileDescriptionFontWeight={mobileDescriptionFontWeight}
          descriptionFontFamily={descriptionFontFamily}
          mobileDescriptionFontFamily={mobileDescriptionFontFamily}
          descriptionLineHeight={descriptionLineHeight}
          mobileDescriptionLineHeight={mobileDescriptionLineHeight}
          descriptionColor={descriptionColor}
          mobileDescriptionColor={mobileDescriptionColor}
        />
      )}
      {needOneShotsSummary && (
        <>
          <DividerUi />
          <OneShotsSummary
            plan={activePricePlan}
            bumpPlan={bumpPlan}
            activePlanPricing={activePricePlanPricing}
            bumpPlanPricing={bumpPlanPricing}
            coupon={coupon}
            quantity={quantity}
            isVatNotChargeable={isVatNotChargeable}
            locale={locale}
            amountFontSize={amountFontSize}
            mobileAmountFontSize={mobileAmountFontSize}
            amountFontStyle={amountFontStyle}
            mobileAmountFontStyle={mobileAmountFontStyle}
            amountFontWeight={amountFontWeight}
            mobileAmountFontWeight={mobileAmountFontWeight}
            amountFontFamily={amountFontFamily}
            mobileAmountFontFamily={mobileAmountFontFamily}
            amountLineHeight={amountLineHeight}
            mobileAmountLineHeight={mobileAmountLineHeight}
            amountColor={amountColor}
            mobileAmountColor={mobileAmountColor}
            descriptionFontSize={descriptionFontSize}
            mobileDescriptionFontSize={mobileDescriptionFontSize}
            descriptionFontStyle={descriptionFontStyle}
            mobileDescriptionFontStyle={mobileDescriptionFontStyle}
            descriptionFontWeight={descriptionFontWeight}
            mobileDescriptionFontWeight={mobileDescriptionFontWeight}
            descriptionFontFamily={descriptionFontFamily}
            mobileDescriptionFontFamily={mobileDescriptionFontFamily}
            descriptionLineHeight={descriptionLineHeight}
            mobileDescriptionLineHeight={mobileDescriptionLineHeight}
            descriptionColor={descriptionColor}
            mobileDescriptionColor={mobileDescriptionColor}
          />
        </>
      )}
      {selectedShipping && (
        <ShippingSummary
          bumpOfferPricing={bumpOfferPricing}
          productPricing={mainOfferPricing}
          selectedShipping={selectedShipping}
          locale={locale}
          amountFontSize={amountFontSize}
          mobileAmountFontSize={mobileAmountFontSize}
          amountFontStyle={amountFontStyle}
          mobileAmountFontStyle={mobileAmountFontStyle}
          amountFontWeight={amountFontWeight}
          mobileAmountFontWeight={mobileAmountFontWeight}
          amountFontFamily={amountFontFamily}
          mobileAmountFontFamily={mobileAmountFontFamily}
          amountLineHeight={amountLineHeight}
          mobileAmountLineHeight={mobileAmountLineHeight}
          amountColor={amountColor}
          mobileAmountColor={mobileAmountColor}
          descriptionFontSize={descriptionFontSize}
          mobileDescriptionFontSize={mobileDescriptionFontSize}
          descriptionFontStyle={descriptionFontStyle}
          mobileDescriptionFontStyle={mobileDescriptionFontStyle}
          descriptionFontWeight={descriptionFontWeight}
          mobileDescriptionFontWeight={mobileDescriptionFontWeight}
          descriptionFontFamily={descriptionFontFamily}
          mobileDescriptionFontFamily={mobileDescriptionFontFamily}
          descriptionLineHeight={descriptionLineHeight}
          mobileDescriptionLineHeight={mobileDescriptionLineHeight}
          descriptionColor={descriptionColor}
          mobileDescriptionColor={mobileDescriptionColor}
        />
      )}
    </div>
  )
}

export default OfferPrice
