import axios from 'axios'

export const twoStepOptIn = (
  pageId,
  fields,
  datetime,
  timeZone,
  popupId,
  isDesktop,
  twoStepOptInEntityId,
  checkBoxIds,
  recaptchaToken,
) => {
  return axios.post(`/secure/payment/page/${pageId}/two-step-payment-opt-in`, {
    optin: {
      fields,
      datetime,
      timeZone,
      popupId,
      isDesktop,
      twoStepOptInEntityId,
      checkBoxIds,
      captcha: recaptchaToken,
    },
  })
}

export const checkAndFetchCoupon = (funnelStepId, coupon) => {
  return axios.post(
    `/secure/payment/funnel-step/${funnelStepId}/check-coupon`,
    {
      coupon,
    },
  )
}
