import { combineReducers } from 'redux'
import page from './pageReducer'
import management from './managementReducer'
import optIn from './optInReducer'
import webinar from './webinarReducer'
import webinarSession from './webinarSession'
import files from './filesReducer'
import payment from './paymentReducer'
import offerThankYou from './offerThankYouReducer'
import lecture from './lectureReducer'
import blog from './blogReducer'

const rootReducer = combineReducers({
  page,
  files,
  management,
  optIn,
  webinar,
  webinarSession,
  payment,
  offerThankYou,
  lecture,
  blog,
})

export default rootReducer
