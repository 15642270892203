import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  getPaymentMethodTitle,
  creditCardMethodTypes,
  isPaymentMethodTranslatable,
} from 'common/components/entities/PaymentMethodNew/utils/creditCardsUtils'
import { PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'
import { CommonTextPropsInterface } from 'common/types/CommonTextPropsInterface'
import PaymentMethodSwitcherImage from './PaymentMethodSwitcherImage'
import InputRadioUi from './ui/InputRadioUi'
import PaymentMethodLabelUi from './ui/PaymentMethodLabelUi'
import PaymentMethodSwitcherCreditCardUi from './ui/PaymentMethodSwitcherCreditCardUi'
import PaymentMethodSwitcherItemUi from './ui/PaymentMethodSwitcherItemUi'
import PaymentMethodUi from './ui/PaymentMethodUi'
import getPaymentMethodName from './utils/getPaymentMethodName'

export interface PaymentMethodSwitcherPropsInterface
  extends CommonTextPropsInterface {
  paymentMethods: PaymentMethodEnum[]
  activeMethod: PaymentMethodEnum | null
  changeMethod?: (method: PaymentMethodEnum) => void
  renderDetails?: (method: PaymentMethodEnum) => JSX.Element | undefined
  locale?: string
  attrId: string
}

const isCreditCardMethod = (method: PaymentMethodEnum) => {
  return creditCardMethodTypes.includes(method)
}

function PaymentMethod({
  paymentMethods,
  activeMethod,
  changeMethod,
  fontSize,
  mobileFontSize,
  lineHeight,
  mobileLineHeight,
  fontWeight,
  fontStyle,
  fontFamily,
  mobileFontStyle,
  mobileFontWeight,
  mobileFontFamily,
  color,
  mobileColor,
  renderDetails,
  attrId,
}: PaymentMethodSwitcherPropsInterface) {
  const { t } = useTranslation('common')
  return (
    <PaymentMethodUi id={attrId}>
      {paymentMethods.map(method => (
        <React.Fragment key={method}>
          <PaymentMethodSwitcherItemUi
            onClick={() => changeMethod && changeMethod(method)}
            data-test-id={`payment-method-${method}`}
          >
            <InputRadioUi checked={activeMethod === method} />
            <PaymentMethodLabelUi
              fontSize={fontSize}
              mobileFontSize={mobileFontSize}
              lineHeight={lineHeight}
              mobileLineHeight={mobileLineHeight}
              fontFamily={fontFamily}
              fontWeight={fontWeight}
              fontStyle={fontStyle}
              mobileFontStyle={mobileFontStyle}
              mobileFontWeight={mobileFontWeight}
              mobileFontFamily={mobileFontFamily}
              color={color}
              mobileColor={mobileColor}
            >
              {isPaymentMethodTranslatable(method)
                ? getPaymentMethodTitle(method, t)
                : getPaymentMethodName(method)}
            </PaymentMethodLabelUi>
            {isCreditCardMethod(method) ? (
              <PaymentMethodSwitcherCreditCardUi />
            ) : (
              <PaymentMethodSwitcherImage method={method} />
            )}
          </PaymentMethodSwitcherItemUi>
          {renderDetails && activeMethod === method && renderDetails(method)}
        </React.Fragment>
      ))}
    </PaymentMethodUi>
  )
}

export default PaymentMethod
