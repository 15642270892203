import styled from 'styled-components'

const CreditCardInputWrapperUi = styled.label<{
  hasErrors?: boolean
  flex?: boolean
}>`
  width: 100%;
  font-size: 14px;
  color: #8b929c;
  border: 1px solid #e4e8ee;
  border-radius: 5px;
  padding: 18px 23px;
  background-color: #fff;
  ${p => p.flex && `display: flex;`}
  ${p => p.hasErrors && `border-color: red;`}
`

export default CreditCardInputWrapperUi
