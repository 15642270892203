import styled from 'styled-components'

type PricePlanColumnUiProps = {
  align?: string
}
const PricePlanColumnUi = styled.div<PricePlanColumnUiProps>`
  flex-basis: 50%;
  text-align: ${({ align }) => align || 'left'};
`

export default PricePlanColumnUi
