import styled, { css } from 'styled-components'
import {
  fontCss,
  mobileFontCss,
  mobileTextCss,
  textCss,
  TextProps,
} from 'common/utils/styleUtilsNew'

const ForgotPasswordUi = styled.a<TextProps>`
  ${fontCss}
  ${textCss}
  ${p => p.theme.phone} {
    ${mobileFontCss}
    ${mobileTextCss}
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileFontCss}
      ${mobileTextCss}
    `}
`

export default ForgotPasswordUi
