import styled from 'styled-components'

const ProductLabelUi = styled.div`
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 2px;
  color: #8196ab;
`

export default ProductLabelUi
