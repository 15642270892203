import styled from 'styled-components'

const PricePlanLabelUi = styled.label`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
`

export default PricePlanLabelUi
