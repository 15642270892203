import { CurrencyEnum } from 'common/types/CurrencyType';

export const mockedPhysicalProduct = {
  id: 28,
  name: 'Product name',
  tax: 2,
  sku: 'SHI-418',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.',
  images: [
    {
      id: 123123123,
      path: 'https://d1yei2z3i6k35z.cloudfront.net/common/Product_placeholder_image.png',
    },
    {
      id: 123123125,
      path: 'https://d1yei2z3i6k35z.cloudfront.net/common/Product_placeholder_image.png',
    },
    {
      id: 123123126,
      path: 'https://d1yei2z3i6k35z.cloudfront.net/common/Product_placeholder_image.png',
    },
    {
      id: 123123127,
      path: 'https://d1yei2z3i6k35z.cloudfront.net/common/Product_placeholder_image.png',
    },
    {
      id: 123123128,
      path: 'https://d1yei2z3i6k35z.cloudfront.net/common/Product_placeholder_image.png',
    },
  ],
  currency: CurrencyEnum.US_DOLLAR,
  price: 213,
  options: [
    {
      id: '01GFNGV0AJBFAT3RBJK8HB7SH3',
      name: 'Size',
      optionValues: [
        {
          id: '01GFNGV0AJBMYQCQGFAK7X3M7H',
          value: 'Small',
        },
        {
          id: '01GFNGV6BSWC7Z8ZZTQ81E61NK',
          value: 'Medium',
        },
        {
          id: '01GFNGV72GARQX9MTTCXYW1C15',
          value: 'Large',
        },
      ],
    },
    {
      id: '01GFNGVE6301RXNEWYCNPGSRE9',
      name: 'Color',
      optionValues: [
        {
          id: '01GFNGVE63D2A3DK50Q96BKA21',
          value: 'Blue',
        },
        {
          id: '01GFNGVHZN8NVYYZDPKGV5HA7P',
          value: 'Red',
        },
        {
          id: '01GFNGVM6A4YDQTA9N3CH127WH',
          value: 'Green',
        },
      ],
    },
  ],
  productVariants: [
    {
      id: '01GFNGVK30XXXR3TRBFYC45PFC',
      optionValueIds: [
        '01GFNGV0AJBMYQCQGFAK7X3M7H',
        '01GFNGVHZN8NVYYZDPKGV5HA7P',
      ],
      image: {
        id: 123123123,
        path: 'https://d1yei2z3i6k35z.cloudfront.net/common/Product_placeholder_image.png',
      },
      productOptionValues: [
        {
          id: '01GFNGV0AJBMYQCQGFAK7X3M7H',
          value: 'Small',
        },
        {
          id: '01GFNGVHZN8NVYYZDPKGV5HA7P',
          value: 'Red',
        },
      ],
      sku: '',
      price: undefined,
    },
    {
      id: '01GFNGVPSZD8ZMR7CM2BVNS595',
      optionValueIds: [
        '01GFNGV0AJBMYQCQGFAK7X3M7H',
        '01GFNGVM6A4YDQTA9N3CH127WH',
      ],
      productOptionValues: [
        {
          id: '01GFNGV0AJBMYQCQGFAK7X3M7H',
          value: 'Small',
        },
        {
          id: '01GFNGVM6A4YDQTA9N3CH127WH',
          value: 'Green',
        },
      ],
      sku: '',
      price: undefined,
    },
    {
      id: '01GFNGV73CCZHN8CYJ295865Z4',
      optionValueIds: [
        '01GFNGV6BSWC7Z8ZZTQ81E61NK',
        '01GFNGVE63D2A3DK50Q96BKA21',
      ],
      productOptionValues: [
        {
          id: '01GFNGV6BSWC7Z8ZZTQ81E61NK',
          value: 'Medium',
        },
        {
          id: '01GFNGVE63D2A3DK50Q96BKA21',
          value: 'Blue',
        },
      ],
      sku: '',
      price: undefined,
    },
    {
      id: '01GFNGVK30RWGWQ3PAYR08TMQA',
      optionValueIds: [
        '01GFNGV6BSWC7Z8ZZTQ81E61NK',
        '01GFNGVHZN8NVYYZDPKGV5HA7P',
      ],
      productOptionValues: [
        {
          id: '01GFNGV6BSWC7Z8ZZTQ81E61NK',
          value: 'Medium',
        },
        {
          id: '01GFNGVHZN8NVYYZDPKGV5HA7P',
          value: 'Red',
        },
      ],
      sku: '',
      price: undefined,
    },
    {
      id: '01GFNGVPT0ZH2T84H2R43K3R7B',
      optionValueIds: [
        '01GFNGV6BSWC7Z8ZZTQ81E61NK',
        '01GFNGVM6A4YDQTA9N3CH127WH',
      ],
      productOptionValues: [
        {
          id: '01GFNGV6BSWC7Z8ZZTQ81E61NK',
          value: 'Medium',
        },
        {
          id: '01GFNGVM6A4YDQTA9N3CH127WH',
          value: 'Green',
        },
      ],
      sku: '',
      price: 214,
    },
    {
      id: '01GFNGVK30MPTAVFA68BZWH432',
      optionValueIds: [
        '01GFNGV72GARQX9MTTCXYW1C15',
        '01GFNGVHZN8NVYYZDPKGV5HA7P',
      ],
      productOptionValues: [
        {
          id: '01GFNGV72GARQX9MTTCXYW1C15',
          value: 'Large',
        },
        {
          id: '01GFNGVHZN8NVYYZDPKGV5HA7P',
          value: 'Red',
        },
      ],
      sku: '',
      price: 215,
    },
    {
      id: '01GFNGVPT0NEPBMXD4S9DHCPN0',
      optionValueIds: [
        '01GFNGV72GARQX9MTTCXYW1C15',
        '01GFNGVM6A4YDQTA9N3CH127WH',
      ],
      productOptionValues: [
        {
          id: '01GFNGV72GARQX9MTTCXYW1C15',
          value: 'Large',
        },
        {
          id: '01GFNGVM6A4YDQTA9N3CH127WH',
          value: 'Green',
        },
      ],
      sku: '',
      price: 216,
    },
  ],
}