import { TFunction, useTranslation } from 'react-i18next'
import { PricePlanTypeEnum } from 'common/enums/PricePlanTypeEnum'
import { OneShotPlan } from 'common/types/OfferInterface'
import { useManagement, usePayment } from 'publisher/store'
import managementSelectors from 'publisher/store/management/managementSelectors'
import {
  getCheckedPricePlanId,
  getPricePlans,
} from 'publisher/store/payment/paymentSelectors'

const getPricePlanExample = (t: TFunction): OneShotPlan => ({
  id: 1,
  name: t('entities.offer_price.stub.name'),
  currency: 'eur',
  type: PricePlanTypeEnum.OneShot,
  directChargeAmount: 4750,
})

export const useCalculatedPricePlans = () => {
  const { t } = useTranslation()
  const pricePlans = usePayment(getPricePlans)
  const activePlanId = usePayment(getCheckedPricePlanId)
  const isScreenshotMode = useManagement(managementSelectors.isScreenshotMode)

  if (isScreenshotMode) {
    const pricePlanExample = getPricePlanExample(t)
    return { pricePlans: [pricePlanExample], activePlanId: pricePlanExample.id }
  }

  return { pricePlans, activePlanId }
}
