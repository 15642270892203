import { useState } from 'react'

export type DefaultFieldsType = Record<string, string[]>

export function useCardErrors<T = DefaultFieldsType>(defaultErrors: T) {
  const [errors, setErrors] = useState<T>(defaultErrors)

  function resetError(key: keyof T) {
    setErrors(errors => ({
      ...errors,
      [key]: '',
    }))
  }

  function addError(key: keyof T, message: string) {
    setErrors(errors => ({
      ...errors,
      [key]: message,
    }))
  }

  function resetErrors() {
    setErrors(() => defaultErrors)
  }

  return { resetError, resetErrors, addError, errors }
}
