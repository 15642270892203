import React from 'react'
import { useTranslation } from 'react-i18next'
import CardFieldUi from 'common/components/entities/PaymentMethods/ui/CardFieldUi'
import CardLabelUi from 'common/components/entities/PaymentMethods/ui/CardLabelUi'
import CardRowUi from 'common/components/entities/PaymentMethods/ui/CardRowUi'
import FieldErrors from 'publisher/components/FieldErrors'
import { CustomTaxNumberOptionsKeys } from 'publisher/pages/offer-page/constants/custom-tax-number-options'
import { useCustomTaxNumber } from 'publisher/pages/offer-page/hooks/useCustomTaxNumber'
import CreditCardInputUi from './ui/CreditCardInputUi'
import CreditCardInputWrapperUi from './ui/CreditCardInputWrapperUi'

interface PixOrBoletoTaxNumberProps {
  id: string
  type: CustomTaxNumberOptionsKeys
}
function PixOrBoletoTaxNumber({ id, type }: PixOrBoletoTaxNumberProps) {
  const taxNumberOptions = useCustomTaxNumber(type)

  const { t } = useTranslation(['common', 'publisher'])

  function handleTaxNumberChange(event: React.ChangeEvent<HTMLInputElement>) {
    taxNumberOptions.change(event.target.value)
  }
  return (
    <CardRowUi>
      <CardFieldUi>
        <CardLabelUi htmlFor={id}>
          {t('components.payment_methods.identification_number')}
        </CardLabelUi>
        <CreditCardInputWrapperUi
          hasErrors={taxNumberOptions.errors.length > 0}
        >
          <CreditCardInputUi
            value={taxNumberOptions.value}
            onChange={handleTaxNumberChange}
          />
        </CreditCardInputWrapperUi>
        {taxNumberOptions.errors && (
          <FieldErrors errors={taxNumberOptions.errors} />
        )}
      </CardFieldUi>
    </CardRowUi>
  )
}

export default PixOrBoletoTaxNumber
