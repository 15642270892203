import React from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import { wrapperStyles } from 'common/components/entities/Button'
import CommonButton from 'common/components/entities/Button/Button'
import paymentMethodTypes from 'common/constants/paymentMethodTypes'
import { PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'
import { UpsellButtonInterface } from 'common/types/entities/ButtonInterface'
import useManagement, { selectors } from 'publisher/hooks/useManagement'
import usePage, { selectors as pageSelectors } from 'publisher/hooks/usePage'
import usePayment, {
  selectors as paymentSelectors,
} from 'publisher/hooks/usePayment'
import CashOnDeliveryUpsellButton from './CashOnDeliveryUpsellButton'
import DLocalCreditCardUpsellButton from './DLocalCreditCardUpsellButton'
import FlutterwaveUpsellButton from './FlutterwaveUpsellButton'
import GocardlessUpsellButton from './GocardlessUpsellButton'
import MercadoPagoUpsellButton from './MercadoPagoUpsellButton'
import MollieUpsellButton from './MollieUpsellButton'
import PaypalUpsellButton from './PaypalUpsellButton'
import PaystackUpsellButton from './PaystackUpsellButton'
import RazorpayUpsellButton from './RazorpayUpsellButton'
import SecureAndPayUpsellButton from './SecureAndPayUpsellButton'
import StripeCreditCardUpsellButton from './StripeCreditCardUpsellButton'
import StripeSepaDebitUpsellButton from './StripeSepaDebitUpsellButton'
import XenditUpsellButton from './XenditUpsellButton'

export type UpsellPaymentButtonPropsType = {
  entity: UpsellButtonInterface
}

function UpsellPaymentButton({ entity }: UpsellPaymentButtonPropsType) {
  const isScreenshotMode = useManagement(selectors.isScreenshotMode)
  const paymentMethod = usePayment(paymentSelectors.getActivePaymentMethod)
  const isSsr = typeof window === 'undefined'
  const isPreview = useManagement(selectors.isPreviewMode)
  const isTemplate = usePage(pageSelectors.isTemplate)

  if (isScreenshotMode || isSsr || isPreview || isTemplate) {
    return (
      <StyleWrapper
        styles={{ ...entity.margin, ...wrapperStyles }}
        mobileStyles={entity.mobileMargin}
        desktop={entity.appearance.desktop}
        mobile={entity.appearance.mobile}
      >
        <CommonButton
          text={entity.text}
          border={entity.border}
          mobileBorder={entity.mobileBorder}
          subText={entity.subText}
          padding={entity.padding}
          mobilePadding={entity.mobilePadding}
          alignSelf={entity.alignSelf}
          backgroundColor={entity.backgroundColor}
          mobileBackgroundColor={entity.mobileBackgroundColor}
          attrId={entity.htmlAttrId}
          width={entity.width}
          mobileWidth={entity.mobileWidth}
          textColor={entity.textColor}
          subTextColor={entity.subTextColor}
          mobileTextColor={entity.mobileTextColor}
          mobileSubTextColor={entity.mobileSubTextColor}
          textFontSize={entity.textFontSize}
          mobileTextFontSize={entity.mobileTextFontSize}
          subTextFontSize={entity.subTextFontSize}
          mobileSubTextFontSize={entity.mobileSubTextFontSize}
          textFontFamily={entity.textFontFamily}
          textFontWeight={entity.textFontWeight}
          textFontStyle={entity.textFontStyle}
          subTextFontFamily={entity.subTextFontFamily}
          subTextFontWeight={entity.subTextFontWeight}
          subTextFontStyle={entity.subTextFontStyle}
          mobileTextFontFamily={entity.mobileTextFontFamily}
          mobileTextFontWeight={entity.mobileTextFontWeight}
          mobileTextFontStyle={entity.mobileTextFontStyle}
          mobileSubTextFontFamily={entity.mobileSubTextFontFamily}
          mobileSubTextFontWeight={entity.mobileSubTextFontWeight}
          mobileSubTextFontStyle={entity.mobileSubTextFontStyle}
          boxShadow={entity.boxShadow}
          mobileBoxShadow={entity.mobileBoxShadow}
          lineHeight={entity.lineHeight}
          mobileLineHeight={entity.mobileLineHeight}
          iconClassNameBefore={entity.iconClassNameBefore}
          iconClassNameAfter={entity.iconClassNameAfter}
          hover={entity.hover}
          mobileHover={entity.mobileHover}
        />
      </StyleWrapper>
    )
  }

  switch (paymentMethod) {
    case paymentMethodTypes.RAZORPAY:
      return <RazorpayUpsellButton entity={entity} />
    case paymentMethodTypes.PAYPAL_SOAP:
      return <PaypalUpsellButton entity={entity} />
    case paymentMethodTypes.STRIPE_CARD:
      return <StripeCreditCardUpsellButton entity={entity} />
    case paymentMethodTypes.STRIPE_IDEAL:
    case paymentMethodTypes.STRIPE_BANCONTACT:
      return <StripeSepaDebitUpsellButton entity={entity} />
    case paymentMethodTypes.DLOCAL_CARD:
      return <DLocalCreditCardUpsellButton entity={entity} />
    case paymentMethodTypes.FLUTTERWAVE:
      return <FlutterwaveUpsellButton entity={entity} />
    case paymentMethodTypes.MOLLIE:
      return <MollieUpsellButton entity={entity} />
    case paymentMethodTypes.GOCARDLESS:
      return <GocardlessUpsellButton entity={entity} />
    case paymentMethodTypes.XENDITIDCARD:
    case paymentMethodTypes.XENDITPHCARD:
      return <XenditUpsellButton entity={entity} />
    case paymentMethodTypes.CASH_ON_DELIVERY:
      return <CashOnDeliveryUpsellButton entity={entity} />
    case paymentMethodTypes.PAYSTACK_GH_CARD:
    case paymentMethodTypes.PAYSTACK_KE_CARD:
    case paymentMethodTypes.PAYSTACK_NG_CARD:
    case paymentMethodTypes.PAYSTACK_ZA_CARD:
    case paymentMethodTypes.PAYSTACK_EG_CARD:
    case paymentMethodTypes.PAYSTACK_CI_CARD:
      return <PaystackUpsellButton entity={entity} />
    case PaymentMethodEnum.MercadoPagoCard:
    case PaymentMethodEnum.MercadoPagoBrCard:
    case PaymentMethodEnum.MercadoPagoArCard:
    case PaymentMethodEnum.MercadoPagoClCard:
    case PaymentMethodEnum.MercadoPagoMxCard:
    case PaymentMethodEnum.MercadoPagoCoCard:
    case PaymentMethodEnum.MercadoPagoPix:
    case PaymentMethodEnum.MercadoPagoBrPix:
    case PaymentMethodEnum.MercadoPagoBoleto:
    case PaymentMethodEnum.MercadoPagoBrBoleto:
      return <MercadoPagoUpsellButton entity={entity} />
    case PaymentMethodEnum.SecureAndPay:
      return <SecureAndPayUpsellButton entity={entity} />
    default:
      // payment method not supported
      return <div />
  }
}

export default UpsellPaymentButton
