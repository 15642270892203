import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DialogContainer from './DialogContainer'
import DialogOverlay from './DialogOverlay'

const StyledDialog = styled.div`
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  display: inline-block;
  flex: ${p => `0 1 ${p.small ? 570 : p.width}px`};
  margin: auto;
  padding: 0;
  font-size: 16px;
`

const Dialog = ({ show, children, close, small, width, ...rest }) => {
  const onClick = ({ target, currentTarget }) => {
    if (target === currentTarget && close) {
      close()
    }
  }

  return (
    show &&
    ReactDOM.createPortal(
      <DialogOverlay show={show} onClick={onClick} {...rest}>
        <DialogContainer show={show} onClick={onClick} width={width}>
          <StyledDialog small={small} width={width}>
            {children}
          </StyledDialog>
        </DialogContainer>
      </DialogOverlay>,
      document.body,
    )
  )
}

Dialog.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool,
  small: PropTypes.bool,
  close: PropTypes.func,
}

Dialog.defaultProps = {
  show: false,
  small: false,
  close: null,
  width: 810,
}

export default Dialog
