/*
 todo: IDS defined in the context publisher/context/mercadoPagoContext.tsx, after payment method migration we can rename these ids and use a single source
 */

export const mercadoPagoCardNumberId = 'mercado-pago-card-number'

export const mercadoPagoCardExpirationId = 'mercado-pago-card-expiration'

export const mercadoPagoCardIdentificationNumberId =
  'mercado-pago-card-identification-number-id'

export const mercadoPagoCardHolderNameId = 'mercado-pago-card-holder-name'

export const mercadoPagoCardSecurityCodeId = 'mercado-pago-card-security_code'

export const mercadoPagoCardIssuerId = 'form-checkout__issuer' // defined mercadoPagoContext
