import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'
import { PaymentMethodUi } from 'common/components/entities/PaymentMethods'
import { PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'
import { extractMarginFromOldStyles } from 'common/utils/styleUtilsNew'
import { togglePaymentMethod } from 'publisher/actions/paymentActions'
import usePayment, { selectors } from '../../../hooks/usePayment'
import PaymentMethodSwitcher from './PaymentMethodSwitcher'

function PaymentMethod({ entity }) {
  const dispatch = useDispatch()
  const paymentMethods = usePayment(selectors.getPaymentMethods)
  const activeMethod = usePayment(selectors.getActivePaymentMethod)
  function changeMethod(method) {
    dispatch(togglePaymentMethod(method))
  }

  const margin = extractMarginFromOldStyles(entity.styles)
  const mobileMargin = extractMarginFromOldStyles(entity.mobileStyles)

  return (
    <PaymentMethodUi
      margin={margin}
      mobileMargin={mobileMargin}
      attrId={entity.options.attrId}
    >
      <PaymentMethodSwitcher
        changeMethod={changeMethod}
        paymentMethods={paymentMethods.filter(pm =>
          Object.values(PaymentMethodEnum).includes(pm),
        )}
        activeMethod={activeMethod}
        fontSize={entity.styles.fontSize}
        mobileFontSize={entity.mobileStyles.fontSize}
        lineHeight={entity.styles.lineHeight}
        mobileLineHeight={entity.mobileStyles.lineHeight}
        fontFamily={entity.styles.fontFamily}
        fontWeight={entity.styles.fontWeight}
        fontStyle={entity.styles.fontStyle}
        mobileFontStyle={entity.mobileStyles.fontStyle}
        mobileFontFamily={entity.mobileStyles.fontFamily}
        mobileFontWeight={entity.mobileStyles.fontWeight}
        color={entity.styles.color}
        mobileColor={entity.mobileStyles.color}
      />
    </PaymentMethodUi>
  )
}

PaymentMethod.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    options: PropTypes.shape({
      attrId: PropTypes.string,
    }).isRequired,
    styles: PropTypes.shape({
      marginTop: PropTypes.string,
      marginRight: PropTypes.string,
      marginBottom: PropTypes.string,
      marginLeft: PropTypes.string,
      fontSize: PropTypes.number,
      lineHeight: PropTypes.string,
      fontFamily: PropTypes.string,
      fontWeight: PropTypes.string,
      fontStyle: PropTypes.string,
      color: PropTypes.string,
    }).isRequired,
    mobileStyles: PropTypes.shape({
      marginTop: PropTypes.string,
      marginRight: PropTypes.string,
      marginBottom: PropTypes.string,
      marginLeft: PropTypes.string,
      fontSize: PropTypes.number,
      lineHeight: PropTypes.string,
      fontFamily: PropTypes.string,
      fontWeight: PropTypes.string,
      fontStyle: PropTypes.string,
      color: PropTypes.string,
    }),
  }).isRequired,
}

export default PaymentMethod
