import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'
import FieldErrors from 'publisher/components/FieldErrors'
import { useDLocal } from 'publisher/context/DlocalContext'
import { useCustomTaxNumber } from 'publisher/pages/offer-page/hooks/useCustomTaxNumber'
import { usePage } from 'publisher/store'
import { getTaxNumberFieldEntity } from 'publisher/store/page/pageSelectors'
import CreditCardFieldUi from '../../shared/ui/CreditCardFieldUi'
import CreditCardInputUi from '../../shared/ui/CreditCardInputUi'
import CreditCardInputWrapperUi from '../../shared/ui/CreditCardInputWrapperUi'
import CreditCardLabelUi from '../../shared/ui/CreditCardLabelUi'
import CreditCardRowUi from '../../shared/ui/CreditCardRowUi'
import {
  dLocalCardExpirationId,
  dLocalCardIdentificationNumberId,
  dLocalCardNumberId,
  dLocalCardSecurityCodeId,
} from './constants/creditCardFieldIds'

function CreditCard() {
  const { t } = useTranslation('common')
  const dLocal = useDLocal()
  const taxNumberOptions = useCustomTaxNumber(PaymentMethodEnum.DlocalCard)

  function handleTaxNumberChange(event: React.ChangeEvent<HTMLInputElement>) {
    taxNumberOptions.change(event.target.value)
  }
  const taxNumberField = usePage(getTaxNumberFieldEntity)

  useEffect(() => {
    if (dLocal) {
      const cardNumberElement = document.getElementById(dLocalCardNumberId)
      if (cardNumberElement) {
        dLocal.pan.mount(cardNumberElement)
      }
      const expirationElement = document.getElementById(dLocalCardExpirationId)
      if (expirationElement) {
        dLocal.expiration.mount(expirationElement)
      }
      const cardCvvElement = document.getElementById(dLocalCardSecurityCodeId)
      if (cardCvvElement) {
        dLocal.cvv.mount(cardCvvElement)
      }
    }
  }, [dLocal])

  return (
    <>
      <CreditCardRowUi>
        <CreditCardFieldUi>
          <CreditCardLabelUi htmlFor={dLocalCardNumberId}>
            {t('components.payment_methods.card_number')}
          </CreditCardLabelUi>
          <CreditCardInputWrapperUi id={dLocalCardNumberId} />
        </CreditCardFieldUi>
      </CreditCardRowUi>
      <CreditCardRowUi>
        <CreditCardFieldUi width="50%">
          <CreditCardLabelUi htmlFor={dLocalCardExpirationId}>
            {t('components.payment_methods.expiration')}
          </CreditCardLabelUi>
          <CreditCardInputWrapperUi id={dLocalCardExpirationId} />
        </CreditCardFieldUi>
        <CreditCardFieldUi width="50%">
          <CreditCardLabelUi htmlFor={dLocalCardSecurityCodeId}>
            {t('components.payment_methods.cvc')}
          </CreditCardLabelUi>
          <CreditCardInputWrapperUi id={dLocalCardSecurityCodeId} />
        </CreditCardFieldUi>
      </CreditCardRowUi>
      <CreditCardRowUi>
        {!taxNumberField && (
          <CreditCardFieldUi>
            <CreditCardLabelUi htmlFor={dLocalCardIdentificationNumberId}>
              {t('components.payment_methods.identification_number')}
            </CreditCardLabelUi>
            <CreditCardInputWrapperUi
              hasErrors={taxNumberOptions.errors.length > 0}
            >
              <CreditCardInputUi
                max={14}
                id={dLocalCardIdentificationNumberId}
                value={taxNumberOptions.value}
                onChange={handleTaxNumberChange}
              />
            </CreditCardInputWrapperUi>
            {taxNumberOptions.errors && (
              <FieldErrors errors={taxNumberOptions.errors} />
            )}
          </CreditCardFieldUi>
        )}
      </CreditCardRowUi>
    </>
  )
}

export default CreditCard
