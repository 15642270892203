import { TFunction } from 'i18next'
import {
  MercadoPagoPaymentMethods,
  PaymentMethodEnum,
} from 'common/enums/PaymentMethodEnum'

export const mercadoCreditCardTypes = [
  PaymentMethodEnum.MercadoPagoCard,
  PaymentMethodEnum.MercadoPagoBrCard,
  PaymentMethodEnum.MercadoPagoCoCard,
  PaymentMethodEnum.MercadoPagoMxCard,
  PaymentMethodEnum.MercadoPagoArCard,
  PaymentMethodEnum.MercadoPagoClCard,
]

export const creditCardMethodTypes = [
  PaymentMethodEnum.StripeCard,
  PaymentMethodEnum.DlocalCard,
  ...mercadoCreditCardTypes,
]

export const getCreditCardPaymentMethodTitle = (
  paymentMethod: PaymentMethodEnum,
  t: TFunction,
) => {
  const baseTitle = t('components.payment_methods.credit_cards.label')
  if (paymentMethod === PaymentMethodEnum.DlocalCard)
    return baseTitle + ' (Dlocal)'

  if (mercadoCreditCardTypes.includes(paymentMethod))
    return baseTitle + ' (MercadoPago)'

  return baseTitle
}

export const getIsMercadoPagoPaymentMethod = (paymentMethod: PaymentMethodEnum) =>
  MercadoPagoPaymentMethods.includes(paymentMethod)
