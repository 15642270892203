import styled from 'styled-components'

const ProductStockLimitInnerContainerUi = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  font-weight: 500;
`

export default ProductStockLimitInnerContainerUi
